import React from 'react';
import  './style.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import ZhaiYao from "./pages/ZhaiYao";
import Tu from "./pages/Tu";
import {Biao} from "./components/Biao";
import Sidebar from './components/Sidebar';
import {Overview1,Overview2,Overview3,Overview4,Overview5,Overview6,Revenue,OldQuestion,NewQuestion,NewAnswer,LinguisticPresumption,AccountingPresumption,LtoAccountingPresumption,EconomicsPresumption,Math} from './pages/Overview';
import {Reports} from './pages/Reports'
import {WorkingPapers} from "./pages/WorkingPapers"
import {Syntax,Syntax1,Syntax2,Syntax3,Pyaccounting,Declarative} from "./pages/Syntax"
import {GP,Contribution,Literature} from "./pages/GenerativeGrammar"
import {Darwin,Kuhn,Positivism,Game,Syntacticsugar,Informationandlinguistics} from "./pages/Support"
import {Semantic,Segmentation,Semantic2,Semantic3,Inline} from "./pages/Semantic"
import {Accounting} from "./pages/References"
import {Join} from "./pages/Join"
import {Metaphor} from "./pages/Cognitive"
import {Css,Naming,Ajax,PseudCode,ResearvedWord,LifeCycle,Decorator,Thinking} from "./pages/Reacc"
import {Install,Example,Documentation} from "./pages/NavHome"
import {Account,Entity,Event,Information,Language,Culture,Market,Institution,Person,Calculate,Abnormal,Strategy} from "./pages/Document"
import { Redefine,Standard,Asset,Liability,Trend,Pythonic} from './pages/Redefine';
import './App.css'
import {Space,Step,Agent,Loss,Action,Dqn,Value,Actor_Critic} from './pages/gym/Reinforcement'
import {Problem} from './pages/gym/Reinforcement_problem'
import {Policy,Epsilon} from './pages/gym/Reinforcement_policy'
import {Env} from './pages/gym/Reinforcement_envs'
import {MonteCarlo} from './pages/gym/Monte_Carlo'
import {Markov} from './pages/gym/Markov_Chains'
import { LoginPage,LogoutPage } from './components/auth/LoginPage';
import { Profile } from './components/auth/UserProfile';
import { Repositories} from './components/auth/Repositories';
import { Settings} from './components/auth/Settings';
function PageNotFound() {
      return (
        <div>
          <h1>Page Not Found</h1>
          <p className="philosophy-text">The page you are looking for does not exist.</p>
        </div>
      );
    }
    
const App = () => {
  return (
      <div  className="background-color">
       <BrowserRouter>
       <Sidebar />
       <Routes>
       <Route path="/users/:id" component={<Profile />} />

       <Route exact path="/" element={<Home />}/>
       <Route exact path="/login" element={<LoginPage />}/>
       <Route exact path="/logout" element={<LogoutPage />}/>
       <Route exact path="/profile" element={<Profile />}/>
       <Route exact path="/repositories" element={<Repositories />}/>
       <Route exact path="/settings" element={<Settings />}/>
       <Route exact path="/overview" element={<Overview1 />}/>
       <Route exact path="/overview/1" element={<Overview1 />}/>
       <Route exact path="/overview/2" element={<Overview2 />}/>
       <Route exact path="/overview/3" element={<Overview3 />}/>
       <Route exact path="/overview/4" element={<Overview4 />}/>
       <Route exact path="/overview/5" element={<Overview5 />}/>
       <Route exact path="/overview/6" element={<Overview6 />}/>
       <Route exact path="/overview/math" element={<Math />}/>
       <Route exact path="/documentation/account" element={<Account />}/>
      <Route exact path="/documentation/entity" element={<Entity />}/>
      <Route exact path="/documentation/person" element={<Person />}/>
      <Route exact path="/documentation/event" element={<Event />}/>
      <Route exact path="/documentation/information" element={<Information />}/>
      <Route exact path="/documentation/language" element={<Language />}/>
      <Route exact path="/documentation/culture" element={<Culture />}/>
      <Route exact path="/documentation/market" element={<Market />}/>
      <Route exact path="/documentation/institution" element={<Institution />}/>
      <Route exact path="/documentation/strategy" element={<Strategy />}/>
      <Route exact path="/documentation/calculate" element={<Calculate />}/>
      <Route exact path="/documentation/abnormal" element=
{<Abnormal />}/>
      <Route exact path="/documentation" element={<Account />}/>
       <Route exact path="/overview/oldquestion" element={<OldQuestion />}/>
       <Route exact path="/overview/newquestion" element={<NewQuestion />}/>
       <Route exact path="/overview/newanswer" element={<NewAnswer />}/>
       <Route exact path="/overview/revenue" element={<Revenue />}/>
       <Route exact path="/overview/literature" element={<Literature />}/>
       <Route exact path="/overview/linguisticpresumption" element={<LinguisticPresumption />}/>
       <Route exact path="/overview/accountingpresumption" element=
      {<AccountingPresumption />}/>
      <Route exact path="/overview/ltoaccountingpresumption" element=
      {<LtoAccountingPresumption />}/>
        <Route exact path="/overview/economicspresumption" element=
{<EconomicsPresumption />}/>
      <Route exact path="/install" element=
      {<Install />}/>
      <Route exact path="/documentation" element=
      {<Documentation />}/>
      <Route exact path="/example" element=
      {<Example />}/>
       <Route exact path="/workingpapers" element={<WorkingPapers />}/>
       <Route exact path="/syntax" element={<Syntax />}/>
       <Route exact path="/syntax/reports1" element={<Syntax1 />}/>
       <Route exact path="/syntax/reports2" element={<GP />}/>
       <Route exact path="/syntax/reports3" element={<Syntax3 />}/>       <Route exact path="/syntax/declarative" element={<Declarative />}/>
       <Route exact path="/syntax/pyaccounting" element={<Pyaccounting />}/>
       <Route exact path="/semantic" element={<Semantic />}/>
       <Route exact path="/semantic/segmentation" element={<Segmentation />}/>
       <Route exact path="/semantic/reports2" element={<Semantic2 />}/>
       <Route exact path="/semantic/reports3" element={<Semantic3 />}/>
       <Route exact path="/semantic/inline" element={<Inline />}/>
       <Route exact path="/reports" element={<Reports />}/>
       <Route exact path="/cognitive/metaphor" element={<Metaphor />}/>
       <Route exact path="/reports/reports1" element={<ZhaiYao />}/>
       <Route exact path="/reports/reports2" element={<Contribution />}/>
       <Route exact path="/reports/reports3" element={<Tu />}/>
       <Route exact path="/redefine" element={<Redefine />}/>
       <Route exact path="/redefine/pythonic" element={<Pythonic />}/>
       <Route exact path="/redefine/asset" element={<Asset />}/>
       <Route exact path="/redefine/standard" element={<Standard />}/>
       <Route exact path="/redefine/liability" element={<Liability />}/>
       <Route exact path="/redefine/trend" element={<Trend />}/>
       <Route exact path="/ajax" element={<Ajax />}/>
       <Route exact path="/ajax/css" element={<Css />}/>
       <Route exact path="/ajax/naming" element={<Naming />}/>
       <Route exact path="/ajax/pseudocode" element={<PseudCode />}/>
       <Route exact path="/ajax/researvedword" element={<ResearvedWord />}/>
       <Route exact path="/ajax/lifecycle" element={<LifeCycle />}/>
       <Route exact path="/ajax/decorator" element={<Decorator />}/>       <Route exact path="/ajax/thinking" element={<Thinking />}/>
       <Route exact path="/css" element={<Css />}/>
       <Route exact path="/reinforcement" element={<Space />}/>
  <Route exact path="/reinforcement/space" element={<Space />}/>
  <Route exact path="/reinforcement/step" element={<Step />}/>
  <Route exact path="/reinforcement/agent" element={<Agent />}/>
  <Route exact path="/reinforcement/loss" element={<Loss />}/>
  <Route exact path="/reinforcement/policy" element={<Policy />}/>
  <Route exact path="/reinforcement/epsilon" element={<Epsilon />}/>
  <Route exact path="/reinforcement/actor_critic" element={<Actor_Critic />}/>
  <Route exact path="/reinforcement/problem" element={<Problem />}/>
  <Route exact path="/reinforcement/convergence" element={<Problem />}/>

  <Route exact path="/reinforcement/action" element={<Action />}/>
  <Route exact path="/reinforcement/env" element={<Env />}/>
  <Route exact path="/reinforcement/train" element={<Dqn />}/>
  <Route exact path="/reinforcement/dqn" element={<Dqn />}/>
  <Route exact path="/reinforcement/value" element={<Value />}/>
  <Route exact path="/reinforcement/montecarlo" element={<MonteCarlo />}/>
  <Route exact path="/reinforcement/markov" element={<Markov />}/>

       <Route exact path="/support" element={<Biao />}/>
       <Route exact path="/support/darwin" element={<Darwin />}/>
       <Route exact path="/support/kuhn" element={<Kuhn />}/>
       <Route exact path="/support/game" element={<Game />}/>
       <Route exact path="/support/informationandlinguistics" element={<Informationandlinguistics />}/>
       <Route exact path="/support/positivism" element={<Positivism />}/>
       <Route exact path="/support/syntacticsugar" element={<Syntacticsugar />}/>
       <Route exact path="/references/accounting" element={<Accounting />}/>
       <Route exact path="/join" element={<Join />}/>

 
 <Route exact path="/Biao" element={<Biao />}/>
 <Route path="*" element={<PageNotFound />}/>
       </Routes>
   </BrowserRouter>
   </div>
  )
  }
export default App;
