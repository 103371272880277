import React from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import {Title} from "../Overview";
import Rating from '@mui/material/Rating';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {NavReinforcementlearning,NavTrain,NavPolicy} from "../Nav";
import {Asset} from "../Code"
import {markov_code} from "../../codes/Reinforcement_Code"
export const Markov = () => (
    <div>
             <Title> Markov Chains<Rating name="read-only" value={3} 
readOnly /
></Title>
<Alert  icon={false} variant="outlined" severity="info">

马尔可夫链是通常用一组随机变量定义的数学系统，可以根据具体的概率规则进行状态转移。转移的集合满足马尔可夫性质，也就是说，<b>转移到任一特定状态的概率只取决于当前状态和所用时间</b>，而与其之前的状态序列无关。马尔可夫链的这个独特性质就是
<b>无记忆性</b>。
<p className="center">            Pr( Xn+1 = x | X1 = x1, X2 = x2, …, Xn = xn) = Pr( Xn+1 = x | Xn = xn)
</p>
</Alert>
<p className="philosophy-text">统状态的改变叫做转移。各个状态改变的概率叫做转移概率。概率自动机包括从已知转移到转移方程的概率，将其转换为转移矩阵。会计马尔可夫过程是一种统计模型，用于描述会计数据随时间的变化。它基于马尔可夫概率模型，可以捕捉会计数据的时间相关性，并在数据的未来趋势和潜在风险方面进行预测。会计马尔可夫过程在风险评估、财务预测和审计等领域中得到了广泛应用。</p>
<p className="philosophy-text">如果一家公司想要预测未来几个季度的营业收入，它可以使用会计马尔可夫过程。通过分析过去几个季度的营业收入数据，该公司可以确定营业收入的时间相关性，并建立一个马尔可夫概率模型来预测未来几个季度的营业收入。

通过使用会计马尔可夫过程，该公司可以获得有关营业收入的更多信息，并为决策者提供重要的信息。例如，如果预测结果显示营业收入可能出现下降趋势，决策者可以采取行动以防止收入下降。

因此，会计马尔可夫过程不仅可以帮助公司预测未来，还可以帮助公司识别潜在的风险，并采取行动以应对这些风险。</p>
<p className="philosophy-text">会计强化学习中的马尔可夫链是一种把强化学习方法应用于会计领域的技术。它基于马尔可夫决策过程，在会计领域中用于解决复杂的决策问题。
在会计强化学习中，马尔可夫链通过对会计环境建模，并将会计决策过程视为一个马尔可夫链。通过不断模拟决策过程，马尔可夫链可以学习如何在复杂的会计环境中做出最佳决策。
会计强化学习中的马尔可夫链在会计领域中的应用广泛，例如，它可以用于预测财务报表的数据，识别财务报表的异常，评估风险等。
因此，会计强化学习中的马尔可夫链是一种高效的工具，可以帮助决策者在复杂的会计环境中做出最佳决策。</p>
<p className="philosophy-text">transition matrix</p>
<Asset code={markov_code}/>
<p className="philosophy-text">这个矩阵表示从一个状态转移到另一个状态的概率。在这个示例中，第一行第一列的值 0.7 表示从状态 1 转移到状态 1 的概率为 0.7，第一行第二列的值 0.3 表示从状态 1 转移到状态 2 的概率为 0.3，以此类推。</p>

<p className="philosophy-text">如果马尔可夫链有 N 种状态，转移矩阵就是 N x N 维，其中（I, J）表示从状态 I 转移到状态 J 的概率。此外，转移矩阵一定是概率矩阵，也就是每一行元素之和一定是 1。</p>
马尔可夫链的几个重要性质：
<ul><li>互通性：如果一个马尔可夫链可以从任何状态转移至任何状态，那么它就是不可还原的。换句话说，如果任两个状态之间存在一系列步骤的概率为正，就是不可还原的。</li><li>周期性：如果马尔可夫链只有在大于 1 的某个整数的倍数时返回某状态，那么马尔可夫链的状态是周期性的。因此，从状态「i」开始，只有经过整数倍个周期「k」才能回到「i」，k 是所有满足条件的整数的最大值。如果 k = 1 状态「i」不是周期性的，如果 k > 1，「i」才是周期性的。
</li><li>瞬态性和常返性：如果从状态「i」开始，有可能无法回到状态「i」，那么状态「i」有瞬态性。否则具有常返性（或者说持续性）。如果某状态可以在有限步内重现，该状态具有常返性，否则没有常返性。
</li><li>遍历性：状态「i」如果满足非周期性和正重现性，它就有遍历性。如果不具有可还原性的马尔可夫链的每个状态都有遍历性，那么这个马尔可夫链也具有遍历性。
</li><li>吸收态：如果无法从状态「i」转移到其他状态，「i」处于吸收态。因此，如果 当 i ≠ j 时，pii = 1 且 pij = 0，状态「i」处于吸收态。如果马尔可夫链的每个状态都可以达到吸收态，称其为具有吸收态的马尔可夫链。
</li></ul>
    </div>

  );