import React from "react";
import Alert from '@mui/material/Alert';

export const LoginPage = () => (
    <>
      <p className="philosophy-text">
      <Alert variant="outlined"severity="info">
      LoginPage
      From a cognitive linguistic perspective, analyzing <b>metaphorical expressions</b> used in accounting language 
involves understanding how people use language to create and convey meaning, and how these metaphorical 
expressions are grounded in our everyday experiences and conceptualizations.

One way to analyze accounting metaphors from a cognitive linguistic perspective is to examine the source domains and 
target domains of the metaphorical expressions. For example, the source domain of the metaphor "balancing the books" is 
the physical act of balancing objects, and the target domain is the financial records of an organization. By 
understanding the source domain, we can better understand how people use this metaphor to convey the idea of making sure 
that financial records are accurate and in agreement.

Another way to analyze accounting metaphors from a cognitive linguistic perspective is to examine the conceptual mapping 
that takes place between the source and target domains. For example, the metaphor "cash flow is the lifeblood of a 
business" maps the concept of cash flow onto the concept of the lifeblood of a living organism, highlighting the 
importance of cash flow for the survival and well-being of a business.

It is also important to note that the <span className="文中强调">cognitive linguistic perspective </span>emphasizes that 
metaphor is not only a figure of speech, but also <span className="文中强调">a fundamental cognitive process</span>, that 
<b>enables us to understand abstract concepts by mapping them onto more concrete and familiar experiences</b>. Thus, 
accounting metaphors are not just a way to make accounting language more interesting, but also a powerful tool to make 
the abstract concepts of accounting more accessible and understandable for the audience.
</Alert>
</p>
    </>
  )
  export const LogoutPage = () => (
    <>
      <p className="philosophy-text">
      <Alert variant="outlined"severity="info">
      LogoutPage
      From a cognitive linguistic perspective, analyzing <b>metaphorical expressions</b> used in accounting language 
involves understanding how people use language to create and convey meaning, and how these metaphorical 
expressions are grounded in our everyday experiences and conceptualizations.

One way to analyze accounting metaphors from a cognitive linguistic perspective is to examine the source domains and 
target domains of the metaphorical expressions. For example, the source domain of the metaphor "balancing the books" is 
the physical act of balancing objects, and the target domain is the financial records of an organization. By 
understanding the source domain, we can better understand how people use this metaphor to convey the idea of making sure 
that financial records are accurate and in agreement.

Another way to analyze accounting metaphors from a cognitive linguistic perspective is to examine the conceptual mapping 
that takes place between the source and target domains. For example, the metaphor "cash flow is the lifeblood of a 
business" maps the concept of cash flow onto the concept of the lifeblood of a living organism, highlighting the 
importance of cash flow for the survival and well-being of a business.

It is also important to note that the <span className="文中强调">cognitive linguistic perspective </span>emphasizes that 
metaphor is not only a figure of speech, but also <span className="文中强调">a fundamental cognitive process</span>, that 
<b>enables us to understand abstract concepts by mapping them onto more concrete and familiar experiences</b>. Thus, 
accounting metaphors are not just a way to make accounting language more interesting, but also a powerful tool to make 
the abstract concepts of accounting more accessible and understandable for the audience.
</Alert>
</p>
    </>
  )
