import React ,{useState} from 'react'
import * as AiIcons from "react-icons/ai"
import * as RiIcons from "react-icons/ri"
export const SidebarData = [
{
    title: "1. Background",
    path:"/overview",
    iconClosed:<RiIcons.RiArrowDownSFill/>,
    iconOpened:<RiIcons.RiArrowUpFill/>,
    subNav:[
        {
            title: "1.0 Research Question",
            path:"/overview/oldquestion",
        },
        {
            title: "1.1 Research Implication",
            path:"/overview/revenue",
        },
        {
            title: "1.2 Literature Review",
            path:"/overview/literature",
        },
        {
            title: "1.3 Core Assumption",
            path:"/overview/linguisticpresumption",
        },
        {
            title: "1.4 Mathematical Modeling",
            path:"/overview/math",
        },
    ]
},
{
    title: "2. Talk Like a 🤖️",
    path:"/reports",
    iconClosed:<RiIcons.RiArrowDownSFill/>,
    iconOpened:<RiIcons.RiArrowUpFill/>,
    subNav:[
        {
            title: "2.0 Abstract",
            path:"/reports/reports1",
        },
        {
            title: "2.1 Contribution",
            path:"/reports/reports2",
        },
        {
            title: "2.2 Picture",
            path:"/reports/reports3",
        },
    ]
},
{
    title: "3. Redefine Accounting",
    path:"/redefine",
    iconClosed:<RiIcons.RiArrowDownSFill/>,
    iconOpened:<RiIcons.RiArrowUpFill/>,
    subNav:[
        {
            title: "3.0 AccountingStandard",
            path:"/redefine/standard",
        },
        {
            title: "3.1 Pythonic",
            path:"/redefine/pythonic",
        },
        {
            title: "3.2 Asset",
            path:"/redefine/asset",
        },
        {
            title: "3.3 Liability",
            path:"/redefine/liability",
        },
        {
            title: "3.4 Picture",
            path:"/reports/reports3",
        },
        {
            title: "3.5 Trend",
            path:"/redefine/trend",
        },
    ]
},
{
    title: "4. Workingpaper",
    path:"/workingpapers",
}
,
{
    title: "5.Accounting Syntax",
    path:"/syntax",
    iconClosed:<RiIcons.RiArrowDownSFill/>,
    iconOpened:<RiIcons.RiArrowUpFill/>,
    subNav:[
        {
            title: "5.0 Debit & Credit Syntax",
            path:"/syntax/reports1",
        },
        {
            title: "5.1 TG",
            path:"/syntax/reports2",
        },
        {
            title: "5.2 Syntax Analysis",
            path:"/syntax/reports3",
        },
        {
            title: "5.4 PyAccouting Python",
            path:"/syntax/pyaccounting",
        },
        {
            title: "5.5 Declarative Accounting",
            path:"/syntax/declarative",
        }
    ]
},
{
    title: "6. Accounting Semantics",
    path:"/semantic",
    iconClosed:<RiIcons.RiArrowDownSFill/>,
    iconOpened:<RiIcons.RiArrowUpFill/>,
    subNav:[
        {
            title: "6.0 Segmentation",
            path:"/semantic/segmentation",
        },
        {
            title: "6.1 Semantic Analysis",
            path:"/semantic/reports2",
        },
        {
            title: "6.2 Semantic Analysis",
            path:"/semantic/reports3",
        },
        {
            title: "6.3 Inline Text Semantics",
            path:"/semantic/inline",
        }
    ]
},
{
    title: "7. Accounting Cognitive",
    path:"/cognitive",
    iconClosed:<RiIcons.RiArrowDownSFill/>,
    iconOpened:<RiIcons.RiArrowUpFill/>,
    subNav:[
        {
            title: "7.0 Accounting Metaphor",
            path:"/cognitive/metaphor",
        },
        {
            title: "7.1 Semantic Analysis",
            path:"/semantic/reports2",
        },
        {
            title: "7.2 Semantic Analysis",
            path:"/semantic/reports3",
        },
        {
            title: "7.3 Inline Text Semantics",
            path:"/semantic/inline",
        }
    ]
},
{
    title: "8. Pyaccounting",
    path:"/ajax",
    iconClosed:<RiIcons.RiArrowDownSFill/>,
    iconOpened:<RiIcons.RiArrowUpFill/>,
    subNav:[
        {
            title: "8.0 Design Thinking",
            path:"/ajax/thinking",
        },
        {
            title: "8.1 Accounting Nomenclature",
            path:"/ajax/naming",
        },
        {
            title: "8.2 Accounting Css",
            path:"/ajax/css",
        },
        {
            title: "8.3 Accounting Logic",
            path:"/ajax/css",
        },
        {
            title: "8.4 Accounting Content",
            path:"/ajax/css",
        },
        {
            title: "8.5 Accounting Pseudo Code",
            path:"/ajax/pseudocode",
        },
        {
            title: "8.6 Accounting Researved Word",
            path:"/ajax/researvedword",
        },
        {
            title: "8.7 Accounting Life Cycle",
            path:"/ajax/lifecycle",
        },
        {
            title: "8.8 Accounting Decorator",
            path:"/ajax/decorator",
        },
    ]
},
{
    title: "9. Intelligent Accounting Firm",
    path:"/firm",
    iconClosed:<RiIcons.RiArrowDownSFill/>,
    iconOpened:<RiIcons.RiArrowUpFill/>,
    subNav:[
        {
            title: "9.0 Position",
            path:"/firm/position",
        },
        {
            title: "9.1 Accounting Css",
            path:"/ajax/css",
        },
    ]
},
{
    title: "10. Reinforcement Learning Accounting",
    path:"/reinforcement",
    iconClosed:<RiIcons.RiArrowDownSFill/>,
    iconOpened:<RiIcons.RiArrowUpFill/>,
    subNav:[
        {
            title: "10.0 Policy function",
            path:"/reinforcement/policy",
        },
        {
            title: "10.1 Value function",
            path:"/reinforcement/value",
        },
        {
            title: "10.2 Accounting Env",
            path:"/reinforcement/env",
        },
        {
            title: "10.3 Accounting Training",
            path:"/reinforcement/train",
        },
        {
            title: "Monte Carlo",
            path:"/reinforcement/montecarlo",
        },
        {
            title: "Markov Chains",
            path:"/reinforcement/markov",
        },
        {
            title: "Problem",
            path:"/reinforcement/problem",
        },
    ]
},
{
    title: "Appendix",
    path:"/support",
    iconClosed:<RiIcons.RiArrowDownSFill/>,
    iconOpened:<RiIcons.RiArrowUpFill/>,
    subNav:[
        {
            title: "Accounting Darwinism",
            path:"/support/darwin",
        },
        {
            title: "Kuhn Paradigm",
            path:"/support/kuhn",
        },
        {
            title: "Accounting Positivism",
            path:"/support/positivism",
        },
        {
            title: "Language Game",
            path:"/support/game",
        },
        {
            title: "Syntactic Sugar",
            path:"/support/syntacticsugar",
        },
        {
            title: "Linguistics Information",
            path:"/support/informationandlinguistics",
        }
    ]
},
{
    title: "References",
    iconClosed:<RiIcons.RiArrowDownSFill/>,
    iconOpened:<RiIcons.RiArrowUpFill/>,
    subNav:[
        {
            title: "Accounting",
            path:"/references/accounting",
        },
        {
            title: "Linguistics",
            path:"/references/linguistics",
        },
        {
            title: "Computer Science",
            path:"/references/computer",
        }
    ]
},
{
    title: "Join us",
    path:"/join",
},
{
    title: "",
    path:"",
},
{
    title: "",
    path:"",
},
{
    title: "",
    path:"",
},
]