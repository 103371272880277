import React from "react";
function Car() {
  return <h2>博士加油</h2>;
}
export const Reports = () => (
    <div>
 
 
      <Car />
    </div>
  );
  
export const ReportsOne = () => (
  <div>
     <h1>博士论文1</h1>
<p className="philosophy-text">时间 {new Date().toLocaleTimeString()}.</p>
    <Car />
  </div>
);

export const ReportsTwo = () => (
    <div>
       <h1>博士论文2</h1>
  <p className="philosophy-text">时间 {new Date().toLocaleTimeString()}.</p>
      <Car />
    </div>
  );
  export const ReportsThree = () => (
    <div>
       <h1>博士论文3</h1>
  <p className="philosophy-text">时间 {new Date().toLocaleTimeString()}.</p>
      <Car />
    </div>
  );
  