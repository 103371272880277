import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import 乔姆斯基 from '../images/乔姆斯基.jpg';
import lakoff from '../images/lakoff.jpg';
import Grice from '../images/Grice.jpeg';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import { MdOutlinePersonOutline } from "react-icons/md";
import { Link} from "react-router-dom";
import {Title} from "./Overview"
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import {Asset,TotalAssets,CalculateAsset} from "./Code"
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Rating from '@mui/material/Rating';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export function GP() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>
      <Tabs
 variant="scrollable"
 scrollButtons
 allowScrollButtonsMobile
 value={value}
 onChange={handleChange}
 aria-label="Vertical tabs example"
      >
        <Tab label="definition定义" {...a11yProps(0)} />
        <Tab label="厉害" {...a11yProps(1)} />
        <Tab label="牛逼" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <Alert  icon={false} variant="outlined" severity="info">
      Generative syntax is a type of syntax model used to describe sentence structure. It analyzes the structure of a sentence by breaking it down into different components and defining and dividing them. The main purpose of generative syntax is to describe the grammatical features and syntax rules of a sentence by analyzing its structure. This type of syntax model typically uses a tree structure to represent the structure of a sentence and specific rules to describe the relationship between the various parts of the sentence. Generative syntax is one of the commonly used syntax models in computer science and has a wide range of applications in fields such as natural language processing and machine translation. It helps us understand the structure and rules of language and aids us in better processing natural language data.
      </Alert>

      <span className="文中强调">生成句法</span>是指用来描述句子结构的一类句法模型。它通过对句子的结构进行分析，把句子分解成不同的组成部分，并对这些部分进行定义和划分。生成句法的主要目的是通过对句子的结构进行分析，来描述句子的语法特征和句法规则。这种句法模型通常使用树形结构来表示句子的结构，并使用特定的规则来描述句子中各个部分之间的关系。生成句法是计算机科学中常用的句法模型之一，在自然语言处理、机器翻译等领域有着广泛的应用。它能够帮助我们理解语言的结构和规则，并有助于我们更好地处理自然语言数据。 <br />
      <Alert  icon={false} variant="outlined" severity="info">
      Accounting generative syntax is a technique of using computer programs to generate sentences related to accounting. This technique is often used to generate financial statements, bills, and other accounting documents. The process of generative syntax typically includes inputting relevant data, using a syntax model to generate sentences, and formatting them according to relevant rules. The generated sentences can be simple, standalone sentences or complex, multi-sentence passages. Generative syntax technology can help accountants complete document generation faster and reduce the possibility of human error. However, generative syntax also has certain limitations, as it can only generate template-based sentences and cannot capture complex language structures and semantics.
      </Alert>

      <span className="文中强调">会计生成句法</span>是指使用计算机程序生成会计相关的句子的一种技术。这种技术通常被用来生成财务报表、账单和其他会计文件。生成句法的过程通常包括输入相关数据、使用句法模型生成句子、并根据相关规则进行排版。生成的句子可以是简单的、单独的句子，也可以是复杂的、包含多个句子的文章。
生成句法技术可以帮助会计人员更快地完成文件的生成，并减少人为错误的可能性。然而，生成句法也存在一定的局限性，因为它只能生成模板化的句子，不能捕捉复杂的语言结构和语义。
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}

export function Contribution() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>
      <Tabs
       variant="scrollable"
       scrollButtons
       allowScrollButtonsMobile
       value={value}
       onChange={handleChange}
       aria-label="Vertical tabs example"
      >
        <Tab label=
           {
            <>
              <Typography variant="caption">
              new concept
             </Typography>
            </>
         }
         {...a11yProps(0)} />
        <Tab label=
             {
              <>
                <Typography variant="caption">
                new turn
               </Typography>
              </>
           }
       {...a11yProps(1)} />
        <Tab label=
            {
              <>
                <Typography variant="caption">
                new definition
               </Typography>
              </>
           }
          {...a11yProps(2)} />
        <Tab label=
          {
            <>
              <Typography variant="caption">
              new suggestion
             </Typography>
            </>
         }
          {...a11yProps(3)} />
        <Tab label=
          {
            <>
              <Typography variant="caption">
              new accounting language
             </Typography>
            </>
         }
        {...a11yProps(4)} />
        <Tab label=
              {
                <>
                  <Typography variant="caption">
                  new foundation
                 </Typography>
                </>
             }
        {...a11yProps(5)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <div>
      <p className="philosophy-text">
      <Title><strong>Word Truth </strong>in Social Science</Title>

      "Word truth" in social science refers to the accuracy and validity of language used in social scientific research to describe and understand social phenomena. It is the extent to which the words and concepts used by researchers to describe social reality correspond to the reality they are attempting to describe.

In social science research, language is an important tool for communicating and analyzing social phenomena. However, because social reality is complex and multifaceted, there is often disagreement among social scientists about the meaning of particular words and concepts, as well as their accuracy and validity. For example, different social scientists may use different words to describe the same phenomenon or interpret the same data in different ways.

Therefore, it is important for social scientists to critically evaluate the accuracy and validity of the language they use in their research, as well as to consider the broader social and historical contexts that shape the meanings of the words they use. This involves being aware of their own biases and assumptions, and engaging in ongoing dialogue and debate with other social scientists in order to refine their understanding of social reality and the language used to describe it. Ultimately, striving for word truth in social science research is essential for producing valid and reliable knowledge about the social world.
<Title><strong>Machine Language</strong> Vs <strong>Ordinary Language</strong></Title>
<ul>
In the social sciences, there are numerous potential benefits and possibilities of people communicating with machines 
using machine language.
  <li>One of the primary benefits is increased efficiency and productivity. By using machine language, people can communicate 
more quickly and accurately, reducing the likelihood of miscommunication and misunderstandings. This can be particularly 
useful in fields such as economics and finance, where even small errors in communication can have significant 
consequences.</li>
  <li>Another benefit is the ability to automate certain tasks and processes, such as data analysis or customer service. This can free up human workers to focus on more complex and creative tasks, leading to higher job satisfaction and improved outcomes.</li>
  <li>Finally, using machine language can lead to more inclusive and accessible communication, as machines can be programmed to communicate in a variety of formats and languages, making it easier for individuals with disabilities or from diverse backgrounds to participate fully in social and economic activities.</li>
  Overall, the potential benefits and possibilities of using machine language in the social sciences are vast and varied, 
and are likely to continue to expand as machine learning algorithms and technologies improve over time.
</ul>

<Alert  icon={false} variant="filled" severity="info">
基于语言的会计观的应用领域包括但不限于：对公司年报中的财务报告文本进行情感分析，以了解公司高管对未来的看法和信心水平。
对公司公告文本进行主题分析，以了解公司的战略重点。
对公司的社交媒体账号进行语义分析，以了解公司的声誉和口碑。
      </Alert>

基于语言的会计观并不是替代传统定量分析的方法，而是与之配合使用，从而使我们能够从多个角度对企业进行分析和理解。会计观是指对会计工作的态度、看法和方式的总称。它是会计工作的核心价值观，是会计工作的道德准则。会计观念包括对会计工作的职业道德、对客观事实的真实性、对公正性的重视、对信息披露的责任感、对财务报告的信任感等方面。会计观念的形成和发展是会计专业的基础，也是会计专业的重要组成部分。
      </p>
      </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      在经济学指导下的会计学的语言学转向是指将经济学理论和方法应用于会计学研究，并将会计学研究的结果作为经济学研究的输入，以更好地理解和解释会计数据。这种语言学转向的目的是为了更好地理解和解释会计数据，并使用经济学的工具来提高会计的决策和评估能力。语言转向是使得会计对会计语言的要求降低，更加聚焦企业业务和经济的底层逻辑
 <p className="philosophy-text">
        <ol>
        <li> 
        <p class="display-flex">
        <StyledBadge
   overlap="circular"
   anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
   variant="dot"
 >
   <Avatar alt="Remy Sharp" src={Grice} />
 </StyledBadge>
        <Title> 语用学转向</Title>
    </p>
</li>   
          语用学是一门研究语言在交际中的运用和效用的学科。它着重于研究语言如何被使用来实现交际目的，以及语言使用过程中的各种因素，如说话
者的意图、说话者和听众的关系、话语的语境等。会计的语用学转向的目的是通过对会计文本的语用分析，更好地理解会计信息的真实含义和使用目的。例如，通过对公司年报中的财务报告文本进行语用分析，我们可以了解公司高管在报告中所使用的信息是否具有欺骗性，以及这些信息的真实含义是什么。会计的语用学转向并不是替代传统定量分析的方法，而是与之配合使用，从而使我们能够从多个角度对企业进行分析和理解。
          <ul>
       <li>
          格莱斯语用学是由英国语言学家约翰·格莱斯（John Grice）提出的一种语用学理论。格莱斯语用学着重于研究语言使用过程中，说话者和听
众之间的交流机制，以及说话者在说话过程中所遵循的一些原则。格莱斯语用学的一些主要原则包括：信息原则、效率原则、保持原则、清晰原则、合理原则等。这些原则主要是指导说话者在交流过程中如何传递信息，以及如何使自己的意图更加明确。格莱斯语用学与会计学的关系是，格莱斯语用学可以作为一种工具，帮助我们更好地理解会计信息的真实含义和使用目的。例如，我们可以通过分析会计文本中说话者所遵循的格莱斯语用学原则，来了解公司高管在报告中所使用的信息是否具有欺骗性，以及这些信息的真实
            </li> 
           <li>
            新格莱斯语用学是指在传统格莱斯语用学理论的基础上，结合社会学、心理学等学科的理论和方法，对语言使用过程中的社会因素和心理因素
进行研究的一种语用学理论。新格莱斯语用学主要关注语言使用过程中，说话者和听众之间的交流机制，以及说话者在说话过程中所遵循的一
些原则，同时也着重研究语言使用过程中的社会因素和心理因素。新格莱斯语用学与会计学的关系是，新格莱斯语用学可以作为一种工具，帮助我们更好地理解会计信息的真实含义和使用目的。例如，我们可以通过分析会计文本中说话者所遵循的新格莱斯语用学原则，来了解公司高管在报告中所使用的信息是否具有欺骗性，
            </li>
          </ul>
          <li> 
        <p class="display-flex">
        <StyledBadge
   overlap="circular"
   anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
   variant="dot"
 >
   <Avatar alt="Remy Sharp" src={乔姆斯基} />
 </StyledBadge>
        <Title> 句法学转向     </Title>
    </p>
</li>   
          会计研究的句法学转向是指将句法学理论和方法应用于会计研究的一种做法。
句法学是语言学的一个分支，主要研究语言中句子的结构和功能。句法学着重于研究句子的内部结构，以及各种成分之间的关系，如主语、谓语、宾语等。会计研究的句法学转向的目的是通过对会计文本的句法分析，更好地理解会计信息的真实含义和使用目的。例如，通过对公司年报中的财务报告文本进行句法
分析，我们可以了解公司高管在报告中所使用的信息是否具有欺骗性，以及这些信息的真实含义是什么。会计研究的句法学转向并不是替代传统定量分析的方法，而是与之配合使用，从而使我们能够从多个角度对企业进行分析和理解。
          <ul>
         <li>
          乔姆斯基（A.A.Chomsky）是美国语言学家和哲学家，他提出了一种称为“句法结构理论”的语言学理论。乔姆斯基的句法结构理论认为，人类
语言具有一种内在的结构，即句法结构，这种结构是固定的，并且是人类语言习得的基础。乔姆斯基的句法结构理论对会计学的影响主要体现在会计研究的语言学方面。例如，我们可以通过对会计文本的句法分析，来了解公司高管在报告中所使用的信息是否具有欺骗性，以及这些信息的真实含义是什么。此外，我们还可以通过对会计文本的句法分析，来了解会计信息的表达方式，以及会计信息在句子中所占的地位和作用。
            </li>
          </ul>
          <li> 
        <p class="display-flex">
        <StyledBadge
   overlap="circular"
   anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
   variant="dot"
 >
   <Avatar alt="Remy Sharp" src={lakoff} />
 </StyledBadge>
        <Title> 认知语言学转向     </Title>
    </p>
</li>             认知语言学是一门研究人类语言表达和理解的心理学分支。它着重于研究人类如何通过语言来表达思想、感受和意图，以及人类如何通过语言来
理解外界信息的过程。会计和认知语言学的目的是通过对会计文本的认知分析，更好地理解会计信息的真实含义和使用目的。例如，通过对公司年报中的财务报告文本进行认知分析，我们可以了解公司高管在报告中所使用的信息是否具有欺骗性，以及这些信息的真实含义是什么。会计和认知语言学并不是替代传统定量分析的方法，而是与之配合使用，从而使我们能够从多个角度对企业进行分析和理解。
          <ul>
      <li>
          Lakoff认知语言学是由美国语言学家罗伯特·拉科夫（Robert Lakoff）提出的一种认知语言学理论。Lakoff认知语言学认为，人类语言是
基于人类认知体系的，即人类对于世界的理解和表达是建立在认知体系的基础上的。Lakoff认知语言学与会计学的关系是，Lakoff认知语言学可以作为一种工具，帮助我们更好地理解会计信息的真实含义和使用目的。例如，我们可以通过分析会计文本中所使用的词汇和概念，来了解公司高管在报告中所使用的信息是否具有欺骗性，以及这些信息的真实含义是什么。
            </li>
          </ul>
        </ol>
     </p>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <p  className="philosophy-text">
      <ol>
      <Title><li>提高可读性从根源是重新创作会计科目</li></Title>
        提高可读性是指让会计信息更容易理解和认知的过程。这个过程通常包括两个方面：一是对会计文本进行改进，使其表达更清晰、简洁；二是对会
计信息的展示方式进行改进，使其更加直观和易于理解。

重新创作会计科目是提高可读性的一种方法，但并不是唯一的方法。例如，我们还可以通过使用图表、统计数据、示意图等工具来帮助读者理解会计信息。此
外，我们还可以通过对会计文本进行语言学分析、句法分析、认知分析等，来更好地理解会计信息的含义和目的。

总的来说，提高可读性是一个复杂的过程，需要综合运用多种方法才能达到最佳效果。

<Title> <li>会计准则缺乏可读性（仿照git）</li></Title>
        会计准则是指对会计信息的计量、表示和披露方式进行规范的规则和准则。

会计准则的缺乏可读性是指，这些准则难以被读者理解和接受。这可能是由于会计准则的语言表述不够清晰、简洁，或者准则的内容过于复杂难懂。

缺乏可读性的会计准则可能会导致许多问题，如：

使得会计信息难以被广大读者理解和
      </ol>
     </p>
      </TabPanel>
      <TabPanel value={value} index={3}>
    
     <Title>  政策建议：会计语言政策</Title>
     <p className="philosophy-text">
     <Link to='/support/syntacticsugar' className="文内链接">会计语法糖</Link>是指将复杂的会计概念和计算过程用简洁的语言表达出来的方式。这种方式可以让会计人员更快地理解会计原理，并且可以让非会计专业人员也更容易理解会计概念。例如，在会计中，收入是指企业在一定时间内收到的经营性收入。会计语法糖可以将这个概念表述为“企业获得的钱”。这样，在阅读会计文本时，读者就可以更快地理解收入的含义，而不必深入研究会计术语。总之，会计语法糖是一种使用简明语言来表达复杂会计概念的方法，目的是使会计人员和非会计专业人员都能更好地理解会计原理。
    </p>
          </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
语言学基础
    </TabPanel>
    </Box>
  );
}


export function Literature() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
           <Title>Literature Review <Rating name="read-only" value={5} readOnly /></Title>
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>
      <Tabs
       variant="scrollable"
       scrollButtons
       allowScrollButtonsMobile
       value={value}
       onChange={handleChange}
       aria-label="Vertical tabs example"
      >
        <Tab label="会计哲学" {...a11yProps(0)} />
        <Tab label="会计理论" {...a11yProps(1)} />
        <Tab label="牛逼" {...a11yProps(2)} />
        <Tab label="Item Four" {...a11yProps(3)} />
        <Tab label="Item Five" {...a11yProps(4)} />
        <Tab label="Item Six" {...a11yProps(5)} />
        <Tab label="附注" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}  className="philosophy-text" >
      A literature review on accounting philosophy would typically include an examination of the historical development of accounting thought, as well as an analysis of the main theories and concepts that have been proposed in the field.

It could begin by tracing the historical development of accounting philosophy, starting with early concepts such as double-entry bookkeeping and the development of modern accounting principles and standards.

Then it could examine the different schools of thought in accounting philosophy, such as positivism, realism, and interpretivism, and how they have influenced the development of accounting theory and practice.

The literature review would also examine the various ethical and societal issues that have been considered in the field of accounting, such as the role of accountants in corporate governance, the relationship between accounting and the public interest, and the impact of accounting on society more broadly.

Additionally, It could also examine the recent trends and developments in accounting philosophy, such as the increasing emphasis on sustainability, corporate social responsibility, and the use of technology in accounting.

In summary, a literature review on accounting philosophy would typically include an examination of the historical development of accounting thought, an analysis of the main theories and concepts that have been proposed in the field, examination of the different schools of thought in accounting philosophy, consideration of the ethical and societal issues that have been considered in the field of accounting, and examination of the recent trends and developments in accounting philosophy.
      <Timeline position="alternate">
  <TimelineItem>
    <TimelineOppositeContent
      sx={{ m: 'auto 0' }}
      align="right"
      variant="caption"
      color="text.secondary"
    >
      /
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot>
     <MdOutlinePersonOutline />
          </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2 }}>
      <Typography  component="span">
        某位
      </Typography>
      <Typography variant="caption">会计是一项人类活动（如果没有人类就没有“会计”）</Typography>
    </TimelineContent>
  </TimelineItem>
  <TimelineItem>
    <TimelineOppositeContent
      sx={{ m: 'auto 0' }}
      variant="caption"
      color="text.secondary"
    >
1920 年代至 60 年代
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot color="primary">
        <LaptopMacIcon />
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>

    <TimelineContent sx={{ py: '12px', px: 2 }}>
      <Typography  component="span">
      <Link to='/support/darwin' className="文内链接">会计达尔文主义</Link>
                  </Typography>
      <Typography variant="caption">依赖归纳过程，<span className="文中强调">归纳法</span>是指通过观察“现实世界”现象来发展思想或理论，理论是从观察会计师在实践中所做的事情发展而来，惯例被编纂为会计学说。</Typography>
    </TimelineContent>
  </TimelineItem>

  <TimelineItem>
  <TimelineOppositeContent
      sx={{ m: 'auto 0' }}
      align="right"
      variant="caption"
      color="text.secondary"
    >
1960 年代至 70 年代
    </TimelineOppositeContent>

    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot color="primary" variant="outlined">
        <HotelIcon />
      </TimelineDot>
      <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
    </TimelineSeparator>


    <TimelineContent sx={{ py: '12px', px: 2 }}>
      <Typography  component="span">
      规范理论
      </Typography>
      <Typography variant="caption"><span className="文中强调">演绎的</span>，基于逻辑争论，寻求发展新的会计方法，理论对历史成本会计持批判态度，寻求提供改进的资产估值方法。</Typography>
    </TimelineContent>
  </TimelineItem>

 {/* 第四部分 */}
  <TimelineItem>
  <TimelineOppositeContent
      sx={{ m: 'auto 0' }}
      align="right"
      variant="caption"
      color="text.secondary"
    >
1970 年代至 2010 年代
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      <TimelineDot color="secondary">
        <RepeatIcon />
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2 }}>
      <Typography  component="span">
      <Link to='/support/positivism' className="文内链接">Accounting Positivism 会计实证主义</Link>
      </Typography>
      <Typography variant="caption">Accounting positivism is a theoretical perspective in accounting research that emphasizes the objective and quantitative nature of accounting information. It argues that accounting is a neutral reflection of objective reality and that accounting information can be quantified and measured. This perspective is based on the idea that accounting is a science and that it can be studied using the scientific method. It also holds that accounting principles and practices can be developed through the application of logical and empirical reasoning. Accounting positivism is often contrasted with interpretivism, which holds that accounting is a social construct that is shaped by the perspectives and biases of those who create and use it.1976年，美国会计学家詹森指出，实证会计理论将能解释：（1）为什么会计是这样；（2）为什么会计人员从事这样的工作；（3 ）这些现象在人力和资源利用方面产生什么效果。</Typography>
    </TimelineContent>
  </TimelineItem>
  {/* 第五部分 */}
  <TimelineItem>
  <TimelineOppositeContent
      sx={{ m: 'auto 0' }}
      align="right"
      variant="caption"
      color="text.secondary"
    >
2010 年代 -  
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot color="primary" variant="outlined">
        <HotelIcon />
      </TimelineDot>
      <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2 }}>
      <Typography  component="span">
      Accounting Realism
            </Typography>
      <Typography variant="caption">
      Accounting realism is a theoretical perspective in accounting research that seeks to bridge the gap between positivism and interpretivism. It recognizes that accounting is both a reflection of objective reality and a social construct shaped by the perspectives and biases of those who create and use it. Accounting realism holds that accounting information is not just a neutral reflection of objective reality, but is also influenced by the social, political and economic context in which it is created and used. This perspective emphasizes the need to consider the complexity of accounting phenomena and the role of human agency in accounting practice and decision-making.
It also acknowledges that accounting itself is an ongoing process of negotiation and manipulation between various stakeholders, rather than a neutral reflection of a pre-existing reality.
      </Typography>
    </TimelineContent>
    </TimelineItem>

{/* 第六部分 */}
<TimelineItem>
  <TimelineOppositeContent
      sx={{ m: 'auto 0' }}
      align="right"
      variant="caption"
      color="text.secondary"
    >
1970 年代至 2010 年代
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      <TimelineDot color="secondary">
        <RepeatIcon />
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2 }}>
      <Typography  component="span">
      Accounting Interpretivism 会计诠释主义
      </Typography>
      <Typography variant="caption">
      Accounting interpretivism is a theoretical perspective in accounting research that emphasizes the subjective and interpretive nature of accounting information and the role of human interpretation in the accounting process. It argues that accounting is not simply a neutral reflection of objective reality, but is instead a social construct that is shaped by the perspectives and biases of those who create and use it. This perspective is often contrasted with positivism, which holds that accounting information is objective and can be quantified and measured.
      </Typography>
    </TimelineContent>
  </TimelineItem>

  realism
 <TimelineItem>
  <TimelineOppositeContent
      sx={{ m: 'auto 0' }}
      align="right"
      variant="caption"
      color="text.secondary"
    >
2010 年代 -  
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot color="primary" variant="outlined">
        <HotelIcon />
      </TimelineDot>
      <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2 }}>
      <Typography  component="span">
      Linguistic Turn of Accounting with Economics Foundation 具有经济学基础的会计学的语言学
      </Typography>
      <Typography variant="caption">It is suggested that there should be different accounting languages, accounting standards do not necessarily need to converge, and for the principle of economy of effort (the limited nature of language storage and transmission), the accounting language should be redesigned.应该有不同的会计语言，会计准则不一定需要趋同，出于省力原则（语言存储和传输的有限性）重新设计会计语言</Typography>
    </TimelineContent>
    </TimelineItem>

</Timeline>
              </TabPanel>
      <TabPanel value={value} index={1}>
      会计学有许多不同的理论，其中一些是比较出名的。下面是一些例子：
      <ul>
<li>
会计实体理论：会计应该只记录某个组织所属的事物，而不是记录个人所有的事物。
</li><li>

会计核算主体理论：会计应该按照一定的标准来核算组织的财务状况，而不是按照个人的观点来核算。
</li><li>

可比较性理论：会计信息应该具有可比性，即可以与其他组织的会计信息进行比较。
</li><li>

会计准则理论：会计应该遵循一定的准则来确定如何记录和报告财务信息，这些准则可以是法律、法规、行业准则或国际准则。
</li><li>

实际权益理论：会计应该根据实际的资产和负债来报告财务信息，而不是根据账面价值来报告。
</li><li>

决策有用性理论：会计信息应该具有决策有用性，即能够帮助决策者作出正确的决策。
当然，还有很多其他出名的会计学理论，例如：
</li><li>

稳定价值理论：会计信息应该使用稳定的价值来报告财务信息，而不是使用浮动的价值。
</li><li>

现金流量理论：现金流量是更好的衡量企业财务状况的指标，而不是盈利情况。
</li><li>

公允价值理论：会计信息应该使用公允价值来报告财务信息，而不是使用历史成本。
</li><li>

实际收益理论：会计信息应该使用实际收益来报告财务信息，而不是使用会计收益。
</li><li>

决策关系理论：会计信息应该具有与决策相关的信息，并且这些信息应该反映出决策者需要考虑的所有因素。
</li>
</ul>
<Alert  icon={false} severity="warning">
        <AlertTitle>会计理论较少</AlertTitle>
        会计理论是指关于会计如何进行记录、报告财务信息的原则和方法的理论。实际上，会计理论并不是很多，但是这些理论都非常重要，因为它们为会计实践提供了指导。这些理论可以帮助我们确定会计信息应该如何记录、报告，以及这些信息的意义和用途。会计理论的发展也反映了社会和经济环境的变化，因此它也是会计实践发展的重要基础。会计理论的数量完全取决于会计的发展需要。随着时间的推移，会计的记录和报告方法也在不断发展和改进，因此会计理论也在不断发展。会计理论的发展是为了解决会计实践中遇到的问题，并提供有效的解决方案。会计理论的数量不是目标，而是为了解决实际问题而产生的结果。
      </Alert>
    </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
      会计达尔文主义是一种会计理论，它认为会计信息应该描述经济实体在某一时间内的经济活动，并且应该为决策者提供关于经济实体未来可能的结果的信息。会计达尔文主义强调会计信息的决策用途，并认为会计信息应该以透明和客观的方式呈现，以便决策者能够做出明智的决策。

会计达尔文主义的基本原则包括：会计单位原则、会计时间原则、会计计量原则和会计披露原则。会计单位原则规定会计信息应该按照一个经济实体的范围来描述，因此会计信息应该反映一个经济实体内部的经济活动。会计时间原则规定会计信息应该按照一个特定的时间段来描述，因此会计信息应该反映一个特定时间段内的经济活动。会计计量原则规定会计信息应该按照一种标准化的计量方法来描述，因此会计信息应该以一种精确的方式反映经济活动的财务影响。


      </TabPanel>
    </Box>
    </div>
  );
}


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));


export function IntelligenteAccounting() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>
      <Tabs
       variant="scrollable"
       scrollButtons
       allowScrollButtonsMobile
       value={value}
       onChange={handleChange}
       aria-label="Vertical tabs example"

      >
        <Tab label=
              {
                <>
                  <Typography variant="caption">
                  definition
                 </Typography>
                </>
             }
        {...a11yProps(0)} />
        <Tab label=
                {
                  <>
                    <Typography variant="caption">
                    Modular
                   </Typography>
                  </>
               }
         {...a11yProps(1)} />
        <Tab label=
                {
                  <>
                    <Typography variant="caption">
                    environmental awareness
                   </Typography>
                  </>
               }
           {...a11yProps(2)} />
        <Tab label=
                {
                  <>
                    <Typography variant="caption">
                    workflow
                   </Typography>
                  </>
               }
         {...a11yProps(3)} />
        <Tab label=
             {
              <>
                <Typography variant="caption">
                technical framework
               </Typography>
              </>
           }
          {...a11yProps(4)} />
        <Tab label=
                {
                  <>
                    <Typography variant="caption">
                    algorithm
                   </Typography>
                  </>
               }
        {...a11yProps(5)} />
        <Tab label=
            {
              <>
                <Typography variant="caption">
                algorithm
               </Typography>
              </>
           }
          {...a11yProps(6)} />
        <Tab label="GPT" {...a11yProps(7)} />

      </Tabs>
      <TabPanel value={value} index={0}>
      <p className="philosophy-text">
      会计是将数据通过映射关系转换成另一种形式的数据，并且相同的输入会产生相同的输出。这正是神经网络适用的场景。神经网络是由大量神经元组成的多层网络，它可以通过调整权值来学习输入输出之间的映射关系，进而产生准确的预测。在会计领域，神经网络可以用来学习会计函数，并通过模拟会计系统的行为来实现复杂的应用。
      <Alert  icon={false} variant="outlined" severity="info">  
  <AlertTitle>Accounting</AlertTitle>
  Accounting is the process of recording, classifying, and summarizing financial transactions to provide information 
that is useful in making business decisions. This information is used to prepare financial statements, such as 
balance sheets, income statements, and cash flow statements, which are used by managers, investors, and other 
stakeholders to evaluate the financial performance and health of a company. Additionally, accounting is used to 
ensure compliance with laws and regulations related to financial reporting.
    </Alert>

      <Alert  icon={false} variant="outlined" severity="info">  
      <AlertTitle>The Intelligent Accounting</AlertTitle>

            Intelligent Accounting is a method of using artificial intelligence technology to automatically process accounting data and tasks. It aims to help accountants complete their work faster and more accurately, and can help businesses better manage their financial information. Intelligent Accounting can use natural language processing, machine learning, and other AI techniques to solve accounting problems, such as automatically classifying accounts, generating financial statements, and predicting future financial trends. It can also be integrated with accounting software and other financial management tools to help businesses better manage their financial information. Overall, Intelligent Accounting is a method of using AI technology to improve accounting efficiency and accuracy, and can provide better financial management and decision support for businesses.
        </Alert>Intelligent Accounting是一种使用人工智能技术来
自动处理会计数据和任务的方法。它旨在帮助会计师更快、更准确地完成会计工作，并且可以帮助企业更
好地管理其财务信息。
智能会计可以使用自然语言处理、机器学习和其他人工智能技术来解决会计问题，例如自动分类账目、生成财务
报表、预测未来财务趋势等。智能会计也可以与会计软件和其他财务管理工具集成，以帮助企业更好地管理其财
务信息。

总的来说，智能会计是一种利用人工智能技术来提高会计效率和准确性的方法，能够为企业提供更好的财务管理
和决策支持。
智能会计是一种使用人工智能技术来自动完成会计任务的方法。它可以通过自然语言处理、机器学习和计算机视觉等技术来帮助会计人员完成重复性工作，提高工作效率。

例如，智能会计系统可以帮助会计人员自动提取账单中的信息，并将其录入会计系统；也可以帮助会计人员自动生成报表，并提供数据分析帮助会计人员更好地理解业务状况。

智能会计可以帮助会计人员更快速地完成工作，并为会计人员提供更多的时间来思考如何为企业做出更有价值的贡献。</p>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <p className="philosophy-text">
      <Alert  icon={false} variant="outlined" severity="info">
      <AlertTitle>Object</AlertTitle>
      Traditional accounting functions refer to functions used for accounting calculations. These functions can be used in accounting software or spreadsheets to help users calculate common accounting metrics such as gross margin, accounts receivable turnover, and debt-to-equity ratio. For example, to calculate a company's gross margin, you can use the following formula: Gross margin = (Revenue - Cost) / Revenue. You can use this formula in a spreadsheet to calculate gross margin, or you can use an accounting function in accounting software to do the calculation. There are many different accounting functions available for use, each with a specific purpose. For example, the DEPR function can be used to calculate depreciation, the FV function can be used to calculate future value, and the PMT function can be used to calculate the amount of each payment. Accounting functions can save time and help you calculate accounting metrics more accurately.

Accounting subjects can be viewed as classes and functions. You can view accounting subjects as classes (class) and functions (function), the specific implementation is as follows: define a class for each subject and define the corresponding attributes and methods for the class. For example, for the assets class, you can define it as follows: define a class for each subject and define the corresponding attributes and methods for the class. For example, for the assets class.
</Alert>
      传统会计函数是指用于会计计算的函数。这些函数可以在会计软件或电子表格中使用，帮助用户计算常见会计指标，如毛利率、应收账款周转率、负债率等。例如，要计算一个公司的毛利率，可以使用以下公式：毛利率 = (收入 - 成本) / 收入您可以在电子表格中使用公式计算毛利率，也可以使用会计软件中的会计函数来计算。有许多不同的会计函数可供使用，每个函数都有特定的用途。例如，函数 DEPR 可用于计算折旧，函数 FV 可用于计算未来值，函数 PMT 可用于计算每期支付金额。会计函数可以节省时间并帮助您更准确地计算会计指标。
      把会计的科目都看成class和function。你可以把会计的科目看成类（class）和函数（function），具体实现方法如下：为每个科目定义一个类，并为类定义相应的属性和方法。例如，对于资产类，可以定义如下：为每个科目定义一个类，并为类定义相应的属性和方法。例如，对于资产类，可以定义如下：
    为每个类定义相应的函数，用于计算科目间的关系。例如，可以定义如下函数计算资产总额：使用上述类和函数来处理会计科目。例如，可以创建一个资产对象，并使用函数计算资产总额：
      </p>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Alert  icon={false} variant="outlined" severity="info">
      <AlertTitle>Environment Perception</AlertTitle>
      Accounting environment perception refers to the ability of accountants to perceive and understand the information and factors in their surroundings. These information and factors include:

Economic environment: including national economic policies, market trends, and competition
Legal and regulatory environment: including accounting standards, tax laws, and company laws
Political and social factors: including government policies, social trends, and public opinion

The ability to perceive the accounting environment is crucial for accountants, as it helps them correctly understand the background and meaning of accounting information, and thus make the right decisions and judgments.

Accounting environment intelligent perception refers to the use of artificial intelligence technology to help accountants perceive and understand the information and factors in the accounting environment. This can be achieved through techniques such as natural language processing, machine learning, and data mining.

For example, natural language processing technology can be used to analyze large amounts of news reports and legal text to extract information about the economic environment, legal and regulatory environment, and political and social factors. Machine learning technology can help accountants build models that predict changes in accounting information. Data mining technology can help accountants discover new patterns and relationships from large amounts of data.

Accounting environment intelligent perception can help accountants obtain information faster, better understand the background and meaning of information, and make more accurate decisions and judgments.
      </Alert >

      会计环境感知指会计人员对周围环境中的信息和因素进行感知和理解的能力。这些信息和因素包括：

经济环境：包括国家的经济政策、市场趋势和竞争情况等
法律法规：包括会计准则、税法和公司法等
政治和社会因素：包括政府政策、社会趋势和公众意见等
会计环境感知能力对会计人员的工作非常重要，因为它能帮助他们正确理解会计信息的背景和意义，从而做出正确的决策和判断。
会计环境智能感知是指使用人工智能技术来帮助会计人员感知和理解会计环境的信息和因素。这可以通过自然语言处理、机器学习、数据挖掘等技术来实现。

例如，可以使用自然语言处理技术来分析大量的新闻报道和法律文本，从中提取有关经济环境、法律法规和政治和社会因素的信息。机器学习技术可以帮助会计人员建立模型，从而预测会计信息的变化趋势。数据挖掘技术可以帮助会计人员从大量的数据中发现新的规律和关系。

会计环境智能感知可以帮助会计人员更快地获取信息、更好地理解信息的背景和意义，从而做出更准确的决策和判断。
      </TabPanel>
      <TabPanel value={value} index={3}>
      <Alert icon={false}  variant="outlined" severity="info">
      <AlertTitle>Workflow</AlertTitle>
      The intelligent accounting workflow refers to the process of automating accounting processes using artificial intelligence technology. This can be achieved through several steps:

Data collection and preprocessing: First, accounting data is collected and necessary preprocessing is done to prepare the data for further artificial intelligence processing.
Model training: Using machine learning techniques, the accounting data is trained to build models for automatically completing accounting tasks.
Model application: The trained models are applied to actual accounting processes to automatically complete accounting tasks.
Result analysis and evaluation: The output results of the models are analyzed, their accuracy and reliability are evaluated, and the models are adjusted and improved as necessary.

The intelligent accounting workflow can help accountants complete their work faster, improve their efficiency, and continuously learn and improve their capabilities.
</Alert >

      智能会计工作流程是指使用人工智能技术来自动化会计流程的工作流程。这可以通过以下几个步骤来实现：

数据收集和预处理：首先，需要收集会计数据，并进行必要的预处理，以便为后续的人工智能处理做好准备。

模型训练：使用机器学习技术，对会计数据进行训练，建立用于自动完成会计任务的模型。

模型应用：将训练好的模型应用到实际的会计流程中，自动完成会计任务。

结果分析和评估：分析模型的输出结果，评估其准确性和可靠性，并根据需要对模型进行调整和改进。

智能会计工作流程可以帮助会计人员更快地完成工作，提高工作效率，并且能够持续学习和改进自己的能力。

      </TabPanel>
      <TabPanel value={value} index={4}>
      <Alert  icon={false} variant="outlined" severity="info">  
      <AlertTitle>Technical framework</AlertTitle>

      The technical framework of Intelligent Accounting refers to using Artificial Intelligence technology to achieve the technical structure of Intelligent Accounting. This can include technologies such as Natural Language Processing (NLP), which can be used to parse and understand accounting text such as financial reports, contracts and legal documents, Machine Learning (ML) which can be used to analyze and predict accounting data, such as forecasting a company's financial performance or identifying potential financial risks, Data Mining which can be used to find new patterns and relationships from large amounts of accounting data, such as identifying outliers or changes in patterns in financial data, Image Processing which can be used to recognize and understand accounting images such as financial charts and vouchers. These technologies can be combined to help accountants complete their work more quickly, improve efficiency and continuously learn and improve their abilities. An Intelligent Accounting system is a system that uses Artificial Intelligence technology to automate accounting processes. It can be implemented through technologies such as NLP, ML, Data Mining and Image Processing, it can help accountants complete their work more quickly, improve efficiency and continuously learn and improve their abilities.
      
      </Alert>
      智能会计的技术框架是指使用人工智能技术来实现智能会计的技术结构。这可以包括以下几种技术：

自然语言处理：可以用于解析和理解会计文本，例如财务报告、合同和法律文件等。

机器学习：可以用于分析和预测会计数据，例如预测公司的财务业绩、识别潜在的财务风险等。

数据挖掘：可以用于从大量的会计数据中发现新的规律和关系，例如发现财务数据中的异常值或者模式变化等。

图像处理：可以用于识别和理解会计图像，例如财务图表和凭证等。

这些技术可以结合使用，帮助会计人员更快地完成工作，提高工作效率，并且能够持续学习和改进自己的能力。智能会计系统是一种使用人工智能技术来自动化会计流程的系统。它可以通过自然语言处理、机器学习、数据挖掘和图像处理等技术来实现。智能会计系统可以帮助会计人员更快地完成工作，提高工作效率，并且能够持续学习和改进自己的能力。
<Alert  icon={false} severity="success">
机器学习框架是指用于构建机器学习系统的软件框架。机器学习框架可以提供数据处理、模型训练、模型评估和模型应用等功能。
常用的机器学习框架包括：
TensorFlow：由Google开发的机器学习框架，支持多种机器学习算法和模型。
PyTorch：由Facebook开发的机器学习框架，支持深度学习和自然语言处理
      </Alert>
      <Alert  icon={false}  variant="outlined" severity="info">  
 <AlertTitle>Functions</AlertTitle>
 The main functions of an Intelligent Accounting system include: Text analysis, which can use natural language processing techniques to analyze accounting text such as financial reports, contracts, and legal documents to extract useful information. Data prediction, which can use machine learning techniques to analyze accounting data and predict a company's financial performance and identify potential financial risks. Decision support, which can use data mining techniques to find new patterns and relationships from large amounts of accounting data to provide support for decision-making. Voucher processing, which can use image processing techniques to recognize and understand accounting images such as financial vouchers and automate tasks such as voucher input, verification, and review. Financial statement generation, which can use AI technology to automatically generate financial statements, reducing the time and errors of manual operations. Process optimization, which can use AI technology to analyze accounting processes, identify bottlenecks, and find improvement opportunities to optimize accounting processes. The application of an Intelligent Accounting system can help accountants complete their work more quickly, improve efficiency and continuously learn and improve their abilities.
 
 </Alert>
智能会计系统的主要功能包括：

文本分析：可以使用自然语言处理技术来分析会计文本，例如财务报告、合同和法律文件等，从中提取有用信息。

数据预测：可以使用机器学习技术来分析会计数据，预测公司的财务业绩、识别潜在的财务风险等。

决策支持：可以使用数据挖掘技术来从大量的会计数据中发现新的规律和关系，为决策提供依据。

凭证处理：可以使用图像处理技术来识别和理解会计图像，例如财务凭证等，自动完成凭证录入、核对和复核等任务。

财务报表生成：可以使用人工智能技术自动生成财务报表，减少人工操作的时间和误差。

过程优化：可以使用人工智能技术来分析会计流程，找出瓶颈和改进机会，优化会计流程。

智能会计系统的应用可以帮助会计人员更快地完成工作，提高工作效率，并且能够持续学习和改进自己的能力。
 </TabPanel>
 <TabPanel value={value} index={5}>
 <Alert  icon={false} variant="outlined" severity="info">  
 <AlertTitle> Algorithms</AlertTitle>
 Naive Bayes classification algorithm: a classification algorithm based on Bayes' theorem, commonly used for tasks such as text classification and spam email filtering.

Decision tree algorithm: a tree-based classification and regression algorithm, commonly used for classification decisions and predictions.

k-nearest neighbor algorithm: an instance-based learning algorithm, commonly used for classification and regression.

Support Vector Machine (SVM) algorithm: a linear model used for classification and regression, commonly used for high-dimensional data analysis.

Neural Network algorithm: a machine learning algorithm based on artificial neural network models, commonly used for classification and prediction.

These algorithms can be used to implement different Intelligent Accounting functions, such as text analysis, data prediction, and decision support.
 
 </Alert>
 在智能会计中，常用的算法包括：

朴素贝叶斯分类算法：是一种基于贝叶斯定理的分类算法，常用于文本分类和垃圾邮件过滤等任务。

决策树算法：是一种基于树形模型的分类和回归算法，常用于分类决策和预测。

k-近邻算法：是一种基于实例的学习算法，常用于分类和回归。

支持向量机算法：是一种用于分类和回归的线性模型，常用于高维数据分析。

神经网络算法：是一种基于人工神经网络模型的机器学习算法，常用于分类和预测。

这些算法可以用于实现不同的智能会计功能，例如文本分析、数据预测和决策支持等。
 </TabPanel>
 <TabPanel value={value} index={6}>
 <Alert  icon={false} variant="outlined" severity="info">  
 <AlertTitle> Unmanned </AlertTitle>
 Intelligence refers to the ability of an entity, whether it be a human or a machine, to solve problems, learn new knowledge, think and make decisions. In the field of artificial intelligence, intelligence typically refers to the abilities of a machine. The goal of artificial intelligence is to enable machines to automatically perform many tasks that humans would do and to continuously learn and improve their abilities.
Therefore, intelligence is unmanned. However, artificial intelligence is developed and controlled by humans and can also be used by humans to perform many tasks. So the accounting is unmanned.

Unmanned accounting refers to the method of completely automating accounting processes using artificial intelligence technology, without the need for human intervention. Unmanned accounting systems can use technologies such as natural language processing, machine learning, data mining, and image processing.

The main functions of unmanned accounting systems include:

Automatic voucher entry: use image processing technology to recognize and understand vouchers images, automatically completing voucher entry and checking.

Automatic financial statement generation: using artificial intelligence technology to automatically generate financial statements, reducing the time and errors of manual operations.

Automatic financial analysis: using machine learning techniques to analyze financial data, predict financial performance, and identify potential financial risks.

Process optimization: using AI technology to analyze accounting processes, identify bottlenecks, and find improvement opportunities to optimize accounting processes.

Overall, unmanned accounting can help to improve the efficiency and accuracy of accounting tasks, and reduce the need for manual intervention.
 
 </Alert>
 智能是指人或机器具有的解决问题、学习新知识、思考和决策的能力。在人工智能领域，智能通常指机器具有的能力。人工智能的目标是使机器能够自动完成许多人类会做的任务，并且能够持续学习和改进自己的能力。

因此，智能是无人的。但是，人工智能是由人类开发和掌握的，并且人类也可以利用人工智能来完成许多任务。所以会计是无人的。
无人会计是指使用人工智能技术完全自动化会计流程的方法，不需要人工干预。无人会计系统可以使用自然语言处理、机器学习、数据挖掘和图像处理等技术来实现。

无人会计系统的主要功能包括：

自动录入凭证：使用图像处理技术识别和理解凭证图像，自动完成凭证录入和核对。

自动生成财务报表：使用人工智能技术自动生成财务报表，减少人工操作的时间和误差。

自动完成财务分析：使用机器学习
 </TabPanel>
 <TabPanel value={value} index={7}>
 <Alert  icon={false} severity="info">
        <AlertTitle>GPT</AlertTitle>
        GPT是一种基于语言模型的人工智能系统，由OpenAI公司开发。GPT（Generative Pre-training Transformer）是一种大型语言模型，用于预测下一个词在句子或文章中出现的可能性。GPT可以用于自然语言生成、问答系统、机器翻译和其他自然语言处理任务。

GPT通过预先训练（pre-training）的方式来学习语言表示，然后可以用于许多不同的任务，而不需要再进行特定的任务训练。这种方法使得GPT能够快速学习新的语言任务，并且表现出优异的性能。
      </Alert>
会计GPT是指将GPT技术应用于会计领域的系统。会计GPT可以用于自动生成会计文本、财务报表、合同等，并且可以用于回答会计相关的问题。

例如，会计GPT可以用于自动生成财务报告，减少人工操作的时间和误差。它也可以用于回答会计相关的问题，例如会计原则、会计准则、税法等。

会计GPT的应用可以帮助会计人员更快地完成工作，提高工作效率，并且能够持续学习和改进自己的能力。
 </TabPanel>
 <TabPanel value={value} index={8}>
 </TabPanel>
 <TabPanel value={value} index={9}>
 </TabPanel>
 <TabPanel value={value} index={10}>
 </TabPanel>
 <TabPanel value={value} index={11}>
 </TabPanel>
    </Box>
  );
}