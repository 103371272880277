import React from "react";
import {Title} from "./Overview"
import Rating from '@mui/material/Rating';
import {Metaphor_Biao} from "../components/Biao";
import Alert from '@mui/material/Alert';
import {Asset} from "./Code"
import ReactMarkdown from 'react-markdown'

export const Metaphor = () => (
    <>
           <Title>Accounting Researved Word 会计隐喻 <Rating name="read-only" value={4} readOnly /></Title>
    <ReactMarkdown>## Hello, *world*!</ReactMarkdown>
    <Asset code={markdown}/>
      <p className="philosophy-text">
      <Alert variant="outlined"severity="info">

      From a cognitive linguistic perspective, analyzing <b>metaphorical expressions</b> used in accounting language involves understanding how people use language to create and convey meaning, and how these metaphorical expressions are grounded in our everyday experiences and conceptualizations.

One way to analyze accounting metaphors from a cognitive linguistic perspective is to examine the source domains and target domains of the metaphorical expressions. For example, the source domain of the metaphor "balancing the books" is the physical act of balancing objects, and the target domain is the financial records of an organization. By understanding the source domain, we can better understand how people use this metaphor to convey the idea of making sure that financial records are accurate and in agreement.

Another way to analyze accounting metaphors from a cognitive linguistic perspective is to examine the conceptual mapping that takes place between the source and target domains. For example, the metaphor "cash flow is the lifeblood of a business" maps the concept of cash flow onto the concept of the lifeblood of a living organism, highlighting the importance of cash flow for the survival and well-being of a business.

It is also important to note that the <span className="文中强调">cognitive linguistic perspective </span>emphasizes that metaphor is not only a figure of speech, but also <span className="文中强调">a fundamental cognitive process</span>, that <b>enables us to understand abstract concepts by mapping them onto more concrete and familiar experiences</b>. Thus, accounting metaphors are not just a way to make accounting language more interesting, but also a powerful tool to make the abstract concepts of accounting more accessible and understandable for the audience.
</Alert>
</p>
<Metaphor_Biao/>
    </>
  )


const markdown = `
  ~~~python
  '''
  The most important attribute of an asset is its ability to generate 
  cash flow or appreciate in value. This allows the asset to provide a 
  return on investment or to be sold at a profit in the future.
  ''' 
  import numpy as np
  from datetime import datetime
  import pandas as pd
  import matplotlib.pyplot as plt
  class Asset:
      def __init__(self, account, debit,date):
          self.account = account
          self.debit = debit
          self.date = date
          self.assets=[]
          self.roa = np.random.pareto(1)*self.debit
          self.cashflows=[]
          self.is_measurable = True
          self.likely_economic_benefit = True
          self.potential_use=""
          self.market_value = debit
          self.bubble=self.is_asset_bubble(self.market_value)
          self.target_value = None
          self.uniqueness= None
      def __repr__(self):
          return f"Asset({self.account}, {self.debit}, {self.date})"
      def add_asset(self, account, debit,date):
          self.assets.append(Asset(account, debit,date))
      def straight_line_depreciation(self,salvage_value, life):
          annual_depreciation = (self.debit - salvage_value) / life
          return annual_depreciation
  ~~~
  `