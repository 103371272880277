import React from "react";
export const Accounting = () => (
    <div>
            <ol>
<li >
     <a className="参考文献" href="https://pan.baidu.com/s/1OnLTYfUwr_xpZLBW1-IQAw?pwd=fh42" target="_blank" rel="noreferrer">
         Grady, P. (1965). Inventory of Generally Accepted Accounting Principles in the USA. The Accounting Review, 40(1), 21.
        </a>
        </li>
        <li >
     <a className="参考文献" href="https://pan.baidu.com/s/1j2rxaE_r6KJM57wZ8gTOhw?pwd=kedu" target="_blank" rel="noreferrer">
     Deegan, C. M. (2013). Financial accounting theory/Craig Deegan. In Accounting Forum (Vol. 20, No. 5, pp. 63-73). in Australia by McGraw-Hill Education (Australia) Pty Ltd Level 2, 82 Waterloo Road, North Ryde NSW 2113.
        </a>
        </li>
        <li >
<a className="参考文献" href="https://pan.baidu.com/s/1HtTsD2p6zJK29rk6LMnFqg?pwd=naot" target="_blank" rel="noreferrer">
***Deegan, C. M. (2013). Financial accounting theory/Craig Deegan. PPT
   </a>
   </li>
   <li >
<a className="参考文献" href="https://pan.baidu.com/s/18TJ7lej4WsFYrudo8T7NKw?pwd=mnbm" target="_blank" rel="noreferrer">
Gaffikin, M., & Aitken, M. (Eds.). (2014). The Development of Accounting Theory (RLE Accounting): Significant Contributors to Accounting Thought in the 20th Century. Routledge.
   </a>
   </li>
   <li >
<a className="参考文献" href="https://pan.baidu.com/s/1Mn7iE6jp-YR_ysgvAyBRPA?pwd=g73k" target="_blank" rel="noreferrer">
郑建友.论会计研究中的实证主义[J].上海会计,1997(08):32-34.
   </a>
   </li>
        </ol>
    </div>
  );
