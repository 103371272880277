import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {StreamlitCard} from "../components/Card"
import cpanlp1 from '../images/streamlit/cpanlp1.svg';
import cpanlp2 from '../images/streamlit/cpanlp2.png';
import cpanlp3 from '../images/streamlit/cpanlp3.png';
import cpanlp4 from '../images/streamlit/cpanlp4.png';
import cpanlp5 from '../images/streamlit/cpanlp5.png';
import cpanlp6 from '../images/streamlit/cpanlp6.png';
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Grid';

const SearchBar = ({setSearchQuery}) => (
  <form>
    <TextField
      id="search-bar"
      className="text"
      onInput={(e) => {
        setSearchQuery(e.target.value);
      }}
      variant="standard"
      placeholder="Search..."
      size="medium"
      type="search"
      fullWidth
    />
  </form>
);
const data = [
  {
      img: cpanlp1,
      title:"Stock Price Forecast股价预测",
      data:"Beijing北京",
      content:"sklearn,nlp",
      url:"https://cpanlp.streamlit.app/",
      detail:"厉害呢"
  },
  {
    img: cpanlp2,
    title:"Forecast预测",
    data:"Beijing北京",
    content:"cpa1",
    url:"https://cpanlp1.streamlit.app/",
    detail:"厉害呢"
},
{
  img: cpanlp3,
  title:"Regression回归",
  data:"Beijing北京",
  content:"cpa1",
  url:"https://cpanlp2.streamlit.app/",
  detail:"厉害呢"
},
{
  img: cpanlp4,
  title:"Get Data获取数据",
  data:"Beijing北京",
  content:"你好",
  url:"https://cpanlp3.streamlit.app/",
  detail:"厉害呢"
},
{
  img: cpanlp5,
  title:"Get Data获取数据",
  data:"Beijing北京",
  content:"你好",
  url:"https://cpanlp4.streamlit.app/",
  detail:"厉害呢"
},
{
  img: cpanlp6,
  title:"Vehicle Sales Valuation车辆销售估价",
  data:"Beijing北京",
  content:"sklearn 随机森林",
  url:"https://cpanlp5.streamlit.app/",
  detail:"厉害呢"
},
]
const filterData = (query, data) => {
  if (!query) {
    return data;
  } else {
    return data.filter((d) => d.title.includes(query) || d.content.includes(query) || d.detail.includes(query) || d.data.includes(query)) ;
  }
};
function GridItem(props) {
  return (
    // From 0 to 600px wide (smart-phones), I take up 12 columns, or the whole device width!
    // From 600-690px wide (tablets), I take up 6 out of 12 columns, so 2 columns fit the screen.
    // From 960px wide and above, I take up 25% of the device (3/12), so 4 columns fit the screen.
    <Grid item xs={12} sm={6} md={4}  >
      <Paper ><StreamlitCard  style= "string" img= {props.img} content={props.title} url={props.url} title={props.
content} detail={props.detail} data={props.data} /></Paper>
    </Grid>
  );
}
export function BasicGrid() {
  const [searchQuery, setSearchQuery] = useState("");
  const dataFiltered = filterData(searchQuery, data);
  return (
    <>
    <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
    
    <Box sx={{ flexGrow: 1 }}>
 <Grid container columnSpacing={2} rowSpacing={2} padding={2}>
 {dataFiltered.map((d) => (
        <GridItem title={d.title} img={d.img} content={d.content} detail={d.detail} data={d.data} url={d.url}/>
 ))}
      </Grid>
     </Box>
     </>
  );
}