import React from "react";
import {Title} from "./Overview"
import Alert from '@mui/material/Alert';
import Rating from '@mui/material/Rating';
import {ResearvedWord_Biao} from "../components/Biao";
import {Asset,asset_code} from "./Code"
import { useState, useEffect } from "react";
import Web3 from "web3";

export const Ajax = () => (
  <>
    <Title>Pyaccounting   <Rating name="read-only" value={5} readOnly />
</Title>
We believe that accounting has reached the most dangerous moment. At this time, it is necessary to promote the innovation of accounting language, create accounting's own python language, and objectify everything in accounting.我们认为，会计到了最危险的时刻，此时需要推动会计语言的创新，创立会计自己的python语言，把会计中的一切都给对象化。
<br />
There are a few reasons why it might be beneficial to develop a new accounting language based on pseudocode:
<ul><li>
Improved readability: A pseudocode-based accounting language could be designed to be more easily understood by non-accountants, making it easier for people from different backgrounds to understand and use the language. This can help to improve collaboration and communication within an organization.
</li><li>
Greater flexibility: An accounting language based on pseudocode could be more flexible than existing accounting languages, making it easier to adapt to new accounting standards or requirements.
</li><li>
Increased automation: By using a pseudocode-based accounting language, it may be easier to automate accounting tasks and processes. This could help to reduce the risk of errors and improve efficiency.
</li><li>
Better integration with other systems: By using a pseudocode-based accounting language, it may be possible to more easily integrate accounting systems with other business systems, such as ERP, CRM, and financial systems.
</li><li>
Better scalability: Pseudocode-based accounting language can be more scalable than existing accounting languages, making it easier to handle large amounts of data and complex transactions, which is important for large enterprises.
</li>
</ul>

<Alert  icon={false}  variant="filled" severity="warning">
However, it's also important to note that developing a new accounting language based on pseudocode would likely be a significant undertaking and would require significant resources, including time, money, and expertise. Additionally, existing accounting languages have been developed and refined over many years, and are widely used and accepted in the industry, so it would be important to consider the potential benefits and drawbacks of developing a new language before taking such an approach.
</Alert>
         <Alert  icon={false}  variant="outlined" severity="info">
      在会计中，会计逻辑是指会计原理、财务报告和财务分析的基础。这些原理包括了财务报表的核算和分类，财务信息的基本要素，财务信息的记录和报告标准，以及财务信息的分析和评估方法。这些原理是建立在经济学和商业知识基础之上的。

会计形式类似于 <abbr>CSS</abbr> (Cascading Style Sheets)，指的是会计信息呈现的格式和布局。这包括了财务报表的组织结构，财务信息的分类和标题，财务信息的表述方式和符号等。会计形式的目的是使财务报表更易于阅读和理解。

会计内容就是财务报告，是企业运营和财务状况的重要参考资料。财务报告包括了资产负债表、损益表和现金流量表等。这些报表是根据会计逻辑和会计形式制作出来的，提供了关于企业资产、负债、收益和现金流的信息。

总而言之，会计逻辑，会计形式和会计内容是相互关联的，共同组成了会计信息的整体。
</Alert>
    <main className="philosophy-text">
    <p className="philosophy-text">
    传统前端开发，我们通常会将结构（内容）（HTML）、样式（形式）（CSS）、逻辑（JavaScript）进行分离。但是在React的思想中认为逻辑本身和UI是无法分离的，所以才会有了JSX的语法。那样式呢？样式也是属于UI的一部分。而css-in-js就是将样式（CSS）也写到逻辑（JavaScript）中，并且可以方便的使用JavaScript的状态，所以React也被人称为All in JS。
    在会计中，会计逻辑就是经济学基础，会计形式就类似 <abbr>CSS</abbr> (Cascading Style Sheets)，会计内容就是财报。
    </p>
      <p className="philosophy-text">
      <span className="文中强调">会计逻辑</span>是指会计系统中数据和信息处理的逻辑流程。它包括记录、汇总、分析和报告财务信息的方法和规则。会计逻辑是会计系统的核心部分，决定了系统的正确性和有效性。
    </p>
    <p className="philosophy-text">在会计中，会计逻辑是指会计原理、财务报告和财务分析的基础。这些原理包括了财务报表的核算和分类，财务信息的基本要素，财务信息的记录和报告标准，以及财务信息的分析和评估方法。这些原理是建立在经济学和商业知识基础之上的。

会计形式类似于 CSS，指的是会计信息呈现的格式和布局。这包括了财务报表的组织结构，财务信息的分类和标题，财务信息的表述方式和符号等。会计形式的目的是使财务报表更易于阅读和理解。

会计内容就是财务报告，是企业运营和财务状况的重要参考资料。财务报告包括了资产负债表、损益表和现金流量表等。这些报表是根据会计逻辑和会计形式制作出来的，提供了关于企业资产、负债、收益和现金流的信息。

总而言之，会计逻辑，会计形式和会计内容是相互关联的，共同组成了会计信息的整体。
    会计逻辑和会计形式是会计系统中两个重要的部分。

会计逻辑是指会计系统中数据和信息处理的逻辑流程。它包括记录、汇总、分析和报告财务信息的方法和规则。会计逻辑是会计系统的核心部分，决定了系统的正确性和有效性。

而<span className="文中强调">会计形式</span>是指会计系统中数据和信息展示的格式。它包括账簿、报表和报告的格式和布局，以及数据展示的方式。会计形式是会计系统的外在表现，决定了系统的可读性和易用性。

会计逻辑和会计形式是相互联系的，会计逻辑保证了数据的正确性和有效性，而会计形式保证了数据的可读性和易用性。在设计和开发会计系统时，应该同时考虑会计逻辑和会计形式。
    </p>
    <p className="philosophy-text">
    会计逻辑和会计内容是会计系统中两个重要的部分。

会计逻辑是指会计系统中数据和信息处理的逻辑流程。它包括记录、汇总、分析和报告财务信息的方法和规则。会计逻辑是会计系统的核心部分，决定了系统的正确性和有效性。

而<span className="文中强调">会计内容</span>是指会计系统中记录和报告的具体财务信息。这包括资产、负债、所有者权益、收入和费用等财务指标。会计内容是会计系统的核心输出，提供了财务状况和经营绩效的信息。

会计逻辑和会计内容是相互联系的。会计逻辑决定了如何记录和处理会计信息，而会计内容是会计逻辑的输出。在设计和开发会计系统时，应该同时考虑会计逻辑和会计内容。
      </p>
      <p className="philosophy-text">
      会计逻辑、会计内容和会计形式是会计系统中三个重要的部分。

会计逻辑是指会计系统中数据和信息处理的逻辑流程。它包括记录、汇总、分析和报告财务信息的方法和规则。会计逻辑是会计系统的核心部分，决定了系统的正确性和有效性。

会计内容是指会计系统中记录和报告的具体财务信息。这包括资产、负债、所有者权益、收入和费用等财务指标。会计内容是会计系统的核心输出，提供了财务状况和经营绩效的信息。

而会计形式是指会计系统中数据和信息展示的格式。它包括账簿、报表和报告的格式和布局，以及数据展示的方式。会计形式是会计系统的外在表现，决定了系统的可读性和易用性。

会计逻辑、会计内容 和 会计形式 三者是相互联系的。会计逻辑决定了如何记录和处理会计信息，会计内容是会计逻辑的输出，而会计形式保证了会计内容的可读性和易用。
      </p>


</main>
<Title>Python 会计语言优劣
</Title>
<p className="philosophy-text">
Python语言和自然语言有着很大的区别，也有一些联系。下面是它们的优势和劣势的简要概括：

优势：

Python语言的优势在于：
<ul><li>
精确性：Python语言是一种精确的编程语言，它可以在计算机上准确地执行程序，避免了自然语言的模糊性和歧义性。
</li><li>
可重复性：Python语言的程序可以被重复执行，保证了结果的一致性和可靠性，而自然语言的表达方式则难以保证。
</li><li>
逻辑性：Python语言的程序通常遵循一定的逻辑规则和结构，便于程序员进行编写和维护，而自然语言则缺乏这种逻辑性。
</li><li>
自动化：Python语言可以通过编写程序实现各种自动化任务，提高工作效率和质量，而自然语言则需要人工进行处理和理解。
</li>
</ul>
劣势：

Python语言的劣势在于：
<ul><li>
学习成本：Python语言需要一定的编程知识和技能，需要进行专门的学习和训练，而自然语言则是人们天生掌握的交流工具。
</li><li>

表达能力：Python语言虽然可以用于表达各种算法和逻辑，但它的表达能力和灵活性受到编程语言本身的限制，难以表达所有的复杂思想。
</li><li>

交互性：Python语言的程序通常是通过计算机交互进行执行的，缺乏人际交互的互动性和情感表达能力。
</li>
</ul>
联系：

Python语言和自然语言之间也有一些联系，例如：

命名规则：Python语言中变量、函数等的命名规则通常遵循自然语言的语法和语义规则，易于理解和记忆。
自然语言处理：Python语言也可以用于自然语言处理领域，例如文本分析、自动翻译等，帮助计算机更好地理解和处理自然语言。
综上所述，Python语言和自然语言有着很大的区别，但在某些方面也存在一定的联系。 Python语言的优势在于精确性、可重复性、逻辑性和自动化，劣势在于学习成本、表达能力和交互性。
</p>
  </>
);
export const Css = () => (
    <>
        <Title>Accounting Css<Rating name="read-only" value={4} readOnly /></Title>
      <p className="philosophy-text">
      Cascading Style Sheets（简称 CSS）是一种用于描述 HTML 或 XML 文档的外观和格式的计算机语言。它可以让开发者对网页的布局、颜色、字体、图像等方面进行设置，使网页更美观、专业。

CSS 语言由一系列的样式规则构成，每个样式规则由选择器和声明两部分组成。选择器用于指定 HTML 元素，声明则用于描述选择器所指定元素的样式。

开发者可以通过不同的选择器和声明来控制 HTML 文档中各种元素的样式，从而达到美化网页的目的。CSS 语言非常灵活，可以让开发者创建出各种复杂的样式效果。

CSS 语言与 HTML 语言配合使用时，可以使网页更加美观、专业，也更加易于维护和更新。
生成器表达式是一种特殊的表达式，它允许你在创建一个列表、集合或字典时使用迭代器。它们是非常有用的，因为它们可以帮助你在不使用临时列表的情况下创建一个迭代器。会计也需要自己的生成表达式，这个代码片段创建了一个临时列表，然后使用这个列表创建了一个新的列表。如果你想避免使用临时列表，就可以使用生成器表达式：
</p>
    </>
  );
export const Naming = () => (
    <>
            <Title>Accounting Nomenclature<Rating name="read-only" value={3} readOnly /></Title>
       <Alert  icon={false}  variant="outlined" severity="info">
 Accounting nomenclature refers to the system of naming and classifying concepts and elements in accounting, 
such as accounts, transactions, and financial statements. This system, also known as accounting 
terminology, helps to ensure consistency and standardization in the way that accounting information is 
presented and communicated. The specific terms and classifications used in accounting nomenclature may vary 
depending on the country or region, as well as the type of organization or industry. Examples of accounting 
nomenclature include the Generally Accepted Accounting Principles (GAAP) in the United States, and the 
International Financial Reporting Standards (IFRS) adopted by many countries worldwide.
The camel case naming convention, also known as camel case or camel caps, is a naming convention used in accounting and other fields to help distinguish different words within a single phrase.

In camel case, the first word is written in lower case and subsequent words have the first letter capitalized, with no spaces or punctuation between words. The phrase "camelCase" is an example of camel case. This convention is often used to make the name more readable and to improve the appearance of the name.

In accounting, camel case is often used in naming fields, variables, and other elements in accounting software and systems. This allows for clear distinction between words, improving readability and understanding of the element being referred to, and also helps with consistency in naming conventions across different accounting systems.
</Alert>
      <p className="philosophy-text">
      这种命名方式被称为 <span className="文中强调">驼峰命名法（CamelCase）</span>。在 Python 中，这种命名方式通常用于类的命名,驼峰命名法（CamelCase）是一种编写英语词语的方法，其中第一个单词以小写字母开头，每个后续单词的首字母均为大写字母。这种命名方式常用于编写变量名或者函数名。在会计中，驼峰命名法可能用于编写程序代码，以表示某些财务概念或者数据。例如，可以使用驼峰命名法编写程序代码来表示会计期间的结束日期，变量名可能为 "endOfPeriod"。总的来说，驼峰命名法在许多编程语言中都很常见，包括 Java、C++、Python 和 Ruby 等。它能够使代码更具可读性，帮助开发人员更容易理解代码的意图。
</p>
<Alert  icon={false} variant="outlined" severity="info">
The naming conventions used in accounting terminology are based on linguistic principles and are designed to improve the clarity and consistency of communication within the field of accounting.
One principle that is often used in accounting is word order, which is the arrangement of words in a sentence to convey meaning. In accounting, the order of the words in a term often reflects the order in which the elements of a transaction or financial statement occur. For example, the term "revenue recognition" reflects the order in which revenue is recognized.
Another principle that is used in accounting is word composition, which is the way words are formed by combining smaller units of meaning. In accounting, this often takes the form of compounds, which are words made up of two or more smaller words. For example, "cash flow" is a compound word made up of "cash" and "flow."
Additionally, the use of affixation, which is the process of adding a prefix or suffix to a word to create a new word, is also frequently used in accounting. For example, "depreciation" is formed by adding the suffix "-ation" to the root word "depreciate."
All these principles are used to create terms that are clear, consistent, and easy to understand for the practitioners in accounting field.
</Alert>

    </>
  );

  export const PseudCode = () => (
    <>
            <Title>Accounting Pseudo Code<Rating name="read-only" value={4} readOnly /></Title>
     
      <p className="philosophy-text">
      会计伪代码是用来描述会计程序的流程和逻辑的文本。它通常使用类似于编程语言的语法，但是并不是真正的编程语言。会计伪代码可以用来描述会计程序的流程和逻辑，但是不能用来执行实际的计算。它主要目的是帮助会计人员理解程序的运行流程和逻辑。
</p>
<p className="philosophy-text">

<span className="文中强调">会计伪代码</span>通常使用类似于编程语言的语法，如IF-THEN, WHILE, FOR等等。它们用来描述程序的流程和逻辑。例如，一个会计伪代码可能会表示“如果账户余额小于零，则发出账户余额不足的警告”。

会计伪代码也可以用来描述数据的处理流程，例如从一个文件中读取数据，对数据进行处理，然后将其写入另一个文件。

通过使用会计伪代码，会计人员可以更好地理解程序的运行流程和逻辑，并能更好地识别和解决问题。此外，会计伪代码也有助于在程序开发过程中沟通和协调工作。
</p>
    </>
  )
  export const ResearvedWord = () => (
    <>
       <Title>Accounting Researved Word<Rating name="read-only" value={4} readOnly /></Title>
      <p className="philosophy-text">
      会计伪代码是用来描述会计程序的流程和逻辑的文本。它通常使用类似于编程语言的语法，但是并不是<b>真正</b>的编程语言。会计伪计程序的流程和逻辑，但是不能用来执行实际的计算。它主要目的是帮助会计人员理解程序的运行流程和逻辑。
</p>
<p className="philosophy-text">

<span className="文中强调">会计伪代码</span>通常使用类似于编程语言的语法，如IF-THEN, WHILE, FOR等等。它们用来描述程序的
辑。例如，一个会计伪代码可能会表示“如果账户余额小于零，则发出账户余额不足的警告”。

会计伪代码也可以用来描述数据的处理流程，例如从一个文件中读取数据，对数据进行处理，然后将其写入另一个文件。

通过使用会计伪代码，会计人员可以更好地理解程序的运行流程和逻辑，并能更好地识别和解决问题。此外，会计伪代码也有助于在程
协调工作。
</p>
<ResearvedWord_Biao/>
    </>
  )
  export const LifeCycle = () => (
    <>
       <Title>Accounting Lief Cycle<Rating name="read-only" value={3} readOnly /></Title>
      <p className="philosophy-text">
      会计科目的生命周期是指会计科目从创建到停用的过程。一般来说，会计科目的生命周期包括以下几个阶段：

创建阶段：在这个阶段，会计科目被创建并被分配编码。

使用阶段：在这个阶段，会计科目被用于记录和报告财务信息。

停用阶段：在这个阶段，会计科目不再被使用，但是可能会保留历史记录。

删除阶段：在这个阶段，会计科目被永久删除。

</p>
<p className="philosophy-text">

<span className="文中强调">会计生命周期</span>是指会计科目创建、更新和销毁过程中所经历的不同状态。会计科目在不同的阶段中被用于记录和报告财务信息。它提供了一种机制，使得会计人员可以在组件的不同状态之间进行交互。
</p>
<p className="philosophy-text">

会计科目生命周期可以借鉴 React 组件的生命周期设计思想。
<ul>
在会计科目生命周期中, 可以设计出类似于 React 组件的"mounting"、"updating"、"unmounting"三个阶段:
<li>
"mounting"阶段: 在这个阶段, 会计科目被创建并被分配编码, 此时类似于React组件的componentDidMount()。
</li><li>
"updating"阶段: 在这个阶段, 会计科目被用于记录和报告财务信息, 此时类似于React组件的componentDidUpdate()。
</li><li>
"unmounting"阶段: 在这个阶段, 会计科目不再被使用, 但是可能会保留历史记录, 此时类似于React组件的componentWillUnmount()。
</li><li>
通过这种方式, 可以使会计科目生命周期更加明确, 并能在不同阶段进行相应的操作。
"validating"阶段: 在这个阶段, 会计科目的输入和输出数据进行校验, 此时类似于React组件的componentWillMount()。
</li><li>
"archiving"阶段: 在这个阶段, 会计科目进入存档状态, 此时类似于React组件的componentWillUpdate()。
</li><li>
"recovering"阶段: 在这个阶段, 会计科目恢复使用, 此时类似于React组件的componentWillUnmount()。
</li>
</ul>

</p>
    </>
  )

  export const Decorator = () => (
    <>
            <Title>Accounting Decorator<Rating name="read-only" 
value={3} readOnly /></Title>
     
      <p className="philosophy-text">
      A Python decorator is a design pattern that allows you to modify the behavior of a function or class, often to add additional functionality such as logging, validation, or error handling. In the context of accounting, a decorator could be used to add functionality related to financial reporting, data analysis, or compliance.

</p>
<p className="philosophy-text">
One core use of an accounting decorator in Python could be to add logging 
functionality to track financial transactions. For example, a decorator could 
be used to log every time a financial transaction is made, including the date, 
amount, and details of the transaction. This could be useful for auditing or 
tracking the flow of funds within a company.

</p>
<p className="philosophy-text">
Another core use of an accounting decorator in Python could be to add data 
analysis functionality. For example, a decorator could be used to analyze 
financial data and provide insights into the financial health of a company, 
such as identifying trends in revenue or expenses. This could be useful for 
financial forecasting, budgeting, and decision making.

Another use of an accounting decorator could be to add compliance 
functionality, for example, a decorator could be used to check that 
transactions are compliant with regulations, or to automatically generate 
reports for compliance purposes.

It's important to note that the use of accounting decorator will depend on the 
company's specific situation and financials, it's not something that can be 
done with a general decorator. I would suggest consulting with financial and 
legal experts before implementing any real-world financial decisions.
</p>
    </>
  )
  export const Thinking = () => (
    <>
            <Title>Design Thinking 设计思想 <Rating name="read-only" 
value={5} readOnly /></Title>
     
      <p className="philosophy-text">
      在设计仿照 React 的智能会计语言时，可以采用类似 React 的组件化思想。将会计相关的功能抽象成独立的组件，这些组件可以被组合在一起构建出复杂的会计应用。类似 React 的组件，每个组件可以有自己的状态和事件处理函数。这样可以使得代码结构更加清晰，更容易维护和升级。
</p>
  Accounting Syntax
<Asset code={asset_code}/>
<Alert  icon={false} variant="filled" severity="info">
  类的初始化设计是如无必要，勿增实体。
</Alert>
    </>
  )