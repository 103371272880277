import React from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import {Title} from "../Overview";
import Rating from '@mui/material/Rating';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {NavReinforcementlearning} from "../Nav";
import {Asset} from "../Code"
import {NavPolicy} from "../Nav";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  export const Policy = () => (
    <div>
             <Title> Policy Function<Rating name="read-only" value={4} readOnly /></Title>
           <NavPolicy/>
             在会计强化学习中，策略函数是指一种算法，它根据当前系统状态和可用动作来确定下一步最佳动作。策略函数可以使用各种不同的算法实现，例如贝叶斯网络、决策树、神经网络等。在会计强化学习中，策略函数通常用于确定最佳账户分配策略或优化公司财务指标。
             <br/>
             在会计强化学习中，策略函数可能会考虑多种因素，如预算限制、税收优惠、风险因素等。策略函数可能会根据历史数据和当前状态来预测未来的财务绩效。
             <br/>
      
强化学习中, 策略函数的效果取决于问题的具体情境。有些策略函数在某些任务上表现较好, 而在其他任务上表现较差。常用的策略函数包括:
<ul><li>确定性策略(deterministic policy): 给定状态, 固定选择一个动作。</li><li>随机策略(stochastic policy): 给定状态, 以一定概率选择动作。</li><li>Q-learning 策略: 根据当前状态和 Q 函数来选择动作。</li><li>策略梯度算法(policy gradient algorithm): 通过梯度上升来更新策略函数。</li></ul>

在某些问题中, 确定性策略可能是最优的, 在其他问题中, 随机策略或策略梯度算法可能更好. Q-learning 策略常用于处理具有确定性环境的问题.

具体来说，在确定性环境中，确定性策略和 Q-learning 策略可能更适合。在高噪声环境中, 随机策略和策略梯度算法可能更适合。


策略函数可以使用强化学习的方法来训练，例如 Q-learning 或 SARSA。在训练过程中，策略函数会根据所采取的动作和其导致的结果来更新自己的策略。
在实际应用中，策略函数可以用于优化企业的财务策略，并帮助管理者做出更明智的决策。例如，策略函数可以帮助企业<strong>优化资产配置、优化税收策略、降低风险</strong>等。
<h3>Policy_net</h3>
在强化学习中，target_net 和 policy_net 通常是两个神经网络，分别用于不同的目的。
<ul><li>policy_net：主要用于根据当前状态（observation）预测动作（action），也就是决策网络。它通常是一个可训练的网络，
并不断通过强化学习算法更新其参数以提高决策的准确性。</li><li>target_net：主要用于计算评估动作的期望回报，是一个辅助网络。因为如果直接使用 policy_net 计算回报可能导致策略不
稳定，所以采用的是比较稳定的 target_net 来计算回报。
这样设置的目的是为了稳定策略的训练，以更快速收敛。</li></ul>
<h3>DeepQ</h3>
Deep Q-Learning (DQL) and Actor-Critic (A2C) are both popular reinforcement learning algorithms, but they are used for different types of tasks and have different strengths and weaknesses.

DQL is a type of Q-Learning algorithm that uses deep neural networks to approximate the Q-value function. It is well-suited for tasks where the state space is discrete and the action space is relatively small, such as playing Atari games.

A2C, on the other hand, is an actor-critic algorithm that uses a separate neural network for the actor (the policy) and the critic (the value function). It is well-suited for tasks where the state and action spaces are continuous, such as robotic control tasks.

In summary, DQL is good for discrete action space and small state space tasks, while A2C is good for continuous action space and large state space tasks. It depends on your problem which one is the better choice.
    </div>
  );
  export const Epsilon = () => (
    <div>
             <Title> Epsilon<Rating name="read-only" value={4} readOnly /></Title>
           <NavPolicy/>
           Epsilon-greedy policy是一种强制学习策略，它通过控制随机性和贪婪在探索与开发之间寻求平衡，以决策算法是否选择最优决策或进行探索。
<br />
<br />
具体地，策略中定义了一个参数ε，表示概率选择一个随机动作，而不是最优动作。在大多数情况下，算法会选择最优动作，但是在一定概率下，算法会随机选择一个动作，以探索新的最优策略。
<br /><br />
随着算法的进行，ε的值通常会逐渐降低，以表示算法越来越确信最优策略的选择。该策略常用于强制学习问题，例如博弈论问题，深度强化学习等。
ε的选择：通常情况下，ε的初始值设为一个较大的数，然后逐渐减小到较小的数，以达到探索与开发的平衡。最常见的选择是以指数级递减的方式减小ε，但也有其他选择，如以线性递减的方式减小ε。
折扣因子γ：ε-贪心策略与折扣因子γ一起使用，以评估每个动作的未来奖励。γ表示对未来奖励的权衡，值越大，对未来的考虑就越多，反之亦然。
其他策略：除了ε-贪心策略外，还有许多其他策略，如贝尔man策略，Boltzmann策略等，它们在决策时使用不同的概率分布函数。
应用：ε-贪心策略在许多领域中都有应用，如环境中的机器人控制，博弈论，多选择决策问题等。
    </div>
  );