import React from "react";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function ControlledAccordions() {
    const [expanded, setExpanded] = React.useState(false);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    return (
      <div className="mark">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              资产
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>描述</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
                资产的大数据细分
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>负债</Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              描述
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            大数据细分
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              所有者权益
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              描述
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
                细分
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>Personal data</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
                细分
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
}
  export const Biao = () =>{
    const rows: GridRowsProp = [
      { id: 1, col1: 'Hello', col2: 'World' },
      { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
      { id: 3, col1: 'MUI', col2: 'is Amazing' },
    ];
    const columns: GridColDef[] = [
      { field: 'col1', headerName: 'Account科目', width: 150 },
      { field: 'col2', headerName: '属性', width: 150 },
    ];
    return(
        <div style={{ height: 300, width: '100%' }}className="mark">
      <DataGrid rows={rows} columns={columns} />
      
      <ControlledAccordions />
    </div>
    )
}
export const ResearvedWord_Biao = () =>{
  const rows: GridRowsProp = [
    { id: 1, col1: 'Asset', col2: 'Account科目' ,col3:"资产"},
    { id: 2, col1: 'Liability', col2: 'Account科目' ,col3:"负债"},
    { id: 3, col1: 'Equity', col2: 'Account科目',col3:"所有者权益" },
    { id: 4, col1: 'Equity', col2: 'Account科目',col3:"所有者权益" },
    { id: 5, col1: 'Equity', col2: 'Account科目',col3:"所有者权益" },
    { id: 6, col1: 'Equity', col2: 'Account科目',col3:"所有者权益" },
    { id: 7, col1: 'Equity', col2: 'Account科目',col3:"所有者权益" },
    { id: 8, col1: 'Equity', col2: 'Action事件',col3:"所有者权益" },

  ];
  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Researved Word保留字', width: 180 },
    { field: 'col3', headerName: '中文', width: 150 },
    { field: 'col2', headerName: 'Description描述', width: 180 },
  ];
  return(
   

      <div style={{ height: 400, width: 520 }}className="mark">
    <DataGrid  sx={{
    boxShadow: 2,
    border: 2,
    borderColor: 'primary.light',
    '& .MuiDataGrid-cell:hover': {
      color: 'primary.main',
    },
  }} rowHeight={25} rows={rows} columns={columns}
      />
    
  </div>
  )
}
export const Metaphor_Biao = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="mark">
   <AccordionSummary
   >
     <Typography variant="h5" align="center" sx={{ width: '50%', flexShrink: 0 }}>
     Metaphor隐喻
     </Typography>
     <Typography variant="h5" align="center" sx={{  width: '50%' ,color: 'text.secondary' }}>Description描述</Typography>
   </AccordionSummary>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography align="center" sx={{ width: '50%', flexShrink: 0 }}>
          Balancing the books
          </Typography>
          <Typography align="center" sx={{  width: '50%' ,color: 'text.secondary' }}>算账</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          This phrase is used to refer to the process of making sure that the financial records of an organization are accurate and in agreement.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography align="center" sx={{ width: '50%', flexShrink: 0 }}>Cooking the books</Typography>
          <Typography align="center" sx={{  width: '50%' ,color: 'text.secondary' }}>
          做假账
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          This phrase is used to refer to the act of manipulating or falsifying financial records in order to present a more favorable financial position.          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography align="center" sx={{ width: '50%', flexShrink: 0 }}>
          Draining the cash cow
          </Typography>
          <Typography align="center" sx={{  width: '50%' ,color: 'text.secondary' }}>
            榨干摇钱树
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          This phrase is used to refer to a situation where an organization is taking too much money out of a profitable business or investment, thereby reducing its potential for future profits.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography align="center" sx={{ width: '50%', flexShrink: 0 }}>Cash is King</Typography>
         <Typography align="center" sx={{  width: '50%' ,color: 'text.secondary' }}>
           现金为王
         </Typography>
       </AccordionSummary>
       <AccordionDetails>
         <Typography>
         This phrase is used to refer to the importance of cash in business operations, and the importance of maintaining a positive cash balance in order to pay bills and keep the business running.
         </Typography>
       </AccordionDetails>
     </Accordion>

     <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
       <AccordionSummary
         expandIcon={<ExpandMoreIcon />}
         aria-controls="panel4bh-content"
         id="panel4bh-header"
       >
         <Typography align="center" sx={{ width: '50%', flexShrink: 0 }}>Fiscal cliff</Typography>
        <Typography align="center" sx={{  width: '50%' ,color: 'text.secondary' }}>
          财政悬崖
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        This phrase is used to refer to the potential consequences of a government reaching its borrowing limit and not being able to pay its bills, which is often associated with a huge fall in the economy and the stock market.
        </Typography>
      </AccordionDetails>
    </Accordion>
    </div>
  );
}