import React from "react";
import  { useEffect } from "react";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import {home_code,home_code1,home_code2,home_code3} from "./Code"
import { styled } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { SignupButton } from "../components/auth/LoginButton";
import axios from 'axios'
import { Menu, MenuItem} from '@mui/material';
import { PineconeClient } from "@pinecone-database/pinecone";
import { ChatOpenAI } from "langchain/chat_models/openai";
import { Document } from "langchain/document";
import { OpenAIEmbeddings } from "langchain/embeddings/openai";
import { PineconeStore } from "langchain/vectorstores/pinecone";
import { VectorDBQAChain } from "langchain/chains";
import {
  ChatPromptTemplate,
  HumanMessagePromptTemplate,
  PromptTemplate,
  SystemMessagePromptTemplate,
} from "langchain/prompts";

import { Helmet } from "react-helmet"


const App = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    axios.get('http://127.0.0.1:5000/api/data')
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error(`Error: ${error}`));
  }, []);

  return (
    <div className="App">
      {data && (
        <div>
          <h2>{data.name}</h2>
          <p>{data.description}</p>
        </div>
      )}
    </div>
  );
}


const MyComponent1 = () => {
  const [product, setProduct] = useState("");
  const [responseAString, setResponseAString] = useState("");

  const fetchResult = async () => {
    const template = "What is a good name for a company that makes {product}?";
    const promptA = new PromptTemplate({ template, inputVariables: ["product"] });

    const responseA = await promptA.formatPromptValue({
      product,
    });
    const responseAString = responseA.toString();

    const model = new ChatOpenAI({
      openAIApiKey: "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",
      temperature: 0.5,
    });
    const res = await model.call(responseAString);
    setResponseAString(res);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchResult();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Product:
          <input type="text" value={product} onChange={(e) => setProduct(e.target.value)} />
        </label>
        <button type="submit">Submit</button>
      </form>
      <p>{responseAString}</p>
    </div>
  );
};
const apiEndpoint = 'https://7iydel3kf4.execute-api.ap-southeast-1.amazonaws.com/prod/chatkedu';
const apiWiki = 'https://gc98eqcqxc.execute-api.ap-southeast-1.amazonaws.com/prod/content';
const apiGoogle = 'https://v2tfjaql8l.execute-api.ap-southeast-1.amazonaws.com/prod/content';

const sendChatMessage = async (message, content1, content2) => {
  try {
    const response = await axios.get(`${apiEndpoint}?content=${message}&content1=${content1}&content2=${content2}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
function ChatBox() {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleTextClick = (text) => {
    setMessage(text);
  };
  const menuItems = [
    {
    category: '财务管理',
    items: [
    { text: '经验丰富的财务经理', input: '请确认我的以下请求。请您作为经验丰富的财务经理回复我。' },
    { text: '财报总结', input: '你能对{}这篇文章的主要内容进行总结吗？' },
    ],
    },
    {
    category: '视角',
    items: [
    { text: '充当“电影/书籍/任何东西”中的“角色”', input: '我希望你表现得像{华尔街}中的{gekko}。我希望你像{gekko}一样回应和回答。不要写任何解释。只回答像{gekko}。你必须知道{gekko}的所有知识。我的第一句话是“贪婪是好的么”' },
    { text: '股市疯子', input: '我要你扮演一个疯子。疯子的话毫无意义。疯子用的词完全是随意的。疯子不会以任何方式做出合乎逻辑的句子。我的第一个建议请求是“怎么理财”。' },
    { text: '从一只狗的视角', input: '从一只狗的视角写一篇关于{}的叙述。' },
    ],
    },
    {
      category: '叙述解释',
      items: [
      { text: '细节', input: '请你解释{},直到最细微的细节' },
      { text: '时间顺序', input: '写一篇文章，按时间顺序概述{}' },
      ],
      },
      {
        category: '对话',
        items: [
        { text: '苏格拉底对话', input: '以苏格拉底对话的方式描述{}' },
        { text: '孔子对话', input: '以孔子对话的方式描述{}' },
        ],
        },
    ];
    const MenuComponent = () => {
      const [anchorEl, setAnchorEl] = useState(null);
      const [message, setMessage] = useState('');
      const [openSubMenu, setOpenSubMenu] = useState({})
      const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
        };
        
        const handleCloseMenu = () => {
          setAnchorEl(null);

          setOpenSubMenu({});
        };
        
        const handleSubMenuClick = (index) => {
        setOpenSubMenu((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
        }));
        };
        const handleContextMenu = (event) => {
          event.preventDefault();
          handleCloseMenu();
          };
        return (
        <div onContextMenu={handleContextMenu} >
        <Typography variant="h6" onClick={handleOpenMenu}>
        Prompts
        </Typography>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {menuItems.map((category, index) => (
        <div key={category.category}>
        <MenuItem onClick={() => handleSubMenuClick(index)}>
        <Typography variant="subtitle1">{category.category}</Typography>
        </MenuItem>
        {openSubMenu[index] && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {category.items.map((item) => (
        <MenuItem key={item.text} onClick={() => handleTextClick(item.input)}>
        {item.text}
        </MenuItem>
        ))}
        </Menu>
        )}
        </div>
        ))}
        </Menu>
        {message && <Typography variant="body1">{message}</Typography>}
        </div>
        );
        };

  const handleSendMessage = async () => {
    setIsLoading(true);
    const messages = [...chatHistory, { role: 'user', content: message }];
    const apiResponse = await sendChatMessage(message, chatHistory[chatHistory.length-2]?.content, chatHistory[chatHistory.length-1]?.content);
    setChatHistory([...messages, { role: 'ai', content: apiResponse }]);
    setResponse(apiResponse);
    setMessage('');
    setIsLoading(false);
  };

  return (
    <div style={{ backgroundColor: 'black', color: 'white' }}>
        <h2         style={{ backgroundColor: 'black', color: 'white' }}
>GPT3.5</h2>
    {chatHistory.map((chat, index) => (
      <div key={index} className={chat.role}>
        {chat.content}
        {chat.role == 'ai' && index !== chatHistory.length - 1 && <hr />}
      </div>
    ))}
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        rows={6}
        placeholder="Please enter"
        style={{ backgroundColor: 'black', color: 'white' }}

      />
            <MenuComponent />

      <Button disabled={isLoading} onClick={handleSendMessage}
                aria-haspopup="true"
                style={{ backgroundColor: 'darkblue', color: 'white' ,marginBottom:"10px",margin:"10px",
                fontSize: '15px', 
                }}
                >
        {isLoading ? 'Is Processing...' : 'Send'}
      </Button>
    </div>
  );
}

function WikiBox() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [api, setApi] = useState(apiWiki);
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleApiChange = (newApi) => {
    setApi(newApi);
  };
  const sendWiki = async (message) => {
    try {
      const response = await axios.get(`${api}?content=${message}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const handleSendMessage = async () => {
    setIsLoading(true);
    const messages = [...chatHistory, { role: 'user', content: message }];
    const apiResponse = await sendWiki(message);
    setChatHistory([...messages, { role: 'ai', content: apiResponse }]);
    setResponse(apiResponse);
    setMessage('');
    setIsLoading(false);
  };

  return (
    <div style={{ backgroundColor: 'black', color: 'white' }}>
              <h2  style={{ margin:"10px"}}>{api == apiWiki ? 'Wiki' : 'Google'}</h2>
        <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ backgroundColor: 'darkblue', color: 'white' ,margin:"10px"}}
      >
         Plugins
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          handleClose();
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => {
    handleApiChange(apiWiki);
    handleClose();
  }}>Wiki</MenuItem>
  <MenuItem onClick={() => {
    handleApiChange(apiGoogle);
    handleClose();
  }}>Google</MenuItem>
      </Menu>
    {chatHistory.map((chat, index) => (
      <div key={index} className={chat.role}>
        {chat.content}
        {chat.role == 'ai' && index !== chatHistory.length - 1 }
      </div>
    ))}
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        rows={6}
        placeholder="Please enter"
        style={{ backgroundColor: 'black', color: 'white' }}
      />

      <Button  id="basic-button" disabled={isLoading} onClick={handleSendMessage}
              aria-haspopup="true"
        style={{ backgroundColor: 'darkblue', color: 'white' ,marginBottom:"10px",margin:"10px",
        fontSize: '15px', 
      }}
        >
        {isLoading ? 'Sending' : 'Send'}
      </Button>
     
    </div>
  );
}

function LoginButton() {
  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  return !isAuthenticated && (
    <button onClick={loginWithRedirect}>Log in</button>
  );
}

const ResponsiveCard = styled(Card)({
  width: '90vw',
  maxWidth: '1500px',
  maxHeight: 'none',
  margin: '20px',
  padding: "20px"
});

const steps = [
  {
    label: 'sales increased 12% compared with fourth quarter 2021:North America segment sales increased 13% year-over-year to $93.4 billion, or increased 14% excluding changes in foreign exchange rates.International segment sales decreased 8% year-over-year to $34.5 billion, or increased 5% excluding changes in foreign exchange rates.AWS segment sales increased 20% year-over-year to $21.4 billion.',
    description:'AMAZON.COM ANNOUNCES FOURTH QUARTER RESULTS',
    img:home_code
  },
  {
    label: '公司拟使用自有资金通过集中竞价交易方式回购公司股份用于实施员工持股计划或股权激励计划，本次回购股份的资金总额不低于 6,000 万元(含)且不超过 8,000 万元(含)，回购价格不超过 17.00 元/股，回购期限为自公司董事会审议通过本次回购股份方案之日起 12 个月内。',
    description:"Buy back 回购",
    img:home_code1

  },
  {
    label: '公司股东南京招银拟通过集中竞价、大 宗交易方式减持其所持有的公司股份合计不超过 7,158,555 股，不超过寒武纪总股份的 1.79%。公司股东湖北招银计划拟通过集中竞价、大宗交易方式合计减持 寒武纪股份不超过 3,561,613 股，不超过寒武纪总股份的 0.89%。',
    description:'ShareholderReduction 减持',
    img:home_code2

  },
  {
    label: "中航电测仪器股份有限公司发行股份购买资产暨关联交易预案",
    description:"Assetbuy 发行股份购买资产",
    img:home_code3

  },
];
const Home1 = () => {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const [showInstructions, setShowInstructions] = useState(false);

  const handleInstructionsClick = () => {
    setShowInstructions(true);
  };
  const handleOpen = () => {
    setShowInstructions(true);
  };

  const handleClose = () => {
    setShowInstructions(false);
  };

  return (
    <>      
    <Box sx={{  height:40,flexGrow: 1 }}>
    <Collapse in={open}>
    <Alert icon={false} onClose={() => { setOpen(false);}} variant="outlined"severity="info">
    <Link href="#" underline="hover" onClick={handleOpen}>
    Welcome to cpanlp!          
      </Link>  </Alert> 
      <Dialog open={showInstructions} onClose={handleClose}>
        <DialogTitle>Welcome to cpanlp!</DialogTitle>
        <DialogContent>
          <DialogContentText>
          To get started, follow these simple steps to register and use the platform:
<ul><li>
Complete your user profile: Once you've registered<SignupButton/>, the first step is to complete your profile. This will help other users learn more about you and your expertise. 
<strong>You can access your <a href="/profile">Profile</a> by clicking on your username in the top-right corner of the screen.</strong>
</li><li>
Upload your Accounting Python code: Once you've completed your profile, you can upload your Python code to the cpanlp repositories. This will enable you to share your work with other users and collaborate with them. 
<strong>To upload your code, go to the  <a href="/repositories">Repositories</a> page and click on "Upload File". Please note that the maximum file size for each Python file is 5MB.</strong>
</li><li>
Share and collaborate: Once your code is uploaded, other users can view and download it, and you can collaborate with them on projects. </li>
Happy accounting with python!
</ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Collapse >

    </Box>
    
    </>

  );
}
const Home = () => (
  <div>
  <div className="居中">
<Home1 />
  </div>
  <div>
      <span className="big-bold-text">Audit made easy in just one prompt</span>
    </div>
  <WikiBox />
    <Helmet>
    <script src="https://chatthing.ai/chat-widget.js" type="text/javascript" 
id="d8482f37-a619-4922-9d4b-dc45ae0bd20a" async defer></script>
  </Helmet>
  <ChatBox />
  
  <App />

  </div>
);
export default Home;




