import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {NavDocument,NavDocument1} from "./Nav";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import {Title} from "./Overview"
import Rating from '@mui/material/Rating';
import {Asset,asset_code,liability_code} from "./Code"

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
export const NavNlp = () =>{
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>
      <Tabs
       variant="scrollable"
       scrollButtons
       allowScrollButtonsMobile
       value={value}
       onChange={handleChange}
       aria-label="Vertical tabs example"
      >
        <Tab label={
      <>
        <Typography variant="caption">
          get Information
        </Typography>
        <Typography variant="caption">
          获取信息
        </Typography>
      </>
   } {...a11yProps(0)} />
        <Tab label=
        {
          <>
            <Typography variant="caption">
            tokenization
            </Typography>
            <Typography variant="caption">
            分词
                        </Typography>
          </>
       } {...a11yProps(1)} />
        <Tab label=
         {
          <>
            <Typography variant="caption">
            sentiment analysis
            </Typography>
            <Typography variant="caption">
            情感分析
            </Typography>
          </>
       }
      {...a11yProps(2)} />
        <Tab label=    
        {
     <>
       <Typography variant="caption">
       similarity
       </Typography>
       <Typography variant="caption">
       相似性
       </Typography>
     </>
  }
   {...a11yProps(3)} />
        <Tab label="计算" {...a11yProps(4)} />
        <Tab label="3d绘图" {...a11yProps(5)} />
        <Tab label="对话" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <p >
        定义资产类及其方法，在 Python 中，可以使用<b>继承</b>来扩展现有的类。下面是一个简单的例子，在资产类的基础上增加无形资产的类：可以根据实际需求来继续扩展这个类。无形资产炒作指的是对无形资产的非理性高估，通常是由于市场上的投资者对某种无形资产的需求过大导致的。

在 Python 中，你可以使用计算模型和模拟来描述无形资产炒作。例如，可以使用随机游走模型来模拟无形资产价值的波动，并使用数据分析和可视化来表示炒作现象。

这里是一个简单的例子，使用 Python 的 random 模块来模拟无形资产价值的波动，并使用 matplotlib 库来可视化无形资产炒作现象:使用更复杂的模型来模拟资产价格的变化。例如，使用随机游走模型或者 GBM 模型替换简单的随机浮动模型。

添加额外的参数，以模拟不同的市场条件。例如，可以添加一个参数来模拟利率水平或者经济增长率，这些因素可能会影响资产价格的变化。

添加事件处理，以模拟重要事件对资产价格的影响。例如，可以在模拟过程中设置一些关键日期，并在这些日期触发特殊事件，以模拟公司重大公告或者经济危机对资产价格的影响。

使用统计学方法来评估模型的效果。例如，可以使用检验模型的拟合程度、计算模型的波动率、或者评估模型的风险等方法来评估模型的效果。

使用可视化工具来展示模拟结果，如使用 Plotly,Seaborn, Bokeh等库来绘制出更加美观、互动性强的图表.添加一个方法来监测商誉在社交媒体上的口碑。例如，可以使用社交媒体分析工具来收集关于公司和品牌的评论，并统计正面和负面评论的比例。首先我们需要有一个基础的 BusinessEntity 类来定义一些通用的属性和方法。下面是一个简单的 BusinessEntity 类的示例:
        </p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}
export const NavCulture = () =>{
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>
      <Tabs
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      >
        <Tab label=
               {
                <>
                  <Typography variant="caption">
                  Entrepreneurship
                  </Typography>
                  <Typography variant="caption">
                  企业家精神
                  </Typography>
                </>
             }
       {...a11yProps(0)} />
        <Tab label="tokenization分词" {...a11yProps(1)} />
        <Tab label="情感分析" {...a11yProps(2)} />
        <Tab label="similarity相似性" {...a11yProps(3)} />
        <Tab label="计算" {...a11yProps(4)} />
        <Tab label="3d绘图" {...a11yProps(5)} />
        <Tab label="对话" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <p >
      企业家精神是指一种具有进取精神、勇于冒险、敢于创新、不断追求卓越的精神状态。企业家精神的具体表现包括对市场机会的敏锐洞察力、对风险的勇敢应对、对失败的坚韧不拔、对成功的不断追求等。这种精神可以帮助企业家在竞争激烈的市场中取得成功，并为社会创造更多的价值。
      <Alert variant="outlined"severity="warning"className='mark'>
      很难判断企业家精神的Python函数，因为企业家精神是一种抽象的概念，并不能用简单的代码来衡量。企业家精神是一种复杂的社会心理现象，其表现形式多样，不能简单地用代码来捕捉。如果你想更好的了解企业家精神，我建议你阅读一些相关的书籍和资料。
      </Alert>
        </p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}
export const NavPerson = () =>{
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>

      <Tabs
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      >
        <Tab label=
               {
                <>
                  <Typography variant="caption">
                  investor
                  </Typography>
                  <Typography variant="caption">
                  投资者
                  </Typography>
                </>
             }
          {...a11yProps(0)} />
        <Tab label=
               {
                <>
                  <Typography variant="caption">
                  shareholder
                  </Typography>
                  <Typography variant="caption">
                  股东
                  </Typography>
                </>
             }
       {...a11yProps(1)} />
        <Tab label=  
        {
    <>
      <Typography variant="caption">
      employee
      </Typography>
      <Typography variant="caption">
      员工
      </Typography>
    </>
 }
  {...a11yProps(2)} />
        <Tab label=
                {
                  <>
                    <Typography variant="caption">
                    partner
                    </Typography>
                    <Typography variant="caption">
                    合伙人
                    </Typography>
                  </>
               }
        {...a11yProps(3)} />
        <Tab label=
             {
              <>
                <Typography variant="caption">
                entrepreneur
                </Typography>
                <Typography variant="caption">
                企业家
                </Typography>
              </>
           }
         {...a11yProps(4)} />
        <Tab label=
            {
              <>
                <Typography variant="caption">
                management
                </Typography>
                <Typography variant="caption">
                管理层
                </Typography>
              </>
           }
        {...a11yProps(5)} />
        <Tab label=
               {
                <>
                  <Typography variant="caption">
                  auditor
                  </Typography>
                  <Typography variant="caption">
                  审计人员
                  </Typography>
                </>
             }
        {...a11yProps(6)} />
        <Tab label=
                {
                  <>
                    <Typography variant="caption">
                    consumer
                    </Typography>
                    <Typography variant="caption">
                    消费者
                    </Typography>
                  </>
               }
       {...a11yProps(7)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <p >
      Investor和Shareholder是两个不同的术语，在金融领域有着不同的含义。

Investor是一个更广义的术语，指的是对某种资产进行投资的人。投资者可以是个人、企业或机构，他们可能会在股票、债券、房地产、商业地产等多种资产中进行投资。

Shareholder指的是持有公司股票的人。他们是公司的股东，拥有公司的部分所有权，并享有权益和投票权。 Shareholder 只指公司股东，而Investor 指的是对资产进行投资的人，不仅仅限于公司股东，还可能包括购买债券或房地产等其他资产的人。

总而言之，所有的Shareholder都是Investor，但并不是所有的Investor都是Shareholder。
        </p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
     Item Three
   </TabPanel>
   <TabPanel value={value} index={4}>
   企业家和管理层是公司运营和管理的两个不同的角色。

企业家是公司的创始人或拥有者，负责公司的战略决策和长远发展。他们需要具备创新和冒险精神，并能够承担风险。企业家通常是公司的股东，并且可能担任高级管理岗位。

管理层是公司的日常管理者，负责公司的运营和经营。他们需要具备领导力和组织能力，并能够有效地管理员工和资源。管理层包括高级经理、总经理、首席执行官等职位。

总而言之，企业家负责公司的长远发展和战略决策，而管理层负责公司的日常运营和经营。两者之间的关系可能是独立的，也可能是相互联系的。   </TabPanel>
   <TabPanel value={value} index={5}>
   <Alert variant="outlined"severity="warning"className='mark'>
   在用类和函数建模管理层行为时，以任何方式编写取笑或批评管理的功能都是不合适的。这样的功能是不专业的，并可能导致工作场所的负面后果。相反，重要的是对管理层和同事保持积极和尊重的态度。
   </Alert>
   </TabPanel>
   <TabPanel value={value} index={6}>
     Item Three
   </TabPanel>
   <TabPanel value={value} index={7}>
    
    <p className="philosophy-text">
      所有个体的基类都是消费者。这是一个简单的类，它实现了一个常见的消费者效用函数，称为带参数希尔伯特-拉姆齐效用函数（CRRA）。该类有一个构造函数，接受一个参数gamma作为输入，并定义了一个utility()方法，接受消费量c作为输入并返回效用值。当gamma=1时，效用函数为对数函数，当gamma≠1时，为CRRA函数。在经济学中，CRRA效用函数的风险厌恶系数通常被限制在 (0,∞) 之间。当风险厌恶系数大于1时，CRRA效用函数可能为负值，这是不符合经济学基本假设的。

在这种情况下，可能需要使用其他类型的效用函数来更好地描述消费者/投资者的行为。例如，可以使用 CARA (Constant Absolute Risk Aversion) 效用函数，它更适合描述风险厌恶系数大于1的消费者/投资者。

也可以使用其他类型的效用函数，如 CES (Constant Elasticity of Substitution) 函数, 等等。这些函数具有不同的效用函数形式和对风险的偏好不同，需要根据具体问题来选择。
    <b> A utility function mapping outcomes into utilities.</b>
  <span className='文中强调'>The Constant Relative Risk Aversion (CRRA) utility function </span> is a popular choice in economic modeling because it has several desirable properties. Some benefits of using the CRRA utility function include:</p>
<ul><li>
Mathematical tractability: The CRRA utility function has a simple mathematical form, which makes it easy to work with and analyze.
</li><li>
Risk aversion: The CRRA utility function captures the idea of risk aversion, which is an important concept in economics. The coefficient of relative risk aversion (CRRA) can be used to measure the degree of risk aversion of an individual or a group of people.
</li><li>
Flexibility: The CRRA utility function can be used to model a wide range of preferences and behaviors, including those of consumers, firms, and governments.
</li><li>
Normality: It's easy to prove the CRRA utility function is concave, and thus it's easy to prove that there is an optimal solution to a problem.
</li><li>
Robustness: The CRRA utility function is relatively robust to assumptions about the underlying probability distributions, which makes it a useful tool for modeling uncertain situations.
</li><li>
Consistency with empirical data: The CRRA utility function is consistent with many empirical observations, including the observation that people's marginal utility of income decreases as their income increases.
</li>
</ul>
However, it's worth noting that the CRRA utility function assumes that the relative risk aversion is constant, which may not always be the case in reality.
   </TabPanel>
    </Box>
  );
}
       <NavPerson /> 
export const NavMarket = () =>{
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>

      <Tabs
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      >
        <Tab label=
               {
                <>
                  <Typography variant="caption">
                  perfect competitive
                  </Typography>
                  <Typography variant="caption">
                  完全竞争
                  </Typography>
                </>
             }
       {...a11yProps(0)} />
        <Tab label=
             {
              <>
                <Typography variant="caption">
                monopoly
                                </Typography>
                <Typography variant="caption">
                垄断
                </Typography>
              </>
           }
        {...a11yProps(1)} />
        <Tab label=
           {
            <>
              <Typography variant="caption">
              Oligopoly
                              </Typography>
              <Typography variant="caption">
              寡头垄断
              </Typography>
            </>
         }
        {...a11yProps(2)} />
        <Tab label=
            {
              <>
                <Typography variant="caption">
                Monopolistic Competition
                                </Typography>
                <Typography variant="caption">
                垄断竞争
                </Typography>
              </>
           }
        {...a11yProps(3)} />
        <Tab label=
         {
          <>
            <Typography variant="caption">
            commodity
           </Typography>
            <Typography variant="caption">
            商品
            </Typography>
          </>
       }
         {...a11yProps(4)} />
        <Tab label=
             {
              <>
                <Typography variant="caption">
                stock
               </Typography>
                <Typography variant="caption">
                股票
                </Typography>
              </>
           }
        {...a11yProps(5)} />
        <Tab label=
             {
              <>
                <Typography variant="caption">
                bond
               </Typography>
                <Typography variant="caption">
                债券
                </Typography>
              </>
           }
      {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <p >
      企业家精神是指一种具有进取精神、勇于冒险、敢于创新、不断追求卓越的精神状态。企业家精神的具体表现包括对市场机会的敏锐洞察力、对风险的勇敢应对、对失败的坚韧不拔、对成功的不断追求等。这种精神可以帮助企业家在竞争激烈的市场中取得成功，并为社会创造更多的价值。
      <Alert variant="outlined"severity="warning"className='mark'>
      很难判断企业家精神的Python函数，因为企业家精神是一种抽象的概念，并不能用简单的代码来衡量。企业家精神是一种复杂的社会心理现象，其表现形式多样，不能简单地用代码来捕捉。如果你想更好的了解企业家精神，我建议你阅读一些相关的书籍和资料。
      </Alert>
        </p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}
export const NavInstitution = () =>{
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>

      <Tabs
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      >
        <Tab label=
               {
                <>
                  <Typography variant="caption">
                  policy
                 </Typography>
                  <Typography variant="caption">
                  政策
                  </Typography>
                </>
             }
         {...a11yProps(0)} />
        <Tab label="垄断市场" {...a11yProps(1)} />
        <Tab label="寡头垄断市场" {...a11yProps(2)} />
        <Tab label="垄断竞争市场" {...a11yProps(3)} />
        <Tab label="商品市场" {...a11yProps(4)} />
        <Tab label="股票市场" {...a11yProps(5)} />
        <Tab label="债券市场" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <p >
      企业家精神是指一种具有进取精神、勇于冒险、敢于创新、不断追求卓越的精神状态。企业家精神的具体表现包括对市场机会的敏锐洞察力、对风险的勇敢应对、对失败的坚韧不拔、对成功的不断追求等。这种精神可以帮助企业家在竞争激烈的市场中取得成功，并为社会创造更多的价值。
      <Alert variant="outlined"severity="warning"className='mark'>
      很难判断企业家精神的Python函数，因为企业家精神是一种抽象的概念，并不能用简单的代码来衡量。企业家精神是一种复杂的社会心理现象，其表现形式多样，不能简单地用代码来捕捉。如果你想更好的了解企业家精神，我建议你阅读一些相关的书籍和资料。
      </Alert>
        </p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}
export const NavStrategy = () =>{
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>

      <Tabs
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      >
        <Tab label=
               {
                <>
                  <Typography variant="caption">
                  毒丸计划
                 </Typography>
                  <Typography variant="caption">
                  poison pill
                  </Typography>
                </>
             }
         {...a11yProps(0)} />
        <Tab label="垄断市场" {...a11yProps(1)} />
        <Tab label="寡头垄断市场" {...a11yProps(2)} />
        <Tab label="垄断竞争市场" {...a11yProps(3)} />
        <Tab label="商品市场" {...a11yProps(4)} />
        <Tab label="股票市场" {...a11yProps(5)} />
        <Tab label="债券市场" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <p >
      <Alert variant="outlined"severity="info"className='mark'>
      A "poison pill" is a corporate defense strategy in which a company issues a large number of new shares, diluting the ownership of current shareholders in order to make a hostile takeover less attractive. Using a Python decorator to simulate a poison pill function could be a way to automate the process of implementing this strategy in a programmatic way. However, it is important to note that implementing a poison pill can also have negative consequences for a company, such as a decrease in stock price and a lowering of credit rating.it is a defensive measure used by companies to make a hostile takeover less attractive by diluting the ownership of current shareholders.
      </Alert>
        </p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}
export const NavCalculate = () =>{
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>

      <Tabs
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      >
        <Tab label="政策" {...a11yProps(0)} />
        <Tab label="垄断市场" {...a11yProps(1)} />
        <Tab label="寡头垄断市场" {...a11yProps(2)} />
        <Tab label="垄断竞争市场" {...a11yProps(3)} />
        <Tab label="商品市场" {...a11yProps(4)} />
        <Tab label="股票市场" {...a11yProps(5)} />
        <Tab label="债券市场" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <p >
      企业家精神是指一种具有进取精神、勇于冒险、敢于创新、不断追求卓越的精神状态。企业家精
包括对市场机会的敏锐洞察力、对风险的勇敢应对、对失败的坚韧不拔、对成功的不断追求等。
帮助企业家在竞争激烈的市场中取得成功，并为社会创造更多的价值。
      <Alert variant="outlined"severity="warning"className='mark'>
      很难判断企业家精神的Python函数，因为企业家精神是一种抽象的概念，并不能用简单的代码来
家精神是一种复杂的社会心理现象，其表现形式多样，不能简单地用代码来捕捉。如果你想更好
精神，我建议你阅读一些相关的书籍和资料。
      </Alert>
        </p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}
export const NavAbnormal = () =>{
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>

      <Tabs
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      >
        <Tab label=
                {
                  <>
                    <Typography variant="caption">
                    winner's curse
                   </Typography>
                    <Typography variant="caption">
                    赢者诅咒
                    </Typography>
                  </>
               }
        {...a11yProps(0)} />
        <Tab label="垄断市场" {...a11yProps(1)} />
        <Tab label="寡头垄断市场" {...a11yProps(2)} />
        <Tab label="垄断竞争市场" {...a11yProps(3)} />
        <Tab label="商品市场" {...a11yProps(4)} />
        <Tab label="股票市场" {...a11yProps(5)} />
        <Tab label="债券市场" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <p >
      企业家精神是指一种具有进取精神、勇于冒险、敢于创新、不断追求卓越的精神状态。企业家精
包括对市场机会的敏锐洞察力、对风险的勇敢应对、对失败的坚韧不拔、对成功的不断追求等。
帮助企业家在竞争激烈的市场中取得成功，并为社会创造更多的价值。
      <Alert variant="outlined"severity="warning"className='mark'>
      很难判断企业家精神的Python函数，因为企业家精神是一种抽象的概念，并不能用简单的代码来
家精神是一种复杂的社会心理现象，其表现形式多样，不能简单地用代码来捕捉。如果你想更好
精神，我建议你阅读一些相关的书籍和资料。
      </Alert>
        </p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}
export const NavInformation= () =>{
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>

      <Tabs
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
        
      >
        <Tab label=
             {
              <>
                <Typography variant="caption">
                Information asymmetry
               </Typography>
                <Typography variant="caption">
                信息不对称
                </Typography>
              </>
           }
         {...a11yProps(0)} />
        <Tab label=
           {
            <>
              <Typography variant="caption">
              control
             </Typography>
              <Typography variant="caption">
              控制权
              </Typography>
            </>
         }
      {...a11yProps(1)} />
        <Tab label=
          {
            <>
              <Typography variant="caption">
              residual claim
             </Typography>
              <Typography variant="caption">
              剩余索取权
              </Typography>
            </>
         }
         {...a11yProps(2)} />
        <Tab label=
              {
                <>
                  <Typography variant="caption">
                  contract
                 </Typography>
                  <Typography variant="caption">
                  合约
                  </Typography>
                </>
             }
         {...a11yProps(3)} />
        <Tab label="calculate计算" {...a11yProps(4)} />
        <Tab label="绘图" {...a11yProps(5)} />
        <Tab label="5" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <p >
        定义资产类及其方法，在 Python 中，可以使用继承来扩展现有的类。下面是一个简单的例子，在资产类的基础上增加无形资产的类：可以根据实际需求来继续扩展这个类。无形资产炒作指的是对无形资产的非理性高估，通常是由于市场上的投资者对某种无形资产的需求过大导致的。

在 Python 中，你可以使用计算模型和模拟来描述无形资产炒作。例如，可以使用随机游走模型来模拟无形资产价值的波动，并使用数据分析和可视化来表示炒作现象。

这里是一个简单的例子，使用 Python 的 random 模块来模拟无形资产价值的波动，并使用 matplotlib 库来可视化无形资产炒作现象:使用更复杂的模型来模拟资产价格的变化。例如，使用随机游走模型或者 GBM 模型替换简单的随机浮动模型。

添加额外的参数，以模拟不同的市场条件。例如，可以添加一个参数来模拟利率水平或者经济增长率，这些因素可能会影响资产价格的变化。

添加事件处理，以模拟重要事件对资产价格的影响。例如，可以在模拟过程中设置一些关键日期，并在这些日期触发特殊事件，以模拟公司重大公告或者经济危机对资产价格的影响。

使用统计学方法来评估模型的效果。例如，可以使用检验模型的拟合程度、计算模型的波动率、或者评估模型的风险等方法来评估模型的效果。

使用可视化工具来展示模拟结果，如使用 Plotly,Seaborn, Bokeh等库来绘制出更加美观、互动性强的图表.添加一个方法来监测商誉在社交媒体上的口碑。例如，可以使用社交媒体分析工具来收集关于公司和品牌的评论，并统计正面和负面评论的比例。首先我们需要有一个基础的 BusinessEntity 类来定义一些通用的属性和方法。下面是一个简单的 BusinessEntity 类的示例:
        </p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}
export const NavEvent = () =>{
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(true);

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}
    >
      <Tabs
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        
      >
        <Tab label=
               {
                <>
                  <Typography variant="caption">
                  Acquisition
                 </Typography>
                  <Typography variant="caption">
                  收购
                  </Typography>
                </>
             }
         {...a11yProps(0)} />
        <Tab label=
        {
          <>
            <Typography variant="caption">
            ipo
           </Typography>
            <Typography variant="caption">
            首次公开上市
            </Typography>
          </>
       }
       {...a11yProps(1)} />
        <Tab label=
            {
              <>
                <Typography variant="caption">
                product launch
               </Typography>
                <Typography variant="caption">
                新产品推出
                </Typography>
              </>
           }
      {...a11yProps(2)} />
        <Tab label=
              {
                <>
                  <Typography variant="caption">
                  contract signing
                 </Typography>
                  <Typography variant="caption">
                  合同签约
                  </Typography>
                </>
             }
         {...a11yProps(3)} />
        <Tab label=
                {
                  <>
                    <Typography variant="caption">
                    employee turnover
                   </Typography>
                    <Typography variant="caption">
                    员工离职
                    </Typography>
                  </>
               }
         {...a11yProps(4)} />
        <Tab label=
              {
                <>
                  <Typography variant="caption">
                  invest
                 </Typography>
                  <Typography variant="caption">
                  投资
                  </Typography>
                </>
             }
         {...a11yProps(5)} />
        <Tab label=
              {
                <>
                  <Typography variant="caption">
                  government approval
                 </Typography>
                  <Typography variant="caption">
                  政府审批
                  </Typography>
                </>
             }
           {...a11yProps(6)} />
        <Tab label=
            {
              <>
                <Typography variant="caption">
                Intellectual Property Disputes
               </Typography>
                <Typography variant="caption">
                知识产权纠纷
                </Typography>
              </>
           }
         {...a11yProps(7)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <p >
      <Collapse in={open}>
      <Alert onClose={() => { setOpen(false);}} variant="outlined"severity="warning"className='mark'>
     "buyout" and "acquisition" are related but have slightly different meanings.

A "buyout" typically refers to a specific type of acquisition in which a controlling stake in a company is purchased, usually with the goal of taking the company private. In a buyout, an investor or group of investors typically acquires a controlling interest in a company by purchasing a majority of its shares, or by purchasing all of its shares. A buyout can be either friendly or hostile, depending on whether the target company's management supports the deal or not.

An "acquisition" is a general term that refers to the process of one company buying or merging with another company. It's a broader term that includes buyouts, but it can also refer to other types of deals such as mergers, strategic partnerships, and joint ventures.

In summary, all buyouts are acquisitions, but not all acquisitions are buyouts.
      </Alert> 
      </Collapse >

        </p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}
export const NavEntity = () =>{
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>

      <Tabs
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      >
        <Tab label=
             {
              <>
                <Typography variant="caption">
                limited liability company
               </Typography>
                <Typography variant="caption">
                有限责任公司
                </Typography>
              </>
           }
         {...a11yProps(0)} />
        <Tab label=
            {
              <>
                <Typography variant="caption">
                partnership
               </Typography>
                <Typography variant="caption">
                合伙企业
                </Typography>
              </>
           }
        {...a11yProps(1)} />
        <Tab label=
         {
          <>
            <Typography variant="caption">
            State-owned enterprises
           </Typography>
            <Typography variant="caption">
            国有企业
            </Typography>
          </>
       }
        {...a11yProps(2)} />
        <Tab label=
          {
            <>
              <Typography variant="caption">
              supplier
             </Typography>
              <Typography variant="caption">
              供应商
              </Typography>
            </>
         }
         {...a11yProps(3)} />
        <Tab label=
         {
          <>
            <Typography variant="caption">
            public
           </Typography>
            <Typography variant="caption">
            社会公众
            </Typography>
          </>
       }
      {...a11yProps(4)} />
        <Tab label=
               {
                <>
                  <Typography variant="caption">
                  government
                 </Typography>
                  <Typography variant="caption">
                  政府
                  </Typography>
                </>
             }
        {...a11yProps(5)} />
        <Tab label=
                     {
                      <>
                        <Typography variant="caption">
                        media
                       </Typography>
                        <Typography variant="caption">
                        媒体
                        </Typography>
                      </>
                   }
        {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <p >
      有限责任公司（LLC）是一种常见的商业组织类型，它将股东的责任限制在其对公司投入的资金数额。这意味着<b>股东不会因公司债务而对其个人财产负责</b>。这与公司或合伙企业不同，它们的<b>股东对公司债务无限责任</b>。
        </p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}

export const NavAccount = () =>{
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>
      <Tabs
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      >
        <Tab label=
                     {
                      <>
                        <Typography variant="caption">
                        asset
                       </Typography>
                        <Typography variant="caption">
                        资产
                        </Typography>
                      </>
                   }
        {...a11yProps(0)} />
        <Tab label=
                     {
                      <>
                        <Typography variant="caption">
                        liability
                       </Typography>
                        <Typography variant="caption">
                        负债
                        </Typography>
                      </>
                   }
       {...a11yProps(1)} />
        <Tab label=
                      {
                        <>
                          <Typography variant="caption">
                          equity
                         </Typography>
                          <Typography variant="caption">
                          所有者权益
                          </Typography>
                        </>
                     }
       {...a11yProps(2)} />
        <Tab label=
         {
          <>
            <Typography variant="caption">
            income
           </Typography>
            <Typography variant="caption">
            收入
            </Typography>
          </>
       }
        {...a11yProps(3)} />
        <Tab label=
             {
              <>
                <Typography variant="caption">
                cash flow
               </Typography>
                <Typography variant="caption">
                现金流
                </Typography>
              </>
           }
        {...a11yProps(4)} />
        <Tab label="绘图" {...a11yProps(5)} />
        <Tab label="5" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <h3>Asset</h3>
      <Asset code={asset_code}/>
      <ul>
<li>
     
      <span className='文中强调'>__init__(self, account, debit,date)</span>——asset实体初始化，包括账户，借方和日期
      </li>
      <li>      <span className='文中强调'>Asset.sum()</span>——类方法，返回pandas df格式的Asset类的总结
</li>
      </ul>
      <p >

      会计科目是<b>耦合</b>的，意思是会计科目之间有密切的联系，变动一个科目会对其他科目产生影响。
例如，销售收入科目和应收账款科目之间就是耦合关系，因为销售收入增加会导致应收账款增加。
另外，资产类科目和负债类科目之间也是耦合关系，因为资产类科目增加会导致负债类科目增加。
因此, 会计科目之间的耦合关系对于理解会计数据的正确性和有效性是非常重要的。
        定义资产类及其方法，在 Python 中，可以使用继承来扩展现有的类。下面是一个简单的例子，在资产类的基础上增加无形资产的类：可以根据实际需求来继续扩展这个类。无形资产炒作指的是对无形资产的非理性高估，通常是由于市场上的投资者对某种无形资产的需求过大导致的。

在 Python 中，你可以使用计算模型和模拟来描述无形资产炒作。例如，可以使用随机游走模型来模拟无形资产价值的波动，并使用数据分析和可视化来表示炒作现象。

这里是一个简单的例子，使用 Python 的 random 模块来模拟无形资产价值的波动，并使用 matplotlib 库来可视化无形资产炒作现象:使用更复杂的模型来模拟资产价格的变化。例如，使用随机游走模型或者 GBM 模型替换简单的随机浮动模型。

添加额外的参数，以模拟不同的市场条件。例如，可以添加一个参数来模拟利率水平或者经济增长率，这些因素可能会影响资产价格的变化。

添加事件处理，以模拟重要事件对资产价格的影响。例如，可以在模拟过程中设置一些关键日期，并在这些日期触发特殊事件，以模拟公司重大公告或者经济危机对资产价格的影响。

使用统计学方法来评估模型的效果。例如，可以使用检验模型的拟合程度、计算模型的波动率、或者评估模型的风险等方法来评估模型的效果。

使用可视化工具来展示模拟结果，如使用 Plotly,Seaborn, Bokeh等库来绘制出更加美观、互动性强的图表.添加一个方法来监测商誉在社交媒体上的口碑。例如，可以使用社交媒体分析工具来收集关于公司和品牌的评论，并统计正面和负面评论的比例。首先我们需要有一个基础的 BusinessEntity 类来定义一些通用的属性和方法。下面是一个简单的 BusinessEntity 类的示例:
        </p>
        无形资产（Intangible Assets）是指没有实物形态的可辨认非货币性资产。无形资产具有广义和狭义之分，广义的无形资产包括金融资产、长期股权投资、专利权、商标权等，因为它们没有物质实体，而是表现为某种法定权利或技术。但是，会计上通常将无形资产作狭义的理解，即将专利权、商标权等称为无形资产。
      </TabPanel>
      <TabPanel value={value} index={1}>
      <h3>Liability</h3>
      <Asset code={liability_code}/>
      <ul>
<li>
     
      <span className='文中强调'>__init__(self, account, debit,
date)</span>——asset实体初始化，包括账户，借方和日期
      </li>
      <li>      <span className='文中强调'>Asset.sum()</span>——类方
法，返回pandas df格式的Asset类的总结
</li>
      </ul>
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
    <p className="philosophy-text">
    在会计中，fair value 和 market price 都涉及到企业资产和负债的估值。

Fair value 表示的是在正常交易条件下，资产或负债在合理时间内能够被买卖的价格。这个价格是在市场上可以被对手接受的价格。Fair value 是会计准则规定的估值方法之一，它反映了资产和负债在市场上的真实价值。

Market price 则是指市场上当前资产或负债的实际成交价格。 Market price 可能会因市场波动而变化，并不能反映资产或负债的真实价值。

总之, Fair value 更加关注资产和负债的价值，而 market price 则更关注市场上的实际成交价格。

需要注意的是，Fair value 可能比 market price 更高或更低，但是如果企业使用 fair value 估值，它必须使用市场数据来确定 fair value。
    </p>
    <Alert   icon={false} variant="outlined" severity="info" className='mark'>
<h3>
When the supply of a product is less than the demand, the market price of the product should be higher than its fair value. This is because in a situation of limited supply, sellers are able to charge a higher price due to the increased demand for the product. This increased demand results in higher prices in the market, which is reflected in the market price of the product. On the other hand, the fair value of a product is determined by its intrinsic value and is not affected by the market conditions. Therefore, in this scenario, the fair value of the product may be lower than the market price because it does not take into account the current market conditions.</h3>
</Alert>
    <p className="philosophy-text">
    市场价格和公允价值是会计中估值资产和负债的两种方法。

市场价格是指市场上当前资产或负债的实际成交价格。市场价格可能会因市场波动而变化，并不能反映资产或负债的真实价值。

公允价值(Fair value)表示的是在正常交易条件下，资产或负债在合理时间内能够被买卖的价格。这个价格是在市场上可以被对手接受的价格。公允价值是会计准则规定的估值方法之一，它反映了资产和负债在市场上的真实价值。

市场价格和公允价值的关系是互相影响的，因为市场价格可能会影响公允价值的测算，而公允价值又可能会影响市场价格的变化。

市场价格主要反映当前市场行情，而公允价值则更多地反映资产或负债的价值。在实际操作中, 企业可以根据具体情况来选择使用市场价格还是公允价值来估值资产和负债。
    </p>
  
  </TabPanel>
    </Box>
  );
}


  export const Account = () => (
    <div>
             <Title>Account  <Rating name="read-only" value={5} readOnly /></Title>

       <NavDocument /> 
       <NavAccount /> 
    </div>
  );
  export const Entity = () => (
    <div>
             <Title>Entity <Rating name="read-only" value={4} readOnly /></Title>
       <NavDocument /> 
       <NavEntity /> 
    </div>
  );
  export const Person = () => (
    <div>
       <Title>Person<Rating name="read-only" value={5} readOnly /></Title>
       <NavDocument /> 
       <NavPerson /> 
    </div>
  );
  export const Event = () => (
    <div>
             <Title>Event<Rating name="read-only" value={5} readOnly /></Title>
       <NavDocument /> 
       <NavEvent /> 
    </div>
  );
  
  export const Information = () => (
    <div>
             <Title>Information <Rating name="read-only" value={3} readOnly /></Title>
       <NavDocument /> 
       <NavInformation /> 
    </div>
  );
  export const Language = () => (
    <div>       <Title>Language<Rating name="read-only" value={5} readOnly /></Title>

       <NavDocument /> 
       <NavNlp /> 
    </div>
  );
  export const Culture = () => (
    <div>
             <Title>Culture  <Rating name="read-only" value={4} readOnly /></Title>
       <NavDocument /> 

       <NavCulture /> 
    </div>
  );
  export const Market = () => (
    <div>
             <Title> Market <Rating name="read-only" value={4} readOnly /></Title>
       <NavDocument /> 
       <NavMarket /> 
    </div>
  );
  export const Institution = () => (
    <div>
     <Title> Institution <Rating name="read-only" value={4} readOnly /></Title>
       <NavDocument /> 
       
       <NavInstitution /> 
    </div>
  );
  export const Strategy = () => (
    <div>
    <Title> Strategy <Rating name="read-only" value={3} readOnly /></Title>
       <NavDocument /> 
       <NavStrategy /> 
    </div>
  );
  export const Calculate = () => (
    <div>
        <Title> Calculate  <Rating name="read-only" value={3} readOnly /></Title>
       <NavDocument /> 
       
       <NavCalculate /> 
    </div>
  );
  export const Abnormal = () => (
    <div>
          <Title> Abnormal  <Rating name="read-only" value={3} readOnly /></Title>
       <NavDocument /> 
       <NavAbnormal /> 
    </div>
  );