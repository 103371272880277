import React from "react";
    import { useAuth0 } from '@auth0/auth0-react'
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { Upload} from "../aws/AwsFunctions_S3";
export const Repositories = () => {
  const { isAuthenticated, user } = useAuth0();

  return (
    <main style={{ padding: '1rem 0' }}>
      {isAuthenticated &&
        <Upload/>
      }
    </main>
  );
}