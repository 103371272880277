import * as AWS from 'aws-sdk'
import React, { useState, useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from '@mui/material/Avatar';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Input, InputLabel,
    TextField
  } from '@mui/material';
export const docClient = new AWS.DynamoDB.DocumentClient({
    region: 'ap-southeast-1',
    secretAccessKey: 'CFme4Sv4XRNKcY9KKVsw6ABRjXIrS3U7SNgzfJFh',
    accessKeyId: 'AKIA5W7L6HWTCGC4LUEP'
  })
export const fetchData = (tableName) => {
    var params = {
        TableName: tableName
    }
    docClient.scan(params, function (err, data) {
        if (!err) {
            console.log(data)
        }
    })
}
export const putData = (tableName , data) => {
    var params = {
        TableName: tableName,
        Item: data
    }
    docClient.put(params, function (err, data) {
        if (err) {
            console.log('Error', err)
        } else {
            console.log('Success', data)
        }
    })
}

export const MyComponent = (props) => {
    const { user} = useAuth0();
  const [values, setValues] = useState({
    Name: '',
    Company: '',
    Location: '',
    Bio: ''
    });
    const handleChange = (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    };
    const addDataToDynamoDB = async () => {
        const userData = {
          userID:user.email,
          name:values.Name,
          company:values.Company,
          location:values.Location,
          bio:values.Bio
        }
        await putData('Profile' , userData)
      }
    const handleClick = () => {
        addDataToDynamoDB()
       
    }

    return (
        <div>
            <h1>My Profile</h1>
            <hr />
            <Card className="centerr">
   <CardHeader
     subheader="The avatar can be edited"
     title="Avatar Upload"
   />
<UploadAvatarForm/>
<br />
</Card>
            <form
     autoComplete="off"
     noValidate
     {...props}
   >
    <hr />
     <Card>
       <CardHeader
         subheader="The information can be edited"
         title="Profile"
       />
       <Divider />
       <CardContent>
         <Grid
           container
           spacing={3}
         >
           <Grid
             item
             md={6}
             xs={12}
           >
             <TextField
               fullWidth
               helperText="Please specify the name"
               label="Name"
               name="Name"
               onChange={handleChange}
               required
               value={values.Name}
               variant="outlined"
             />
           </Grid>
           <Grid
             item
             md={6}
             xs={12}
           >
             <TextField
               fullWidth
               helperText="Please specify the company"
               name="Company"
               label="Company"
               onChange={handleChange}
               required
               value={values.Company}
               variant="outlined"
             />
           </Grid>
           <Grid
             item
             md={6}
             xs={12}
           >
             <TextField
               fullWidth
               name="Location"
               label="Location"
               onChange={handleChange}
               value={values.Location}
               variant="outlined"
             />
           </Grid>
           <Grid
             item
             md={6}
             xs={12}
           >
             <TextField
               fullWidth
               name="Bio"
               helperText="Tell us about you"
               label="Bio"
               onChange={handleChange}
               required
               value={values.Bio}
               variant="outlined"
             />
           </Grid>
         </Grid>
       </CardContent>
       <Divider />
       <Box
         sx={{
           display: 'flex',
           justifyContent: 'flex-end',
           p: 2
         }}
       >
       </Box>
       <div className='centerr'>
       <Button variant='contained'  onClick={handleClick}>Save</Button>
       <br />
       </div>
     </Card>
   </form>
        </div>
    )
}
const s3 = new AWS.S3(
  {
   region: 'ap-southeast-1',
   secretAccessKey: 'CFme4Sv4XRNKcY9KKVsw6ABRjXIrS3U7SNgzfJFh',
   accessKeyId: 'AKIA5W7L6HWTCGC4LUEP',
   params: { Bucket: 'cpanlp-avatar' },
  }
);
export const UploadAvatarForm = () => {
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState(null);
  const { user, isAuthenticated, isLoading } = useAuth0();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileType = file.type;
    const maxSize = 1 * 1024 * 1024; // 1MB

    if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/gif') {
      alert('The selected file is not a jpg、png or gif');
    } else if (file.size > maxSize) {
      alert("The selected file exceeds the maximum allowed size.");
    } else {
      setFile(event.target.files[0])   }
    setProgress(0)
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const fileName = `avatars/${file.name}`;

    s3.upload({
      Key: fileName,
      Body: file,
      ACL: 'public-read',
    }, (error, data) => {
      if (error) {
        console.log(error);
      } else {
        const dynamodb = new AWS.DynamoDB.DocumentClient(
          {
            region: 'ap-southeast-1',
            secretAccessKey: 'CFme4Sv4XRNKcY9KKVsw6ABRjXIrS3U7SNgzfJFh',
            accessKeyId: 'AKIA5W7L6HWTCGC4LUEP'
          }
        );
        const userId = user.email;
        const avatarUrl = data.Location;

        dynamodb.put({
          TableName: 'avatar',
          Item: {
            userId,
            avatarUrl,
          },
        }, (error) => {
          if (error) {
            console.log(error);
          } else {
            console.log('Avatar uploaded successfully.');
          }
        });
      }
    }).on('httpUploadProgress', (evt) => {
      const percent = parseInt((evt.loaded * 100) / evt.total);
      setProgress(percent);
    })
  };
    return (
      
      <form onSubmit={handleSubmit} className='centerr'>
                            <InputLabel htmlFor="file-input">Select file</InputLabel>

        <Input type="file" onChange={handleFileChange} />
        <br />
        <Button variant="contained" type="submit">Upload</Button>
        {progress > 0 && <div>Progress: {progress}%,The upload has been finished</div>}
      </form>
    )}

    const dynamodb = new AWS.DynamoDB.DocumentClient(
      {
        region: 'ap-southeast-1',
        secretAccessKey: 'CFme4Sv4XRNKcY9KKVsw6ABRjXIrS3U7SNgzfJFh',
        accessKeyId: 'AKIA5W7L6HWTCGC4LUEP'
      }
    );
   export const Avatar1 = () => {
      const { user, isAuthenticated, isLoading } = useAuth0();
      const [avatarUrl, setAvatarUrl] = useState(null);
            useEffect(() => {
        dynamodb.get({
          TableName: 'avatar',
          Key: {
            userId: user.email,
          },
        }, (error, data) => {
          if (error) {
            console.log(error);
          } else {
            setAvatarUrl(data.Item.avatarUrl);
          }
        });
      }, []);
      return (
        <Avatar src={avatarUrl} alt="User avatar" />
      );
    }



    export const MyComponent1 = () => {
      const [items, setItems] = useState([])
      const { user, isAuthenticated, isLoading } = useAuth0();
    const [values, setValues] = useState({
      Name: '',
      Company: '',
      Location: '',
      Bio: ''
      });
      const handleChange = (event) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
      };
      useEffect(() => {
          fetchData('Profile',user.email)
              .then((data) => {
                  setItems(data)
              })
              .catch((err) => {
                  console.error(err)
              })
      }, [])
      useEffect(() => {
        if (items && items.length > 0) {
          localStorage.setItem("userName", items[0].name);
        }
      }, [items]);
      useEffect(() => {
        if (items && items.length > 0) {
          localStorage.setItem("userCompany", items[0].company);
        }
      }, [items]);
      const fetchData = (tableName, userID) => {
          return new Promise((resolve, reject) => {
              const params = {
                  TableName: tableName,
                  KeyConditionExpression: 'userID = :uid',
                  ExpressionAttributeValues: {
                      ':uid': userID
                  }
              }
              docClient.query(params, (err, data) => {
                  if (err) {
                      reject(err)
                  } else {
                      resolve(data.Items)
                  }
              })
          })
      }
  
      return (
        <div className="my-container">
      {items.map((item) => (
        <div className="item" key={item.userID}>
          <div className="text">
            Name: {item.name} <br />
            Bio: {item.bio} <br />
            Company: {item.company} <br />
            Location: {item.location} <br />
            Email: {user.email}
          </div>
        </div>
      ))}
    </div>
      )
  }