import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { MyComponent} from "../aws/AwsFunctions";

export const Profile = () => {
  const {  isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div>
        <MyComponent/>

      </div>
    )
  );
};

