import React from "react";
import {GraphCard} from "../components/Card"
import 语义分割 from '../images/语义分割.png';
import {Title} from "./Overview"
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Rating from '@mui/material/Rating';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
export const Semantic = () => (
    <div>
      <p className="philosophy-text">
      <span className="文中强调">会计语义</span>是指会计信息的意义和含义。这些信息包括会计账目、报表和其他财务文件。会计语义是会计的基本概念，它规定了会计信息应该如何被记录、表示和报告。

在制定会计准则时，会计语义起着重要作用，因为它决定了会计信息应该如何被表示，从而使得这些信息更容易被理解和使用。此外，会计语义还可以帮助会计人员在处理财务信息时避免误解和曲解。

会计语义的一个重要方面是会计概念和原则。会计概念是指会计信息中使用的基本概念和定义，如资产、负债、所有者权益和收益。会计原则是指会计信息记录和报告的基本准则，如实货准则、货币单位准则和实际收益原则。

会计语义对于会计人员来说是非常重要的，因为它们需要理解和使用这些基本概念和原则来准确地记录和报告财务信息。此外，会计语义也对于那些使用财务信息的人来说非常重要。
      </p>
    </div>
  );
  export const Segmentation = () => (
    <div>
               <Title>会计语义分割<Rating name="read-only" value={3} readOnly /></Title>
    <p className="philosophy-text">

    <span className="文中强调">语义分割</span>描述了将每个像素与类标签相关联的过程。很简单，这里我们只关心图像中所有对象的粗略表示。在这里，你可以看到所有以蓝色表示的汽车，以红色表示的行人，以及略带粉红色的街道等。仔细观察可以发现，属于同一类的所有目标之间并没有明显的区别，这意味着所有的车都是蓝色的。这是定义图像分割问题的最简单方法。不同类的物体产生不同的语义，而语义分割就是要将这些属于不同类别的像素进行归类，把属于同一类的像素归为一起。
     语用分割是指将语言中的信息分类或划分到不同的语义类别中，以便更好地理解和使用这些信息。

语用分割通常用于自然语言处理（NLP）领域，旨在帮助计算机和人类更好地理解文本中的信息。在 NLP 中，语用分割可以用于识别语言中的实体、关系、动作和意图等信息。

例如，在一段文本中，语用分割可以帮助计算机识别出“纽约”是一个地名实体，“位于”是一个关系，“美国东北部”是一个地名实体，并且整个句子的意思是“纽约位于美国东北部”。

<GraphCard img= {语义分割} title="语义分割" content="语义分割是指将输入图像的每个像素分配到一个或多个类别的过程。这种分类方法的目的是将图像中的每个像素与其他像素区分开来，以便将图像中的每个对象或部分分配到一个单独的类别。

语义分割在计算机视觉领域中非常重要，因为它可以为机器人或自动驾驶汽车提供高精度的场景理解，也可以用于图像分类和对象检测任务。

常用的语义分割方法包括基于深度学习的方法，如卷积神经网络（CNN）和全连接网络（FCN），以及基于传统机器学习的方法，如随机森林（RF）和支持向量机（SVM）。"/>
会计报表的语义分割是指将会计报表中的信息分类或划分到不同的语义类别中，以便更好地理解和使用这些信息。

会计报表中的信息通常被分为两大类：实际交易信息和会计估计信息。实际交易信息指的是在某个特定会计时期内发生的真实的金融交易信息，而会计估计信息则是根据当前可用的信息对未来可能发生的交易进行估计的信息。

另一种常见的会计报表语义分割方法是将信息划分为报表的不同部分。例如，资产负债表中通常包含有关公司资产和负债的信息，而损益表中通常包含有关公司收益和费用的信息。这种方法有助于人们更好地理解会计报表是如何组织和表示的，并能够更好地看出会计报表中的各个部分之间的关系。
会计的语义分割是指将会计术语和概念分类或划分到不同的语义类别中，以便更好地理解和使用这些术语和概念。这可以帮助人们在处理会计信息时更有效地使用会计术语，并能够更好地区分会计中不同的概念和技术。
<p className="例子">
例如，可以将会计术语划分为资产、负债、所有者权益、收益和费用等不同的语义类别。
     另一种常见的会计语义分割方法是将会计术语划分为报告的不同部分。例如，可以将会计术语划分为资产负债表、损益表和现金流量表等。这种方法有助于人们更好地理解会计信息是如何组织和表示的，并能够更好地看出会计报告中的各个部分之间的关系。

还有一种常见的会计语义分割方法是将会计术语划分为实际交易和会计估计。例如，实际交易指的是在某个特定会计时期内发生的真实的金融交易，而会计估计则是根据当前可用的信息对未来可能发生的交易进行估计的数据。这种方法有助于人们更好地理解会计信息的真实性和可靠性。
</p>
</p>

    </div>
    );
    export const Semantic2 = () => (
      <div>
      语义2
      </div>
      );
      export const Semantic3 = () => (
        <div>
          语义3
        </div>
        );
        export const Inline = () => (
          <>
            <p className="philosophy-text">
            <span className="文中强调">会计语义</span>是指会计信息的意义和含义。这些信息包括会计账目、报表和其他财务文件。会计语义是会计的基本概念，
      它规定了会计信息应该如何被记录、表示和报告。
      
      在制定会计准则时，会计语义起着重要作用，因为它决定了会计信息应该如何被表示，从而使得这些信息更容易被理解和使用。此外，会计语义还可以帮助会计人员理财务信息时避免误解和曲解。
      
      会计语义的一个重要方面是会计概念和原则。会计概念是指会计信息中使用的基本概念和定义，如资产、负债、所有者权益和收益。会计原则是指会计信息记录和报基本准则，如实货准则、货币单位准则和实际收益原则。
      
      会计语义对于会计人员来说是非常重要的，因为它们需要理解和使用这些基本概念和原则来准确地记录和报告财务信息。此外，会计语义也对于那些使用财务信息的说非常重要。
            </p>
          </>
        );