import * as AWS from 'aws-sdk'
import React, { useState, useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { putData } from './AwsFunctions';
import { ListItemButton } from '@mui/material';
import { docClient } from './AwsFunctions';
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import {
    Button,
    ListItemText,
    TextField, Input, InputLabel
  } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';

const s3 = new AWS.S3(
  {
    region: 'ap-southeast-1',
    secretAccessKey: 'CFme4Sv4XRNKcY9KKVsw6ABRjXIrS3U7SNgzfJFh',
    accessKeyId: 'AKIA5W7L6HWTCGC4LUEP',
    params: { Bucket: 'cpanlp-s3' },

  }
  )
  const useStyles = makeStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      border: '0.3px solid #00ffff',
      borderRadius: 4,
    },
    input: {
      marginLeft: 11,
      flex: 1,
      backgroundColor:"#FFFFFF",
      padding:2
    },
    iconButton: {
      padding: 10,
    },
    myInput: { // 更具体的选择器
      color: '#fff',
    },
  });
  
 export const Upload = () => {
  const [files, setFiles] = useState([]);
  const { user} = useAuth0();
  const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);

    const handleDescriptionChange = (event) => {
      setDescription(event.target.value);
    };
    const name = localStorage.getItem("userName");
    const company = localStorage.getItem("userCompany");



    const handleFileChange = (event) => {
      const file = event.target.files[0];
      const allowedFileType = "text/x-python-script";
      setProgress(0)
      
  const maxSize = 5 * 1024 * 1024; // 5MB


if (!name || !company) {
  alert("Please complete your profile by providing your name and company.");
}
  else if (file.type !== allowedFileType) {
    alert("The selected file is not a Python script.");
  } else if (file.size > maxSize) {
    alert("The selected file exceeds the maximum allowed size.");
  } else {
    const params = {
      TableName: "pyfile",
      Key: {
        filename: file.name,
      },
    };
    docClient.get(params, (err, data) => {
      if (err) {
        console.log(err);
      } else if (data.Item) {
        if (data.Item.userID === user.email) {
          if (window.confirm("This file already exists. Do you want to overwrite it?")) {
            setFile(event.target.files[0]);
          }
        } else {
          alert("This file name is already in use by another user. Please rename the file and try again.");
        }
      } else {
        setFile(event.target.files[0]);
      }  });
    }
  }
    const handleUpload = () => {

      const addDataToDynamoDB = async () => {
        const Data = {
          filename:file.name,
          description:description,
          name:name,
          company:company,
          userID:user.email,
        }
        await putData('pyfile' , Data)
      }
      const params = {
        Key: file.name,
        Body: file,
      };
      s3.upload(params, (err, data) => {
        if (err) {
          console.log(err);
        } else {
          console.log('Upload Success:', data.Location);
        }
      })
      .on('httpUploadProgress', (evt) => {
        const percent = parseInt((evt.loaded * 100) / evt.total);
        setProgress(percent);
      });
      addDataToDynamoDB()
    };
    const handleDownload = (key) => {
      const params = {
        Key: key,
      };
      s3.getSignedUrl('getObject', params, (err, url) => {
        if (err) {
          console.log(err);
        } else {
          window.open(url);
        }
      });
    };
    useEffect(() => {
      const params = {
        TableName: "pyfile",
        FilterExpression: "userID = :userID",
        ExpressionAttributeValues: {
          ":userID": user.email,
        },
      };
  
      docClient.scan(params, (err, data) => {
        if (err) {
          console.log(err);
        } else {
          setFiles(data.Items);
        }
      });
    }, []);
    const handleDelete = (filename) => {
      const params = {
        TableName: "pyfile",
        Key: {
          filename: filename,
        },
      };
  
      docClient.delete(params, (err, data) => {
        if (err) {
          console.log(err);
        } else {
          setFiles((prevFiles) => prevFiles.filter((file) => file.filename !== filename));
        }
      });
      const params1 = {
        Bucket: "cpanlp-s3",
        Key: filename,
      };
      s3.deleteObject(params1, (err, data) => {
        if (err) {
          console.log(err);
        } else {
          console.log("File deleted:", filename);
        }
      });
    };
    return (
      <div>
      <h1>My Repositories</h1>
      <hr />
      <h2>Upload Files</h2>
      <div className='centerr'>
                    <InputLabel htmlFor="file-input">Select file</InputLabel>
                    <Input id="file-input" type="file" onChange={handleFileChange} />
                    <br />
      <TextField
      sx={{ m: 2,width: 400, maxWidth: '100%'}}
       multiline
       maxRows={10}
        label="File description"
        value={description}
        onChange={handleDescriptionChange}
      />
      <br />
        <Button variant="contained" color="primary" onClick={handleUpload}>
        Upload
      </Button>
        {progress > 0 && <div>Progress: {progress}%</div>}
      </div>
      <hr />
      <h2>Uploaded</h2>

    <Table>
      <TableHead>
        <TableRow>
          <TableCell>File Name</TableCell>
          <TableCell>Company</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Description</TableCell>
          <TableCell> </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {files.map((file) => (
          <TableRow key={file.filename}>
            <TableCell>
              <ListItemButton divider onClick={() => handleDownload(file.filename)}>
                {file.filename}
              </ListItemButton>
            </TableCell>
            <TableCell>
              <Typography style={{ color: "purple", fontSize: "1rem" }}>
                {file.company}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ color: "blue", fontSize: "0.8rem" }}>
                {file.name}
              </Typography>
            </TableCell>
            <TableCell>{file.description}</TableCell>
            <TableCell>
                <Button variant="contained" color="primary" onClick={() => handleDelete(file.filename)}>
                  Delete
                </Button>
              </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>

      </div>
    );
  };

export const ListFiles = () => {
  const [files, setFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const handleDownload = (key) => {
    const params = {
      Key: key,
    };
    s3.getSignedUrl('getObject', params, (err, url) => {
      if (err) {
        console.log(err);
      } else {
        window.open(url);
      }
    });
  };
  const handleClick1 = async (event) => {
    const params = {
      MaxKeys: 5      
    };
    setAnchorEl(event.currentTarget);
    s3.listObjects(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        const pattern = new RegExp(searchTerm, 'i');
        const filteredFiles = data.Contents.filter((file) => pattern.test(file.Key));
        setFiles(filteredFiles);
      }
    });
   };
   const handleClick = async (event) => {
    const params = {
      TableName:'pyfile',
      Limit: 5
    };
    setAnchorEl(event.currentTarget);
    docClient.scan(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        const pattern = new RegExp(searchTerm, 'i');
        const filteredItems = data.Items.filter((item) => 
        {
          const filenameMatch = pattern.test(item.filename);
          const descriptionMatch = pattern.test(item.description);
          const nameMatch = pattern.test(item.name);
          const companyMatch = pattern.test(item.company);

          return filenameMatch || descriptionMatch || nameMatch || companyMatch;
        }
        );
        setFiles(filteredItems);
      }
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <div>
      <div  className={classes.root}>
      <InputBase
          className={classes.input}
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />         <IconButton onClick={handleClick} color="primary">
       <SearchIcon />
     </IconButton>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ 
          top: anchorEl ? anchorEl.getBoundingClientRect().bottom : 0, 
          left: anchorEl ? anchorEl.getBoundingClientRect().left - 80 : 0, // 将left值减去10
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          {files.map((file) => (
            <ListItemButton key={file.filename} divider>
               <ListItemText
               onClick={() => handleDownload(file.filename)}
            primary={`${file.filename}`}
            secondary={`${file.description}`}>
          </ListItemText>
          <Typography style={{ color: "purple", fontSize: "1rem" }} >{file.company}&nbsp;</Typography>
          <Typography style={{ color: "blue", fontSize: "0.8rem" }}>{file.name}</Typography>
            </ListItemButton>

))}
        </List>
      </Popover>
    </div>
  );
};


