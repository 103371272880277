import { useNavigate } from "react-router-dom";
import React, {useCallback} from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { LoginButton } from "../components/auth/LoginButton";
import { AccountMenu } from "../components/auth/AccountMenu";
import { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import AWS from 'aws-sdk';
import Popover from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';
import {ListFiles} from "../components/aws/AwsFunctions_S3"
import {Link } from 'react-router-dom';
import { Menu, MenuItem, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Dropdown = ({label, items}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowUp, setArrowUp] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setArrowUp((prevState) => !prevState);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setArrowUp((prevState) => !prevState);
  };
  
  return (
    <div>
      <IconButton onClick={handleClick} color="inherit" style={{ padding: '4px',marginTop: "16px" }}>
        <div className="wordnav" style={{ fontSize: '18px' , marginRight: '2px', fontFamily: 'Arial'  }}>{label}</div>
        {arrowUp ? (
          <ExpandLessIcon style={{ color: 'white', fontSize: '18px' }} />
        ) : (
          <ExpandMoreIcon style={{ color: 'white' ,fontSize: '18px'  }} />
        )}      
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item, index) => 
          <MenuItem key={index} onClick={handleClose}>
            <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit', fontFamily: 'Arial'  }}>{item.name}</Link>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};



const useStyles = makeStyles({
  search: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 2,
    flex: 1,
  },
});
const docClient = new AWS.DynamoDB.DocumentClient({
    region: 'ap-southeast-1',
    secretAccessKey: 'CFme4Sv4XRNKcY9KKVsw6ABRjXIrS3U7SNgzfJFh',
    accessKeyId: 'AKIA5W7L6HWTCGC4LUEP'
  })
  
const SearchBar1 = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [results, setResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

   const handleClick = async (event) => {
     setAnchorEl(event.currentTarget);
      try {
        const params = {
          TableName: 'Profile',
          FilterExpression: 'contains(company,:searchTerm)',
          ExpressionAttributeValues: {
            ':searchTerm': event.target.value,
          },
          
        };
        const data = await docClient.scan(params).promise();
        setResults(data.Items);
      } catch (error) {
        console.log(error);
      }
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'search-popover' : undefined;
  
    return (
      <div className={classes.search}>
        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', height:32, width: 220 }}
        >
          <InputBase
            className={classes.input}
            placeholder="Search"
            inputProps={{ 'aria-label': 'search repositories' }}
            onClick={handleClick}

          />
        </Paper>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {results.map((result) => (
            <p key={result.name}>Name:{result.name},Company:{result.company}</p>
          ))}
          
        </Popover>
      </div>
    );  


}
export const Nav = () => {
    const navigate = useNavigate();
    const handleOnClick1 = useCallback(() => navigate('/overview/1', {replace: true}), [navigate]);
    const handleOnClick2 = useCallback(() => navigate('/overview/2', {replace: true}), [navigate]);
    const handleOnClick3 = useCallback(() => navigate('/overview/3', {replace: true}), [navigate]);
    const handleOnClick4 = useCallback(() => navigate('/overview/4', {replace: true}), [navigate]);
    const handleOnClick5 = useCallback(() => navigate('/overview/5', {replace: true}), [navigate]);
    const handleOnClick6 = useCallback(() => navigate('/overview/6', {replace: true}), [navigate]);
    return (
        <div >
 <Grid item xs={12} sm={6} md={4}  >

       <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}} type="button" onClick={handleOnClick1}>
       Intelligent Accounting
     </Button>
     <Button variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  type="button" 
onClick={handleOnClick6}>
    Economic Basis
     </Button>
     <Button variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  type="button" onClick={handleOnClick2}>
     linguistic turn
        </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  type="button" onClick={handleOnClick3}>
        Big data
    </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  type="button" onClick={handleOnClick4}>
            AI
        </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  type="button" onClick=
{handleOnClick5}>
       philosophy
   </Button>
   </Grid>
     </div>
    );
  }
  export const NavDocument = () => {
    const navigate = useNavigate();
    const handleOnClick1 = useCallback(() => navigate('/documentation/account', {replace: true}), [navigate]);
    const handleOnClick2 = useCallback(() => navigate('/documentation/entity', {replace: true}), [navigate]);
    const handleOnClick3 = useCallback(() => navigate('/documentation/event', {replace: true}), [navigate]);
    const handleOnClick4 = useCallback(() => navigate('/documentation/information', {replace: true}), [navigate]);
    const handleOnClick5 = useCallback(() => navigate('/documentation/language', {replace: true}), 
    [navigate]);
    const handleOnClick6 = useCallback(() => navigate('/documentation/culture', {replace: true}), 
    [navigate]);
    const handleOnClick7 = useCallback(() => navigate('/documentation/market', {replace: true}), 
    [navigate]);
    const handleOnClick8 = useCallback(() => navigate('/documentation/institution', {replace: true}), 
    [navigate]);
    const handleOnClick9 = useCallback(() => navigate('/documentation/institution', {replace: true}), 
[navigate]);
const handleOnClick10 = useCallback(() => navigate('/documentation/person', {replace: true}), 
[navigate]);
const handleOnClick11 = useCallback(() => navigate('/documentation/calculate', {replace: true}), 
[navigate]);
const handleOnClick12 = useCallback(() => navigate('/documentation/abnormal', {replace: true}), 
[navigate]);
const handleOnClick13 = useCallback(() => navigate('/documentation/strategy', 
{replace: true}), 
[navigate]);
    return (
        <div >
 <Grid item xs={12} sm={6} md={4}  >
       <Button  variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}} type="button" onClick={handleOnClick1}>
       Account
     </Button>
     <Button  variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  type="button" onClick={handleOnClick2}>
       Entity
        </Button>
        <Button  variant = "outlined" style={{ width:"auto", height: "30px",
color:"blue"}}  type="button" onClick={handleOnClick10}>
    Person
     </Button>
        <Button  variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  type="button" onClick={handleOnClick3}>
        Event
    </Button>
        <Button variant = "outlined" variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  type="button" onClick={handleOnClick4}>
            information
        </Button>
        <Button  variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  type="button" onClick=
{handleOnClick5}>
       natural language
   </Button>
   <Button  variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}} type="button" onClick=
{handleOnClick6}>
   culture
 </Button>
 <Button  variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  type="button" onClick=
{handleOnClick7}>
   market
    </Button>
    <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  type="button" onClick=
{handleOnClick8}>
    institution
</Button>
<Button variant = "outlined" style={{ width: "auto", height: "30px",
color:"blue"}}  type="button" onClick=
{handleOnClick13}>
    strategy
</Button>
<Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  type="button" onClick=
{handleOnClick9}>
    risk
</Button>
<Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  type="button" onClick=
{handleOnClick11}>
   calculate
</Button>
<Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  type="button" onClick=
{handleOnClick12}>
   abnormal
</Button>

   </Grid >
     </div>
    );
  }
export  const NavQuestion = () => {
    const navigate = useNavigate();
    const handleOnClick1 = useCallback(() => navigate('/overview/oldquestion', {replace: 
true}), [navigate]);
    const handleOnClick2 = useCallback(() => navigate('/overview/newquestion', {replace: 
true}), [navigate]);
    const handleOnClick3 = useCallback(() => navigate('/overview/newanswer', {replace: 
true}), [navigate]);
    return (
        <>
 <Grid item xs={12} sm={6} md={4}  >
       <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}} 
type="button" onClick={handleOnClick1}>
old question
     </Button>
     <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  type="button" 
onClick={handleOnClick2}>
       
new question
        </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick3}>
             New Answers
    </Button>
   </Grid>
     </>
    );
  }
  export  const NavPresumption = () => {
    const navigate = useNavigate();
    const handleOnClick1 = useCallback(() => navigate('/overview/linguisticpresumption', 
{replace: 
true}), [navigate]);
    const handleOnClick2 = useCallback(() => navigate('/overview/accountingpresumption', 
{replace: 
true}), [navigate]);
    const handleOnClick3 = useCallback(() => navigate('/overview/economicspresumption', {replace: 
true}), [navigate]);
    const handleOnClick4 = useCallback(() => navigate('/overview/ltoaccountingpresumption', {replace: 
true}), [navigate]);

    return (
        <div >
 <Grid item xs={12} sm={6} md={4}  >
       <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}} 
type="button" onClick={handleOnClick1}>
linguistic assumption
     </Button>
     <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  type="button" 
onClick={handleOnClick2}>
      accounting assumption
        </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick3}>
       economy assumption
    </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick4}>
       Linguistic Turn assumption
    </Button>
   </Grid>
     </div>
    );
  }

  export  const NavHome = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();
    const handleOnClick1 = useCallback(() => navigate('/install', 
{replace: 
true}), [navigate]);
    const handleOnClick2 = useCallback(() => navigate('/documentation', 
{replace: 
true}), [navigate]);
const handleOnClick4 = useCallback(() => navigate('/example', {replace: 
    true}), [navigate]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    const dropdownItems = [
      {
        label: "Home",
        items: [
          {name: "Home", path: "/"},
          {name: "Management", path: "/documentation/language"}
        ]
      },
      {
        label: "Research",
        items: [
          {name: "intelligent accounting", path: "/documentation/account"},
          {name: "management", path: "/documentation/language"}
        ]
      },
      {
        label: "Developers",
        items: [
          {name: "intelligent accounting", path: "/documentation/account"},
          {name: "management", path: "/documentation/language"}
        ]
      },
      // Add other dropdowns here...
    ];
    const DropdownComponent = (
      <>
      <Dropdown 
      label="Home" 
      items={[
        {name: "Home", path: "/"},
        {name: "Management", path: "/documentation/language"}
      ]} 
    />
     <Dropdown 
      label="Research" 
      items={[
        {name: "intelligent accounting", path: "/documentation/account"},
        {name: "management", path: "/documentation/language"}
      ]} 
    />
    <Dropdown 
      label="Developers" 
      items={[
        {name: "intelligent accounting", path: "/documentation/account"},
        {name: "management", path: "/documentation/language"}
      ]} 
    />
    <Dropdown 
      label="Product" 
      items={[
        {name: "intelligent accounting", path: "/documentation/account"},
        {name: "management", path: "/documentation/language"}
      ]} 
    />
    <Dropdown 
      label="Company" 
      items={[
        {name: "intelligent accounting", path: "/documentation/account"},
        {name: "management", path: "/documentation/language"}
      ]} 
    />
    </>
    );
    return (
        <div >
 <div className="横向排列" >
 <div className="横向排列1" >
 {windowWidth > 900 ? DropdownComponent : null}
 </div>
 <div className="横向排列1" >
 <div style={{ margin: '6.5px', padding: '4px'}} >
      <ListFiles/>
</div>
    <LoginButton/>
    <AccountMenu/>
    </div>
   </div>
     </div>
    );
  }

  export const NavReinforcementlearning = () => {
    const navigate = useNavigate();
    const handleOnClick1 = useCallback(() => navigate('/reinforcement/space', {replace: true}), 
[navigate]);
    const handleOnClick2 = useCallback(() => navigate('/reinforcement/action', {replace: true}), 
[navigate]);
    const handleOnClick3 = useCallback(() => navigate('/reinforcement/step', {replace: true}), 
[navigate]);
    const handleOnClick4 = useCallback(() => navigate('/reinforcement/reward', {replace: true}), 
[navigate]);
    const handleOnClick5 = useCallback(() => navigate('/reinforcement/agent', {replace: true}), 
[navigate]);
    const handleOnClick6 = useCallback(() => navigate('/reinforcement/loss', {replace: true}), 
[navigate]);
    return (
        <div >
 <Grid item xs={12} sm={6} md={4}  >
 <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick5}>
           agent
        </Button>
       <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}} 
type="button" onClick={handleOnClick1}>
       space
     </Button>
     <Button variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  
type="button" 
onClick={handleOnClick2}>
    action
     </Button>
     <Button variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick3}>
     step(action)
        </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick4}>
        reward
    </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick=
{handleOnClick6}>
       Loss function
   </Button>
   </Grid>
     </div>
    );
  }

  export const NavEnvs = () => {
    const navigate = useNavigate();
    const handleOnClick1 = useCallback(() => navigate('/reinforcement/space', {replace: true}), 
[navigate]);
    const handleOnClick2 = useCallback(() => navigate('/reinforcement/action', {replace: true}), 
[navigate]);
    const handleOnClick3 = useCallback(() => navigate('/reinforcement/step', {replace: true}), 
[navigate]);
    const handleOnClick4 = useCallback(() => navigate('/reinforcement/reward', {replace: true}), 
[navigate]);
    const handleOnClick5 = useCallback(() => navigate('/reinforcement/agent', {replace: true}), 
[navigate]);
    const handleOnClick6 = useCallback(() => navigate('/reinforcement/reset', {replace: true}), 
[navigate]);
    return (
        <div >
 <Grid item xs={12} sm={6} md={4}  >
 <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick5}>
           agent
        </Button>
       <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}} 
type="button" onClick={handleOnClick1}>
       space
     </Button>
     <Button variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  
type="button" 
onClick={handleOnClick2}>
    action
     </Button>
     <Button variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick3}>
     step(action)
        </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick4}>
        reward
    </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick=
{handleOnClick6}>
       reset
   </Button>
   </Grid>
     </div>
    );
  }

  export const NavTrain = () => {
    const navigate = useNavigate();
    const handleOnClick1 = useCallback(() => navigate('/reinforcement/actor_critic', {replace: true}), 
[navigate]);
    const handleOnClick2 = useCallback(() => navigate('/reinforcement/action', {replace: true}), 
[navigate]);
    const handleOnClick3 = useCallback(() => navigate('/reinforcement/step', {replace: true}), 
[navigate]);
    const handleOnClick4 = useCallback(() => navigate('/reinforcement/reward', {replace: true}), 
[navigate]);
    const handleOnClick5 = useCallback(() => navigate('/reinforcement/dqn', {replace: true}), 
[navigate]);
    const handleOnClick6 = useCallback(() => navigate('/reinforcement/reset', {replace: true}), 
[navigate]);
    return (
        <div >
 <Grid item xs={12} sm={6} md={4}  >
 <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick5}>
           DQN
        </Button>
       <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}} 
type="button" onClick={handleOnClick1}>
       Actor_Critic
     </Button>
     <Button variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  
type="button" 
onClick={handleOnClick2}>
    action
     </Button>
     <Button variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick3}>
     step(action)
        </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick4}>
        reward
    </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick=
{handleOnClick6}>
       reset
   </Button>
   </Grid>
     </div>
    );
  }

  export const NavPolicy = () => {
    const navigate = useNavigate();
    const handleOnClick1 = useCallback(() => navigate('/reinforcement/epsilon', {replace: true}), 
[navigate]);
    const handleOnClick2 = useCallback(() => navigate('/reinforcement/action', {replace: 
true}), 
[navigate]);
    const handleOnClick3 = useCallback(() => navigate('/reinforcement/step', {replace: true}), 
[navigate]);
    const handleOnClick4 = useCallback(() => navigate('/reinforcement/reward', {replace: 
true}), 
[navigate]);
    const handleOnClick5 = useCallback(() => navigate('/reinforcement/dqn', {replace: true}), 
[navigate]);
    const handleOnClick6 = useCallback(() => navigate('/reinforcement/reset', {replace: true}), 
[navigate]);
    return (
        <div >
 <Grid item xs={12} sm={6} md={4}  >
 <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick5}>
           DQN
        </Button>
       <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}} 
type="button" onClick={handleOnClick1}>
       epsilon greedy 
     </Button>
     <Button variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  
type="button" 
onClick={handleOnClick2}>
    action动作
     </Button>
     <Button variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick3}>
     step(action)
        </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick4}>
        reward
    </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick=
{handleOnClick6}>
       reset
   </Button>
   </Grid>
     </div>
    );
  }

  export const NavProblem = () => {
    const navigate = useNavigate();
    const handleOnClick1 = useCallback(() => navigate('/reinforcement/epsilon', {replace: true}), 
[navigate]);
    const handleOnClick2 = useCallback(() => navigate('/reinforcement/action', {replace: 
true}), 
[navigate]);
    const handleOnClick3 = useCallback(() => navigate('/reinforcement/step', {replace: true}), 
[navigate]);
    const handleOnClick4 = useCallback(() => navigate('/reinforcement/reward', {replace: 
true}), 
[navigate]);
    const handleOnClick5 = useCallback(() => navigate('/reinforcement/convergence', {replace: true}), 
[navigate]);
    const handleOnClick6 = useCallback(() => navigate('/reinforcement/reset', {replace: true}), 
[navigate]);
    return (
        <div >
 <Grid item xs={12} sm={6} md={4}  >
 <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick5}>
          convergence
        </Button>
       <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}} 
type="button" onClick={handleOnClick1}>
       epsilon greedy 
     </Button>
     <Button variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  
type="button" 
onClick={handleOnClick2}>
    action
     </Button>
     <Button variant = "outlined" style={{ width:"auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick3}>
     step(action)
        </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick={handleOnClick4}>
        reward
    </Button>
        <Button variant = "outlined" style={{ width: "auto", height: "30px",color:"blue"}}  
type="button" onClick=
{handleOnClick6}>
       reset
   </Button>
   </Grid>
     </div>
    );
  }

  