import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import * as AWS from 'aws-sdk'
const domain = process.env.REACT_APP_AUTH0_DOMAIN
const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID
const uri='http://localhost:3000';
const configuration = {
    region: 'ap-southeast-1',
    secretAccessKey: 'CFme4Sv4XRNKcY9KKVsw6ABRjXIrS3U7SNgzfJFh',
    accessKeyId: 'AKIA5W7L6HWTCGC4LUEP'
}
AWS.config.update(configuration)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Auth0Provider
        domain={domain}
        clientId={clientID}
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: uri
        }}
>
    <App />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
