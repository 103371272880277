import React from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import {Title} from "../Overview";
import Rating from '@mui/material/Rating';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {NavReinforcementlearning,NavTrain,NavPolicy} from "../Nav";
import {Asset} from "../Code"
import {action_space_code,action_space_code1, action_space_code2,action_space_code3,action_space_code4,action_space_code5,action_space_code6,loss_function_code1,loss_function_code2,loss_function_code3} from "../../codes/Reinforcement_Code"
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  export const NavSpace = () =>{
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>
  
        <Tabs
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        >
          <Tab label=
                 {
                  <>
                    <Typography variant="caption">
                    observation space
                    </Typography>
                    <Typography variant="caption">
                    观察空间
                    </Typography>
                  </>
               }
         {...a11yProps(0)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  action space
                                  </Typography>
                  <Typography variant="caption">
                 动作空间
                  </Typography>
                </>
             }
          {...a11yProps(1)} />
          <Tab label=
             {
              <>
                <Typography variant="caption">
                Oligopoly
                                </Typography>
                <Typography variant="caption">
                寡头垄断
                </Typography>
              </>
           }
          {...a11yProps(2)} />
          <Tab label=
              {
                <>
                  <Typography variant="caption">
                  Monopolistic Competition
                                  </Typography>
                  <Typography variant="caption">
                  垄断竞争
                  </Typography>
                </>
             }
          {...a11yProps(3)} />
          <Tab label=
           {
            <>
              <Typography variant="caption">
              commodity
             </Typography>
              <Typography variant="caption">
              商品
              </Typography>
            </>
         }
           {...a11yProps(4)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  stock
                 </Typography>
                  <Typography variant="caption">
                  股票
                  </Typography>
                </>
             }
          {...a11yProps(5)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  bond
                 </Typography>
                  <Typography variant="caption">
                  债券
                  </Typography>
                </>
             }
        {...a11yProps(6)} />
        </Tabs>
        <TabPanel value={value} index={0}>
        <p >        
        <h3>定义环境：状态，动作和奖励</h3>
        The observation space and action space in a reinforcement learning (RL) environment define the valid inputs and outputs for the environment's step and reset methods, respectively.
        An example of an 8-dimensional observation space in reinforcement learning could be the state of a robot in an environment. The 8 dimensions could represent the following observations:
<ol><li>X-coordinate position</li><li>Y-coordinate position</li><li>Orientation (angle)</li><li>Linear velocity</li><li>Angular velocity</li><li>
Proximity sensor readings (left, right, front, back)</li><li>Camera image</li><li>
Audio readings</li></ol>

These observations could be used to represent the current state of the robot, and the agent could use this information to make decisions on its next action in the environment.

Regenerate response
        在强化学习中，<strong>观测空间是指智能体能够观测到的状态的集合。它是环境的一部分，描述了智能体能够感知到的所有信息。</strong>
        <br/>
        The state space represents an agent's perception of the business environment.<strong> Just like a human CPA</strong> analyzing various information, here our agent passively observes many features and learns by interacting with the business environment (usually by replaying historical data and corporate report).
        <br/>
观测空间可以是离散的或连续的。离散观测空间是有限的，智能体只能感知到固定数量的状态。连续观测空间是无限的，智能体可以感知到任意数量的状态。
<Alert icon={false}variant="filled"severity="info">
状态空间的设计需要考虑到系统的需求和可行性，需要经过多次试验和调整。例如，在游戏中，观测空间可能包括玩家的坐标、生命值、游戏时间等信息。在控制机器人的任务中，观测空间可能包括机器人的位置、速度、传感器读数等信息。在会计领域中，观测空间可能包括财务数据，如收入、成本、利润,公司的<strong>财务数据或市场数据</strong>等。
</Alert>
观测空间的大小和细节对智能体的学习有很大影响。观测空间过大可能导致智能体学习困难，而观测空间过小可能导致智能体学习不充分。因此，<strong>在设计强化学习任务时，需要考虑观测空间的合理性。</strong>
        <Alert icon={false}variant="outlined"severity="warning"className='mark'>
        The observation space defines the valid observations that the agent can receive from the environment. It can be defined as a discrete space with a fixed number of possible observations, or as a continuous space with a range of real-valued observations. For example, in a simple RL environment such as a cartpole, the observation space may include the cart's position, velocity, and pole's angle and angular velocity.
        </Alert>
  <ul><li>They clearly define how to interact with environments, i.e. they specify what actions need to look like and what observations will look like</li><li>They allow us to work with highly structured data (e.g. in the form of elements of Dict spaces) and painlessly transform them into flat arrays that can be used in learning code</li><li>They provide a method to sample random elements. This is especially useful for exploration and debugging.</li></ul>
  <h3>表示公司过去 3 个月的财务数据</h3>
  <Asset code={action_space_code5}/>
<h3>gym.spaces.Dict </h3>使用 gym.spaces.Dict 可以方便地把多个不同类型的空间组合在一起, observation_space 表示了公司过去 3 年的财务数据和行业数据，financial_data_space 和 industry_data_space 分别表示了财务数据和行业数据的空间，最终使用 gym.spaces.Dict 将它们组合在一起。
<Asset code={action_space_code6}/>
<Alert>Observation space refers to the set of all possible observations or outputs that an agent can receive in a Reinforcement Learning (RL) environment. The dimension of the observation space refers to the number of variables or features that can be observed at any given time. If the observation space has 8 dimensions, it means that there are 8 variables or features that can be observed at any given time in the RL environment.</Alert>
          </p>
        </TabPanel>
        <TabPanel value={value} index={1}>
        在会计强化学习中，<strong>行动空间是指模型可以在给定状态下采取的所有可能行动的集合。在会计任务中，行动空间可能包括不同的会计策略、预测方法、或决策。</strong>例如，在预测未来财务状况的任务中，行动空间可能包括使用不同的模型、调整模型参数、或使用不同的数据集。在其他任务中，行动空间可能包括选择不同的会计程序或遵循不同的会计准则。
        <br/>
        The action space defines the valid actions that the agent can take in the environment. It can be defined as a discrete space with a fixed number of possible actions, or as a continuous space with a range of real-valued actions. For example, in a cartpole environment, the action space may include only two possible actions, pushing the cart left or right.
        <Alert icon={false}variant="filled"severity="info">

        在会计学中，动作空间可能包括财务决策，如投资、贷款等。为了写出一个动作空间，我们需要考虑以下几点：
<ul><li>动作合理性：动作空间中的动作应该是合理的，并且应该与环境相关。例如，在研发决策环境中，投资于研发项目是一个合理的动
作，而玩电脑游戏则不是。
</li><li>动作可行性：动作空间中的动作应该是可行的。例如，在研发决策环境中，如果公司的财务状况不佳，投资于研发项目可能不可行。</li><li>动作相关性：动作空间中的动作应该是相关的。例如，在研发决策环境中，投资于研发项目和削减研发预算是相关的动作。
</li><li>动作数量：动作空间中的动作数量应该是适当的，不能过多也不能过少。
</li></ul>
根据以上要点，可以考虑编写一个研发决策环境的动作空间代码，包括投资于研发项目，削减研发预算，购买其他公司的技术等动
作，并设置相应的可行性和相关性限制。
        </Alert>
        <h3>action_space</h3>
        <Asset code={action_space_code}/>

        It is important to note that both observation_space and action_space could be of different types , it could be continuous, discrete, multi-discrete, box and etc. It depends on the problem you are trying to solve.
       <br/> 另一个例子是预测公司未来财务指标任务中，行动空间可能是连续的，其中每个行动都对应一个特定的预测值。

例如，在预测公司未来净利润任务中，行动空间可以定义为连续的实数区间。

在Python中，可以使用gym库定义连续行动空间，行动空间是一个连续的区间[0,1000000]，可以接受的预测值为[0,1000000]之间的实数，且预测值是单维的.如下所示
<h3>预测利润(连续)</h3>
<Asset code={action_space_code1}/>
行动空间可能是离散的，其中每个行动都对应一个特定的预测值。例如，在预测公司未来股票走势任务中，行动空间可以定义为离散的{'上涨','下跌','不变'}。在这个例子中，行动空间是{0,1,2}，对应的预测值为{'上涨','下跌','不变'},其中0,1,2可以通过一个映射函数转换为实际的预测值。
<h3>预测股价(离散)</h3>

<Asset code={action_space_code2}/>
可以用MultiDiscrete来定义多个离散的行动空间, 行动空间是二维的。
<h3>预测净利润和购买股票(多个离散)</h3>
<Asset code={action_space_code3}/>
gym.ActionWrapper 是 gym 库中的一种工具，可以用来包装原始的 action space 。这样可以在 agent 执行 action 时对其进行预处理或修改，或在 agent 接收到环境返回的结果时对其进行后处理。
假设有一个 agent，它需要预测公司未来的净利润。我们可以使用 gym.ActionWrapper 将 agent 所预测的净利润限制在一定范围内，如每年不能超过100万美元。
<h3>gym.ActionWrapper</h3>
<Asset code={action_space_code4}/>

        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Box>
    );
  }

  export const Space = () => (
    <div>
             <Title> Space <Rating name="read-only" value={4} readOnly /></Title>
       <NavReinforcementlearning /> 
       <NavSpace /> 
    </div>
  );

  export const NavStep = () =>{
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>
  
        <Tabs
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        >
          <Tab label=
                 {
                  <>
                    <Typography variant="caption">
                    step function
                    </Typography>
                    <Typography variant="caption">
                    step函数
                    </Typography>
                  </>
               }
         {...a11yProps(0)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  next observation
                                  </Typography>
                  <Typography variant="caption">
                 下一个状态
                  </Typography>
                </>
             }
          {...a11yProps(1)} />
          <Tab label=
             {
              <>
                <Typography variant="caption">
               reward
                                </Typography>
                <Typography variant="caption">
                奖励
                </Typography>
              </>
           }
          {...a11yProps(2)} />
          <Tab label=
              {
                <>
                  <Typography variant="caption">
                  terminal state
                                  </Typography>
                  <Typography variant="caption">
                 终止状态
                  </Typography>
                </>
             }
          {...a11yProps(3)} />
          <Tab label=
           {
            <>
              <Typography variant="caption">
              commodity
             </Typography>
              <Typography variant="caption">
              商品
              </Typography>
            </>
         }
           {...a11yProps(4)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  stock
                 </Typography>
                  <Typography variant="caption">
                  股票
                  </Typography>
                </>
             }
          {...a11yProps(5)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  bond
                 </Typography>
                  <Typography variant="caption">
                  债券
                  </Typography>
                </>
             }
        {...a11yProps(6)} />
        </Tabs>
        <TabPanel value={value} index={0}>
        <p >
        Step函数是强化学习中的一个核心函数，它定义了智能体在环境中采取行动后所产生的奖励。在具体实现中，step函数通常会返回四个值：下一个状态，奖励，是否终止，其他信息。
        <br />
        在会计领域中使用强化学习时，step方法通常会return以下几个值：
<ul><li>observation：当前状态的观察值。这可能是一个数字，向量或图像，具体取决于环境的观察
空间。</li><li>reward：根据当前状态和所采取的动作获得的奖励。这可能是一个数字，向量或标量。
</li><li>done：表示当前环境是否已经终止。如果done为True，则环境被视为已经完成并且不能再进
行任何更多的操作。</li><li>info：一些附加信息，如诊断信息或附加统计信息，可能用于调试或监视环境。</li></ul>
具体而言，在会计领域中，可以设计不同的环境，例如预算环境、财务预测环境等，在其中观察值可能是公司的财务数据，奖励可能是根据公司的财务状况进行评估，done可能是根据公司的目标是否实现进行判断，附加信息可能是公司的财务预测结果。
        <Alert icon={false}variant="outlined"severity="warning"className='mark'>
        强化学习算法通过不断地重复这个过程来学习如何在环境中采取最优策略。具体来说，通过不断地调整智能体的策略来尝试提高奖励。这个过程需要经过多次尝试和错误才能找到最优策略。

在实现强化学习算法时，step函数通常是由环境提供的，它负责模拟环境中的物理过程和智能体与环境之间的交互。智能体可以通过调用step函数来获取环境中当前状态并采取行动。
在实际应用中，step函数的具体实现可能会有所不同。例如，在模拟环境中，step函数可能会根据智能体采取的行动更新环境状态，并计算出相应的奖励。而在真实世界中，step函数可能会直接读取传感器数据来获取环境状态，并将智能体的行动发送给机器人控制器执行。
        </Alert>
          </p>
        </TabPanel>
        <TabPanel value={value} index={1}>
        在强化学习中，step函数返回的下一个状态是指智能体在环境中采取行动后所处的状态。这个状态通常是由环境模拟器更新并返回的。在具体实现中，下一个状态可能是一个数值型向量，表示环境中各种物理量的状态，也可能是图像或其他多维数据类型。这取决于环境的具体实现和强化学习算法的需求。

在强化学习中，智能体要根据当前状态和获得的奖励来决策下一步的行动，并通过不断重复这个过程来学习如何在环境中采取最优策略。下一个状态是智能体在环境中行动后的结果，是下一步行动决策的重要依据。
<Alert   icon={false} variant="outlined" severity="info" className='mark'>
举个例子，假设我们有一个简单的强化学习环境，其中智能体控制着一个机器人在迷宫中移动。step函数的输入为当前状态(当前位置，机器人的当前状态)和行动（向上，向下，向左或向右移动），输出为下一个状态(机器人移动后的位置，机器人的新状态)和奖励(到达终点奖励为1，其他情况为0)。
在这个例子中，当智能体选择向上移动时，step函数会更新机器人的位置并返回新的状态(x-1,y)和奖励(如果到达终点奖励为1，否则为0)。之后，智能体可以根据新状态和奖励来决策下一步的行动。</Alert>
        </TabPanel>
        <TabPanel value={value} index={2}>
        在强化学习中，<strong>奖励是指智能体在环境中采取行动后所得到的数值反馈。这个数值反馈是用来评估智能体行动的优劣，并为智能体学习最优策略提供目标</strong>。

在step函数中，奖励是由环境模拟器根据当前状态和采取的行动来计算的。奖励的计算可能取决于环境的具体实现和强化学习算法的需求。常见的奖励机制包括：<ul>
<li>
固定奖励：在某些特定状态或执行特定行动后给予固定数值的奖励。
</li><li>
差分奖励：在执行行动后给予与当前状态相对应的奖励，而不是终止状态。
</li></ul>
举个例子，在迷宫中，可能会设置奖励机制，达到终点奖励为1，其他状态奖励为0。这样智能体就会学习走到终点而不是其他地方。

总而言之，奖励是强化学习中非常重要的部分，是智能体学习最优策略的重要依据。     
<Alert icon={false}variant="outlined"severity="warning"className='mark'>

 Reward is an incentive for an agent to learn a better policy. For example, it can be the change of the portfolio value when taking a at state s and arriving at new state s',  i.e., r(s, a, s′) = v′ − v, where v′ and v represent the portfolio values at state s′ and s, respectively
 </Alert>
   </TabPanel>
        <TabPanel value={value} index={3}>
        在会计强化学习中，终止状态通常指完成会计任务或达到目标所需的最终状态。例如，在预测未来财务状况的任务中，终止状态可能是预测出的未来财务状况。在其他任务中，终止状态可能是完成特定的会计程序或达到特定的会计准则。
  </TabPanel>
      </Box>
    );
  }
  export const Step = () => (
    <div>
             <Title> Step <Rating name="read-only" value={5} readOnly /></Title>
       <NavReinforcementlearning /> 
       <NavStep /> 
    </div>
  );

  export const NavAgent = () =>{
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>
  
        <Tabs
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        >
          <Tab label=
                 {
                  <>
                    <Typography variant="caption">
                    agent-environment loop
                    </Typography>
                    <Typography variant="caption">
                    交互
                    </Typography>
                  </>
               }
         {...a11yProps(0)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  timestep
                                  </Typography>
                  <Typography variant="caption">
                  一次循环
                  </Typography>
                </>
             }
          {...a11yProps(1)} />
          <Tab label=
             {
              <>
                <Typography variant="caption">
               reward
                                </Typography>
                <Typography variant="caption">
                奖励
                </Typography>
              </>
           }
          {...a11yProps(2)} />
          <Tab label=
              {
                <>
                  <Typography variant="caption">
                  Monopolistic Competition
                                  </Typography>
                  <Typography variant="caption">
                  垄断竞争
                  </Typography>
                </>
             }
          {...a11yProps(3)} />
          <Tab label=
           {
            <>
              <Typography variant="caption">
              commodity
             </Typography>
              <Typography variant="caption">
              商品
              </Typography>
            </>
         }
           {...a11yProps(4)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  stock
                 </Typography>
                  <Typography variant="caption">
                  股票
                  </Typography>
                </>
             }
          {...a11yProps(5)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  bond
                 </Typography>
                  <Typography variant="caption">
                  债券
                  </Typography>
                </>
             }
        {...a11yProps(6)} />
        </Tabs>
        <TabPanel value={value} index={0}>
        <p >
        <h3>定义智能体的行为</h3>
        在会计领域中，强化学习的agent-environment loop可以用来解决各种类型的决策问题。这种循环包括以下步骤：
<ol><li>智能体（agent）根据当前状态（observation）采取动作（action）。</li><li>环境（environment）根据智能体的动作和内部规则，更新状态并给予奖励（reward）。
</li><li>智能体根据环境的反馈和自身的策略，更新自己的决策策略。
</li></ol>
智能体类主要有三种方法：
<ul><li><b>get_action</b>：使用传递的ε值，智能体决定是使用随机操作，还是从网络输出中执行Q值最高的操作。</li><li><b>play_step</b>：在这里，智能体通过从get_action中选择的操作在环境中执行一个步骤。从环境中获得反馈后，经验将存储在重播缓冲区中。如果环境已完成该步骤，则环境将重置。最后，返回当前的奖励和完成标志。</li><li><b>reset</b>：重置环境并更新存储在代理中的当前状态。</li></ul>
<Alert icon={false}variant="filled"severity="info">

在会计领域中，agent可以是会计系统或其他财务决策系统,agent可以通过强化学习来优化资产负债表或损益表的预测，或者优化财务指标的预测。
</Alert>
强化学习的agent-environment loop可以帮助会计系统和其他财务决策系统通过不断学习和优化来提高决策质量。
          </p>
        </TabPanel>
        <TabPanel value={value} index={1}>
        在会计领域中，timestep表示强化学习算法进行决策的顺序。每个timestep都对应着agent-environment loop中的一个循环。

在会计领域中，timestep可以是时间上的一个单位，如天、月、年等。
例如，假设一个公司的财务数据每天更新一次，那么每天都是一个timestep。在每个timestep中，agent会根据当前的财务数据和自身的策略来采取动作，环境会根据agent的动作来更新状态并给予奖励。
        </TabPanel>
        <TabPanel value={value} index={2}>
        在强化学习中，<strong>奖励是指智能体在环境中采取行动后所得到的数值反馈。这个数值反馈是用来评估智能体行动的优劣，并为智能体学习最优策略提供目标</strong>。

在step函数中，奖励是由环境模拟器根据当前状态和采取的行动来计算的。奖励的计算可能取决于环境的具体实现和强化学习算法的需求。常见的奖励机制包括：<ul>
<li>
固定奖励：在某些特定状态或执行特定行动后给予固定数值的奖励。
</li><li>
差分奖励：在执行行动后给予与当前状态相对应的奖励，而不是终止状态。
</li></ul>
举个例子，在迷宫中，可能会设置奖励机制，达到终点奖励为1，其他状态奖励为0。这样智能体就会学习走到终点而不是其他地方。

总而言之，奖励是强化学习中非常重要的部分，是智能体学习最优策略的重要依据。        </TabPanel>
      </Box>
    );
  }

  export const Agent = () => (
    <div>
             <Title> Step<Rating name="read-only" value={4} readOnly /></Title>
       <NavReinforcementlearning /> 
       <NavAgent/> 
    </div>
  );

  export const NavLoss = () =>{
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>
  
        <Tabs
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        >
          <Tab label=
                 {
                  <>
                    <Typography variant="caption">
                    MSE
                    </Typography>
                    <Typography variant="caption">
                    均方误差
                    </Typography>
                  </>
               }
         {...a11yProps(0)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  Huber Loss
                                  </Typography>
                  <Typography variant="caption">
                  </Typography>
                </>
             }
          {...a11yProps(1)} />
          <Tab label=
             {
              <>
                <Typography variant="caption">
               Q value Loss
                                </Typography>
                <Typography variant="caption">
                基于 Q 值的损失函数
                </Typography>
              </>
           }
          {...a11yProps(2)} />
          <Tab label=
              {
                <>
                  <Typography variant="caption">
                  Monopolistic Competition
                                  </Typography>
                  <Typography variant="caption">
                  垄断竞争
                  </Typography>
                </>
             }
          {...a11yProps(3)} />
          <Tab label=
           {
            <>
              <Typography variant="caption">
              commodity
             </Typography>
              <Typography variant="caption">
              商品
              </Typography>
            </>
         }
           {...a11yProps(4)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  stock
                 </Typography>
                  <Typography variant="caption">
                  股票
                  </Typography>
                </>
             }
          {...a11yProps(5)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  bond
                 </Typography>
                  <Typography variant="caption">
                  债券
                  </Typography>
                </>
             }
        {...a11yProps(6)} />
        </Tabs>
        <TabPanel value={value} index={0}>
        <p >
        MSE (Mean Squared Error)：该损失函数使用预测值与真实值的均方误差计算差距：
        <Asset code={loss_function_code1}/>
          </p>
        </TabPanel>
        <TabPanel value={value} index={1}>
        Huber Loss：该损失函数是一种折中的损失函数，在偏差较小时计算 MSE，而在偏差较大时计算 MAE (Mean Absolute Error)：     <Asset code={loss_function_code2}/>   </TabPanel>
        <TabPanel value={value} index={2}>
        对于强化学习任务，最常见的损失函数是基于 Q 值的损失函数，例如 DQN 模型使用的损失函数：
        <Asset code={loss_function_code3}/>

                 </TabPanel>
      </Box>
    );
  }
  export const Loss = () => (
    <div>
             <Title> Loss function<Rating name="read-only" value={5} readOnly /></Title>
       <NavReinforcementlearning /> 
       <NavLoss/> 
    </div>
  );
  export const NavAction = () =>{
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>
  
        <Tabs
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        >
          <Tab label=
                 {
                  <>
                    <Typography variant="caption">
                    agent-environment loop
                    </Typography>
                    <Typography variant="caption">
                    交互
                    </Typography>
                  </>
               }
         {...a11yProps(0)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  timestep
                                  </Typography>
                  <Typography variant="caption">
                  一次循环
                  </Typography>
                </>
             }
          {...a11yProps(1)} />
          <Tab label=
             {
              <>
                <Typography variant="caption">
               policy
                                </Typography>
                <Typography variant="caption">
                策略
                </Typography>
              </>
           }
          {...a11yProps(2)} />
          <Tab label=
              {
                <>
                  <Typography variant="caption">
                  Monopolistic Competition
                                  </Typography>
                  <Typography variant="caption">
                  垄断竞争
                  </Typography>
                </>
             }
          {...a11yProps(3)} />
          <Tab label=
           {
            <>
              <Typography variant="caption">
              commodity
             </Typography>
              <Typography variant="caption">
              商品
              </Typography>
            </>
         }
           {...a11yProps(4)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  stock
                 </Typography>
                  <Typography variant="caption">
                  股票
                  </Typography>
                </>
             }
          {...a11yProps(5)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  bond
                 </Typography>
                  <Typography variant="caption">
                  债券
                  </Typography>
                </>
             }
        {...a11yProps(6)} />
        </Tabs>
        <TabPanel value={value} index={0}>
        <p >
        First of all, you can't simply expect an RL CPA to do everything for you and just sit back on your chair in such complex Business world! Things need to be simplified as much as possible in order to let the agent learn in a faster and more efficient way. In all trading algorithms, the first thing that should be done is to define <strong>actions</strong> and positions. 
        <br/>
        Trading Actions

If you search on the Internet for trading algorithms, you will find them using numerous actions such as Buy, Sell, Hold, Enter, Exit, etc. Referring to the first statement of this section, a typical RL agent can only solve a part of the main problem in this area. If you work in trading markets you will learn that deciding whether to hold, enter, or exit a pair (in FOREX) or stock (in Stocks) is a statistical decision depending on many parameters such as your budget, pairs or stocks you trade, your money distribution policy in multiple markets, etc. It's a massive burden for an RL agent to consider all these parameters and may take years to develop such an agent! In this case, you certainly will not use this environment but you will extend your own.

So after months of work, I finally found out that these actions just make things complicated with no real positive impact. In fact, they just increase the learning time and an action like Hold will be barely used by a well-trained agent because it doesn't want to miss a single penny. Therefore there is no need to have such numerous actions and only <strong>Sell=0 and Buy=1 actions </strong>are adequate to train an agent just as well.
        在会计强化学习中，reset方法还可以用于重置智能体的策略或模型参数，使其能够重新学习并适应新的环境。重置参数可以使智能体能够更快地学习新的策略，并且不会受到之前学习的影响。这对于模拟复杂的财务环境非常重要，因为市场环境可能会发生变化，需要智能体进行调整。
          </p>
        </TabPanel>
        <TabPanel value={value} index={1}>
        在会计领域中，timestep表示强化学习算法进行决策的顺序。每个timestep都对应着agent-environment loop中的一个循环。

在会计领域中，timestep可以是时间上的一个单位，如天、月、年等。
例如，假设一个公司的财务数据每天更新一次，那么每天都是一个timestep。在每个timestep中，agent会根据当前的财务数据和自身的策略来采取动作，环境会根据agent的动作来更新状态并给予奖励。
        </TabPanel>
        <TabPanel value={value} index={2}>
        在会计强化学习中，
        <b>deterministic指的是策略或决策是确定的，即在给定状态下，策略总是产生相同的动作。</b>这与随机策略不同，随机策略可能在相同状态下产生不同的动作。
        
        <br />
        <br />
        举个例子，在一个智能财务管理系统中，deterministic策略可能是在给定的预算限制条件下，始终选择最高回报率的投资组合。而随机策略可能是在给定的预算限制条件下，随机选择其中一个回报率较高的投资组合。
<br />
        <b>OUNoise</b>
        <br />
        Ornstein-Uhlenbeck (OU) noise is a type of stochastic process that 
can be used to add randomness to an agent's actions in Reinforcement Learning (RL) 
environments. It is a Gaussian process that is mean-reverting, meaning that it moves 
toward a fixed mean over time. This property allows OU noise to simulate the random 
exploration in an environment while still maintaining some sense of direction.

In the context of RL, adding OU noise to the actions of an agent can help to ensure that it 
explores the environment and doesn't get stuck in a sub-optimal policy.
    </TabPanel>
      </Box>
    );
  }
  export const Action = () => (
    <div>
             <Title> Action<Rating name="read-only" value={5} 
 readOnly /></Title>
       <NavReinforcementlearning /> 
       <NavAction/> 
    </div>
  );

  export const Value = () => (
    <div>
             <Title> Value Function<Rating name="read-only" value={4} readOnly /></Title>
             <p className="philosophy-text">             <p className="philosophy-text"></p>
            <b>价值函数 (value function) </b> 是一种用来评估一个状态或状态-动作对的期望回报的函数。
对于一个确定的状态 S，价值函数 V(S) 表示从该状态开始采取最优策略所能获得的最大总奖励。

对于一个确定的状态-动作对 (S,A)，价值函数 Q(S,A) 表示从该状态采取给定动作 A 并采取最优策略所能获得的最大总奖励。

通过不断优化价值函数来更新策略，使智能体能够在环境中获得最大回报。</p>  
<br />
<p className="philosophy-text">Q(S,A) 和 V(S) 是两个不同的价值函数，它们在强化学习中都有重要作用。

Q(S,A) 是状态-动作价值函数，表示在状态 S 采取动作 A 并采取最优策略所能获得的最大总收益。

V(S) 是状态价值函数，表示在状态 S 采取最优策略所能获得的最大总收益。

Q(S,A) 和 V(S) 之间有如下关系：

V(S) = max(Q(S,A))

也就是说，状态价值函数 V(S) 等于在状态 S 采取所有动作中 Q(S,A) 的最大值。

因此，我们可以通过计算状态-动作价值函数 Q(S,A) 来得到状态价值函数 V(S)，然后再根据状态价值函数来确定最优策略。</p>
 </div>
  );
  export const Dqn = () => (
    <div>
             <Title> Deep Q-learning<Rating name="read-only" value={4} readOnly /
></Title>
<NavTrain/>
<Alert>强化学习算法从算法的结构可以分为三大类，仅仅训练价值网络的DQN、仅仅训练策略网络的VPG，还有同时训练价值网络与策略网络Actor-Critic算法。</Alert>

DQN是深度强化学习中的一种算法，主要用于解决强化学习中的控制问题。它是一种基于Q-learning的算法，但使用了深度神经网络来进行价值估计。 DQN在会计领域中的应用可能是在财务预测、风险管理等方面。
<br />
<br />DQN (Deep Q-Network) 中的最优化行动是指，通过对智能体（Agent）在环境中的状态和行动的评估，选择最有价值的行动，使其能够最大化期望的奖励。

DQN 通过强化学习算法训练一个深度神经网络（称为 Q-Network），来预测在给定状态下采取每个可能行动的期望奖励，最后选择具有最大期望奖励的行动作为最优行动。
<h3>Task</h3>
<p className="philosophy-text">
As the accounting-agent observes the current state of the environment and chooses an action, the environment transitions to a new state, and also returns a reward that indicates the consequences of the action. In this task, rewards are +1 for every incremental timestep and the environment terminates if the pole falls over too far or the cart moves more than 2.4 units away from center. This means better performing scenarios will run for longer duration, accumulating larger return.
<br />
<br />
We take inputs without any scaling and pass them through a small <strong>fully-connected network with 2 outputs, one for each action. The network is trained to predict the expected value for each action, given the input state. </strong>
The action with the highest expected value is then chosen.
</p>

<h3>Package</h3>
PyTorch:
<ul><li>neural networks (torch.nn)</li><li>optimization (torch.optim)
</li><li>automatic differentiation (torch.autograd)
</li></ul>
<h3>Replay Memory经验回放</h3>
<p className="philosophy-text">
We’ll be using experience replay memory for training our DQN. It stores the transitions that the agent observes, allowing us to reuse this data later. By sampling from it randomly, the transitions that build up a batch are decorrelated. It has been shown that this greatly stabilizes and improves the DQN training procedure.</p>

    </div>
  );


  export const Nav_Actor_Critic = () =>{
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 1,}}>
  
        <Tabs
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        >
          <Tab label=
                 {
                  <>
                    <Typography variant="caption">
                    PPO
                    </Typography>
                    <Typography variant="caption">
                    交互
                    </Typography>
                  </>
               }
         {...a11yProps(0)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
DDPG                                  </Typography>
                  <Typography variant="caption">
                  Deep Deterministic Policy Gradient
                  </Typography>
                </>
             }
          {...a11yProps(1)} />
          <Tab label=
             {
              <>
                <Typography variant="caption">
A3C                                </Typography>
                <Typography variant="caption">
                策略
                </Typography>
              </>
           }
          {...a11yProps(2)} />
          <Tab label=
              {
                <>
                  <Typography variant="caption">
TRPO                                  </Typography>
                  <Typography variant="caption">
                  垄断竞争
                  </Typography>
                </>
             }
          {...a11yProps(3)} />
          <Tab label=
           {
            <>
              <Typography variant="caption">
              commodity
             </Typography>
              <Typography variant="caption">
              商品
              </Typography>
            </>
         }
           {...a11yProps(4)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  stock
                 </Typography>
                  <Typography variant="caption">
                  股票
                  </Typography>
                </>
             }
          {...a11yProps(5)} />
          <Tab label=
               {
                <>
                  <Typography variant="caption">
                  bond
                 </Typography>
                  <Typography variant="caption">
                  债券
                  </Typography>
                </>
             }
        {...a11yProps(6)} />
        </Tabs>
        <TabPanel value={value} index={0}>
<p className="philosophy-text">Actor-Critic Methods 是强化学习的一种方法，它结合了 Actor-based 和 Critic-based 的思想。

在 Actor-based 方法中，智能体通过学习一个策略函数，来决定如何选择行动。这个策略函数告诉智能体如何选择动作，从而控制环境的状态。

在 Critic-based 方法中，智能体通过学习一个值函数，来预测对于给定状态，采取给定动作后的期望回报。

Actor-Critic 方法通过同时学习策略函数和值函数，并且把这两者结合在一起，从而更好地解决强化学习问题。

在 Actor-Critic 方法中，Actor 学习如何选择动作，Critic 学习如何评估策略的价值，并将评估结果反馈给 Actor，调整它的行为。通过这种方式，Actor-Critic 方法可以更快地学习优秀的策略。
<br /><br />
DQN (Deep Q-Network) 是一种强化学习算法，属于 Critic-based 方法。

DQN 算法通过使用神经网络，来学习一个价值函数，该价值函数预测当前状态下采取各个可能动作后的回报期望值。

Actor-Critic Methods 和 DQN 的区别在于 Actor-Critic Methods 同时学习策略函数和价值函数，而 DQN 只学习价值函数。

但是，在实际的强化学习应用中，DQN 经常和 Actor-Critic Methods 一起使用，以提高学习效率。因为 DQN 是一种 Critic-based 的算法，它可以预测状态的价值，并通过评估状态的价值，帮助 Actor-Critic Methods 算法更快地学习优秀的策略。
</p>
<h3>DDPG</h3>
        </TabPanel>
        <TabPanel value={value} index={1}>
        DDPG (Deep Deterministic Policy Gradients) 是一种强化学习算法，它是基于 DQN (Deep Q-Network) 算法的改进版本，专门用于处理连续动作空间的任务。DDPG 将 DQN 中的离散动作空间转化为连续动作空间，并使用神经网络对策略进行建模。DDPG 通过学习一个基于状态的策略，以选择动作并最大化累积奖励，来解决强化学习任务。
        </TabPanel>
        <TabPanel value={value} index={2}>
        在会计强化学习中，
        <b>deterministic指的是策略或决策是确定的，即在给定状态下，策略总是产生相同的动作。</b>这与随机策
同，随机策略可能在相同状态下产生不同的动作。
        <br />
        <br />
        举个例子，在一个智能财务管理系统中，deterministic策略可能是在给定的预算限制条件下，始终选择最高
投资组合。而随机策略可能是在给定的预算限制条件下，随机选择其中一个回报率较高的投资组合。
    </TabPanel>
      </Box>
    );
  }
  export const Actor_Critic = () => (
    <div>
     <Title> Actor_Critic method<Rating name="read-only" value={4} readOnly /></Title>
      <NavTrain/>
      <Nav_Actor_Critic/>
    </div>
  );


