export const action_space_code = `
~~~python
# 离散
action_space = ['IFRS', 'US-GAAP', 'UK-GAAP']

# 连续
action_space = np.array([-1, 1]) 
~~~
`
export const action_space_code1 = `
~~~python
import gym
from gym import spaces
action_space = spaces.Box(low=np.array([0]), high=np.array([1000000]), dtype=np.float32)
~~~
`
export const action_space_code2 = `
~~~python
action_space = spaces.Discrete(3)
~~~
`
export const action_space_code3 = `
~~~python
# 第一维{0,1,2}对应购买哪只股票，第二维{0,1,2,3}对应购买几股。
action_space = spaces.MultiDiscrete([[0,1,2], [0,1,2,3]])

# 第一维{-10, 0, 10}对应净利润的预测值，第二维{-20, 0, 20}对应现金流量的预测值。
action_space = spaces.MultiDiscrete([[-10, 0, 10], [-20, 0, 20]])
~~~
`
export const action_space_code4 = `
~~~python
# 使用 gym.Discrete 定义了一个可能的净利润范围为 [-2000000,2000000] 的离散行动空间。
# 然后使用 ProfitWrapper 包装了这个行动空间，限制了净利润不能超过100万美元，
# 限制 agent 的行为，防止其在训练或实际应用中做出不合理的决策。
class ProfitWrapper(gym.ActionWrapper):
    def action(self, action):
        #限制净利润不能超过100万美
        # 
        if action > 1000000:
            action = 1000000
        elif action < -1000000:
            action = -1000000
        return action

action_space = spaces.Discrete(2000000)
action_space = ProfitWrapper(action_space)
~~~
`
export const action_space_code5 = `
~~~python
import gym
import numpy as np
# 定义 observation_space
# observation_space 表示了公司过去三个月的现金流量
# low 和 high 分别表示了数据的下限和上限。
# 这里使用 gym.spaces.Box 表示一个连续的空间，因此，这个空间中的数据可以是任何实数。
low = np.array([-np.inf, -np.inf, -np.inf])
high = np.array([np.inf, np.inf, np.inf])
observation_space = gym.spaces.Box(low, high, dtype=np.float32)
~~~
`
export const action_space_code6 = `
~~~python
import gym
import numpy as np

# 定义财务数据的 observation_space
low = np.array([0,0,0])
high = np.array([np.inf,np.inf,np.inf])
financial_data_space = gym.spaces.Box(low, high, dtype=np.float32)

# 定义行业数据的 observation_space
low = np.array([0])
high = np.array([1])
industry_data_space = gym.spaces.Box(low, high, dtype=np.float32)

# 使用Dict将多个空间组合在一起
observation_space = gym.spaces.Dict({"financial_data": financial_data_space, "industry_data": industry_data_space})
~~~
`
export const loss_function_code1 = `
~~~python
def mse_loss(y_pred, y_true):
    return ((y_pred - y_true) ** 2).mean()
~~~
`
export const loss_function_code2 = `
~~~python
def huber_loss(y_pred, y_true, delta=1.0):
    diff = y_pred - y_true
    loss = torch.where(diff.abs() < delta, 0.5 * diff ** 2, delta * (diff.abs() - 0.5 * delta))
    return loss.mean()
~~~
`
export const loss_function_code3 = `
~~~python
loss = (reward + discount_factor * max(Q(next_state, action)) - Q(state, action)) ** 2
~~~
`
export const markov_code = `
~~~python
import numpy as np
# Define the transition matrix
transition_matrix = np.array([[0.7, 0.3], [0.4, 0.6]])
~~~
`