import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 
'recharts';
const data = [{name: '👩', 重点: 400, 字数: 2400, amt: 2400},{name: '正文', 重点: 1400, 字数: 3400, amt: 4400},
{name: '参考文献', 重点: 433, 字数: 222, amt: 222}];
const Tubiao = () =>{
    return(
        <div>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="字数" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="重点" stroke="#82ca9d" />
        </LineChart>
    </div>
    )
}
export default Tubiao