import React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { BasicGrid  } from './Exapmle';
import {  NavAccount } from './Document';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const Install = () => (
    <div>
 Install

    </div>
  );
export const Learn = () => (
    <div>
 教程
 
    </div>
  );  
export const Example = () => (
    <div>
 <BasicGrid />
    </div>
  );
export const Documentation = () => (
    <div>
 <NavAccount />

    </div>
  );