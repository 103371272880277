import React ,{useState} from 'react';
import styled from 'styled-components';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import {SidebarData} from "./SidebarData";
import {SubMenu} from "./SubMenu"
import { IconContext } from 'react-icons';
import {NavHome} from "../pages/Nav";
import TextField from "@mui/material/TextField";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

function filterNestedList(list, keyword) {
  let filteredList = [];
  list.forEach(item => {
    if (typeof item === "object") {
      for (let key in item) {
        if (typeof item[key] === "string" && item[key].includes(keyword)) {
          filteredList.push(item);
          break;
        } else if (typeof item[key] === "object") {
          filteredList = filteredList.concat(filterNestedList([item[key]], keyword));
        }
      }
    }
  });
  return filteredList;
}
const SearchBar = ({setSearchQuery}) => (
  <form>
    <TextField
      id="search-bar"
      className="text"
      onInput={(e) => {
        setSearchQuery(e.target.value);
      }}
      variant="standard"
      placeholder="Search..."
      size="small"
      fullWidth
      type="search"
      sx={{ input: { color: 'white' } }}
      
    />
  </form>
);
const filterData = (query, data) => {
  if (!query) {
    return data;
  } else {
    return filterNestedList(data, query);
  }
};
const Nav = styled.div`
  background: #000080;
  height: 60px;
  width:100%
  overflow: auto;
`;
const NavIcon = styled(Link)`
margin-left: 2rem;
margin-top: 0.9rem;

font-size:2rem;
`
const SidebarNav = styled.nav`
background: #000080;
width:230px;
height:90%;
display:flex;
justify-content: center;
position:fixed;
top:0;
margin-top:68px;
left:${({sidebar}) => (sidebar ? '8px' : '-100%')};
transition: 1ms;
z-index:10;
overflow-y: scroll;
`
const SidebarWrap = styled.div`
width:100%;
`
const Sidebar = () => {
  const [sidebar,setSidebar] = useState(false)
  const showSidebar = () => setSidebar(!sidebar)
  const [searchQuery, setSearchQuery] = useState("");
const dataFiltered = filterData(searchQuery, SidebarData);
  return (
    <>
    <IconContext.Provider value={{ color: '#fff'}} >
    <Nav  >
      <NavIcon to="#" className="item1" >
      <FaIcons.FaBars onClick={showSidebar}/>
      </NavIcon>

      <NavHome className="item"/>

      <div className="container">


      </div>
    </Nav>

    <SidebarNav sidebar={sidebar}>
<SidebarWrap>
<SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
{dataFiltered.map((item,index) => {
  return <SubMenu item={item}key={index} />;
  }
  )
  }
</SidebarWrap>
    </SidebarNav>
    </IconContext.Provider>
    </>
  )
}

export default Sidebar;
