import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardMedia ,CardActionArea} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function BasicCard(props){
  return (
    <Card variant={props.style} sx={{ width: 350 ,margin:1}} elevation={18} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {props.text1}
        </Typography>
        <Typography variant="h5" component="div">
        {props.text2}
                </Typography>
                <br />
        <Typography sx={{ mb: -1.5 }} color="text.secondary">
        {props.text3}
        </Typography>
        <Typography variant="body2">
        {props.text4}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="big" href={props.url}>{props.button}</Button>
      </CardActions>
    </Card>
  );
}
export function GraphCard(props){
  return (
<Card variant={props.style} sx={{ width: 350 ,margin:1}}elevation={18}>
  <CardMedia
    component="img"
    height="140"
    image={props.img}
    alt="green iguana"
  />
  <CardContent>
    <Typography gutterBottom variant="h5" component="div">
    {props.title}
    </Typography>
    <Typography variant="body2" color="text.secondary">
{props.content}
     </Typography>
  </CardContent>
  <CardActions>
    <Button size="big" href={props.url}>{props.button}</Button>
  </CardActions>
</Card>
  );
}
export function StreamlitCard(props){
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
<Card variant={props.style} sx={{ margin:1,position: 'relative'}}elevation={18}>
<CardActionArea href={props.url}>
<Typography gutterBottom variant="h5" component="div">
{props.content}
     </Typography>
  <CardMedia
    component="img"
    height="200"
    image={props.img}
    alt="green iguana"
  />
  <CardContent>
  <div style={{display: 'flex'}}>
  <Typography variant="body2" color="text">
算法：  
</Typography>
    <Typography variant="body2" color="text.secondary">
    {props.title}
    </Typography>
    </div>
    <div style={{display: 'flex'}}>
  <Typography variant="body2" color="text">
数据：  
</Typography>
    <Typography variant="body2" color="text.secondary">
    {props.data}
    </Typography>
    </div>
  </CardContent>
  </CardActionArea>
  <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>{props.detail}</Typography>
        </CardContent>
      </Collapse>
</Card>
  );
}