import React,{useState}from 'react';
import { Link } from "react-router-dom"
import styled from 'styled-components'
const SidebarLink = styled(Link)`
text-decoration: none;
display: flex;
color:#e1e9fc;
justify-content:space-between;
padding-left:2px;
padding-top:4px;
margin-top: 3px;
list-style:none;
font-size:16px;
&:hover{
    background:#808080;
    cursor:pointer;

}
`;
const SidebarLabel = styled.span`
margin-left: 2px;
margin-top: 2px;
margin-bottom:11px;
`;
const DropdownLink = styled(Link)`
text-decoration: none;
background: #414757;
padding-left: 1rem;
padding-top: 3px;
font-size:15px;
display:flex;
align-item:center;
color:#f5f5f5;
&:hover{
    background:#808080;
    cursor:pointer;

}
`
export const SubMenu = ({ item }) => {
    const [subnav,setSubnav] = useState(false)
    const showSubnav = () => setSubnav(!subnav)
    return(
        <div>
        <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <div>
            <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
      {item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}
        </div>
        </SidebarLink>
        {subnav && item.subNav.map((item,index) => {
            return(
                <DropdownLink to={item.path} key={index}>
                    <SidebarLabel>{item.title}</SidebarLabel>
                    </DropdownLink>
            )
        }
        )
        }
        </div>
        );
};
