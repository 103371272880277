import React from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import {Title} from "./Overview";
import Rating from '@mui/material/Rating';

export const Redefine = () => (
    <div>
        <Title>Redefine Accounting重定义会计<Rating name="read-only" value={4} readOnly /></Title>

      <Alert  icon={false} variant="outlined" severity="info" className="mark">
        <h3>
      Accounting has both horizontal and vertical classification. Horizontally, it is classified as assets, liabilities, and equity. Vertically, it is classified based on the type of data, such as date, string, and numbers. Redefining accounting may refer to updating or changing accounting principles, guidelines, or standards or rethinking the definition and purpose of accounting. Accounting is a profession that aims to record, compile and report on financial information related to an organization. It is widely used in business, government, and non-profit organizations. Accounting principles, guidelines, and standards are the foundation of accounting, used to determine how to record and report financial information. As the economic environment and accounting technology changes, accounting principles, guidelines, and standards also continuously update and change. Additionally, the definition and purpose of accounting may also change. For example, accounting may be viewed as a tool to assist managers in making decisions or as a channel to provide information to the outside world. Redefining accounting may mean rethinking the goals and meaning of accounting and adjusting it based on current needs and expectations.</h3>
      </Alert>
      会计有横向的分类和纵向的分类，横向的是资产、负债、所有者权益，纵向的是这些数据的类型，比如日期，字符串、数字等。
    重新定义会计可能指的是更新或改变会计原则、准则或标准，或者是重新思考会计的定义和目的。会计是一门专业，旨在记录、编制和报告有关组织财务信息的活动。它被广泛应用于商业、政府和非营利组织。会计原则、准则和标准是会计的基础，用于确定如何记录和报告财务信息。随着经济环境和会计技术的变化，会计原则、准则和标准也会不断更新和改变。此外，对会计的定义和目的也可能会发生改变。例如，会计可能被视为旨在帮助管理者决策的工具，或者被视为向外界提供信息的渠道。重新定义会计可能意味着重新思考会计的目标和意义，并根据当前的需要和期望对其进行调整。
    </div>
  );
  export const Standard = () => (
    <div>
        <Title>Accounting Standard会计准则<Rating name="read-only" value={5} readOnly /></Title>

      <Alert  icon={false} variant="outlined" severity="info" className="mark">
        会计准则是会计的基本规范，它规定了会计记录、报告和披露的标准。在智能会计中，会计准则是指导会计系统如何处理和记录财务信息的重要依据。智能会计系统可以根据会计准则自动生成会计报告，并对财务数据进行审核和核对，从而确保财务信息的准确性和可信度。
      </Alert>
      会计准则在智能会计中的具体功能如下：
      <ul>
        <li>
          作为智能会计类的建模基础
        </li>
        <li>
          作为智能会计类的属性和方法
</li>
<li>
  人工智能学习的依据
</li>
      </ul>
    </div>
  );
  export const Pythonic = () => (
    <div>
        <Title>Pythonic<Rating name="read-only" value={4} readOnly /></Title>

      <Alert  icon={false} variant="outlined" severity="info" className="mark">
      Pythonic的会计语言风格是指使用Python语言编写会计程序时遵循的编码规范和最佳实践。这可能包括使用Python中的标准库和第三方库来处理数字和货币数据，使用简洁而易于理解的代码结构和命名约定，以及遵循会计原则和程序审核标准。
      </Alert>
      Pythonic会计python的语言风格如下：
      <ul>
        <li>
        Pythonic的会计语言风格还可能包括使用Python中的面向对象编程方法来模拟会计概念，如会计科目、账户和交易。这可能还包括使用Python中的数据结构，如列表和字典来存储和管理会计数据，并使用Python中的控制结构，如循环和条件语句来处理会计程序的业务逻辑。

在使用Python编写会计程序时，遵循Pythonic的编码规范和最佳实践可以帮助程序员更轻松地理解和维护代码，并使程序更易于协作和扩展。
        </li>
        <li>
        此外，遵循Pythonic的会计语言风格还可以帮助程序员更有效地处理大量的会计数据。例如，Python中的pandas库可以帮助程序员处理大型数据集并进行数据分析。 Python中的Numpy库可以帮助程序员进行高效的数学运算。

另外，使用Python编写会计程序还可以帮助程序员使用第三方库来实现各种功能，如报表生成、数据可视化、数据库交互等。这些库可以让程序员花更少的时间和精力来编写代码，而是更多地关注业务逻辑和数据分析。

总之，遵循Pythonic的会计语言风格可以帮助程序员更有效地编写和维护会计程序，并提高程序的可读性和可维护性。
</li>
<li>
在编写会计程序时，遵循Pythonic的会计语言风格还可以帮助程序员提高程序的可重用性和可扩展性。例如，可以使用Python中的模块和类来封装会计程序的逻辑，并使用继承和多态来实现代码的重用。这样可以使程序员更轻松地将会计程序扩展到新的业务场景。

此外，遵循Pythonic的会计语言风格还可以帮助程序员更好地管理程序中的数据。例如，可以使用Python中的数据模型库（如Django ORM）来管理程序中的数据，并使用Python中的数据库操作库（如SQLAlchemy）来与数据库交互。这样可以使程序员更轻松地管理程序中的大量数据。

总之，遵循Pythonic的会计语言风格可以帮助程序员编写高质量、高可重用性和高可扩展性的会计程序，并使程序更易于管理和维护。
</li>
      </ul>
    </div>
  );
export const Asset = () => (
    <div>
              <Title>Redefine Asset</Title>

            <Alert icon={false}  variant="outlined" severity="info"className="mark">
      Redefining assets: Assets refer to anything that a person or company owns that can aid in production, increase revenue, or be consumed. Assets can be divided into current assets and fixed assets. Current assets are assets that can be converted to cash within a short period of time, such as cash, inventory, and accounts receivable. Fixed assets are assets that are needed for long-term use and cannot be quickly converted to cash, such as real estate, machinery, and vehicles. Assets have three attributes: name, category and value. It also have two methods: total_value and generate_report. You can use this class to create instances of assets, for example: You can also use the total_value method to calculate the total value of assets: You can also use the generate_report method to generate a report of assets.
      </Alert>
      <p className="philosophy-text">
      资产是一个人或企业拥有的一切可以帮助生产、增加收益或者消耗的东西。资产可以分为流动资产和固定资产。流动资产是指一个人或企业在短期内可以转化为现金的资产，如现金、库存和应收款项。固定资产是指需要长期使用，不能很快转化为现金的资产，如房地产、机器设备和汽车。这个类有三个属性：名称，类别和价值。它还有两个方法：total_value 和 generate_report。你可以使用这个类来创建资产科目的实例，例如：你还可以使用 total_value 方法来计算资产的总值：你还可以使用 generate_report 方法来生成资产的报告：
      </p>
    </div>
  );
export const Liability = () => (
    <div>
                    <Title>Redefine Liability</Title>
                    <Alert  icon={false} variant="outlined" severity="info" className="mark">
                    A debt is an amount of money that is owed to another party. It is a liability that must be repaid, often with interest.In the field of artificial intelligence and accounting, debt can refer to financial obligations that a company or organization has taken on. This can include loans from banks, bonds issued to investors, and other forms of borrowing. AI can be used to analyze a company's debt load and predict its ability to repay it, as well as identifying potential opportunities for restructuring or refinancing. Additionally, AI can help automate the process of tracking and reporting on debt, allowing for more efficient and accurate financial management.
                      </Alert>
      <p className="philosophy-text">
      资产是一个人或企业拥有的一切可以帮助生产、增加收益或者消耗的东西。资产可以分为流动资产和
固定资产。流动资产是指一个人或企业在短期内可以转化为现金的资产，如现金、库存和应收款项。
固定资产是指需要长期使用，不能很快转化为现金的资产，如房地产、机器设备和汽车。这个类有三
个属性：名称，类别和价值。它还有两个方法：total_value 和 generate_report。你可以使
用这个类来创建资产科目的实例，例如：你还可以使用 total_value 方法来计算资产的总值：你
还可以使用 generate_report 方法来生成资产的报告：
      </p>
    </div>
  );
export const Trend = () => (
    <>
      <p className="philosophy-text">
        趋势
        还处在第一阶段：基础静态会计准则阶段。原生技术支持：注会，
        第二阶段：<b>前端封装会计准则</b>，解决原生会计政策不好用，会计政策兼容性问题，二次封装，更便于开发和掌握
        第三阶段：<b>会计组件化</b> node催生会计开发框架
      </p>
    </>
  );