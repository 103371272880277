import SyntaxHighlighter from 'react-syntax-highlighter';
import * as themes from "react-syntax-highlighter/dist/esm/styles/hljs";
import * as React from 'react';
import ReactMarkdown from 'react-markdown'

// Did you know you can use tildes instead of backticks for code in markdown? ✨
export const Asset = (props) => {
  return (
    <h4 className='mark'>
    <ReactMarkdown
    children={props.code}
    components={{
      code({node, inline, className, children, ...props}) {
        const match = /language-(\w+)/.exec(className || '')
        return !inline && match ? (
          <SyntaxHighlighter
            children={String(children).replace(/\n$/, '')}
            style={themes.a11yDark}
            language={match[1]}
            PreTag="div"
            {...props}
          />
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        )
      }
    }}
  /></h4>
  );
};

export const asset_code = `
  ~~~python
  class Asset:
    accounts = []
    def __init__(self, account, debit,date):
        self.account = account
        self.debit = debit
        self.date = date
        self.assets=[]
        self.roa = np.random.pareto(1)*self.debit
        self.cashflows=[]
        self.is_measurable = True
        self.likely_economic_benefit = True
        self.potential_use=""
        self.market_value = debit
        self.bubble=self.is_asset_bubble(self.market_value
        self.target_value = None
        self.uniqueness= None
        Asset.accounts.append(self)
  ~~~
  `
  export const liability_code = `
  ~~~python
  class Liability:
    accounts = []
    def __init__(self, account, credit, date,due_date,rate):
        self.date=date
        self.account = account
        self.credit = credit
        self.due_date = due_date
        self.liabilities = []
        self.asset = None
        self.rate=rate
        today = datetime.date.today()
        due_date = datetime.datetime.strptime(self.due_date, "%Y-%m-%d").date()
        if today < due_date:
            self.remaining_days = (due_date - today).days
        else:
            raise ValueError("债务已经过期，无法计算剩余天数")
        Liability.accounts.append(self)
  ~~~
  `
  export const home_code = `
  ~~~python
  import cpanlp as p
  sale1 = p.Sale(quarter="Q4",amount=93.4,unit="billion dollars",growth_rate=13%,year=2022,segment="North America")
  sale2 = p.Sale(quarter="Q4",amount=34.5,unit="billion dollars",growth_rate=-8%,year=2022,segment="International")
  sale3 = p.Sale(quarter="Q4",amount=21.4,unit="billion dollars",growth_rate=20%,year=2022,segment="AWS")
  sales = [sale1, sale2, sale3]
  total_sales = 0
  for s in sales:
      total_sales += s.amount
      print(f"Segment: {s.segment}, Sale: {s.amount}")
  
  print(f"Total Sales: {total_sales}")
  ~~~
  `
  export const home_code1 = `
~~~python

def buy_back_shares(self):
if self.funds >= 6000 and self.funds <= 8000:
    if self.price <= 17.00:
        # Buy back shares using the centralized bidding process
        # Implementation details omitted
        pass


~~~
`
export const home_code2 = `
~~~python


class ShareholderReduction:
def __init__(self, name, amount, percentage, method):
    self.name = name
    self.amount = amount
    self.percentage = percentage
    self.method = method

nanjing_reduction = ShareholderReduction("Nanjing Zhaoyin", 7158555, 1.79, "Centralized bidding and block trade")
hubei_reduction = ShareholderReduction("Hubei Zhaoyin", 3561613, 0.89, "Centralized bidding and block trade")


~~~
`
export const home_code3 = `
~~~python
  
class AviationElectronicInstrument:
    def __init__(self, share_issue, assets_purchase, related_transactions):
        self.share_issue = share_issue
        self.assets_purchase = assets_purchase
        self.related_transactions = related_transactions

    def announce_plan(self):
        print(f"The plan of Aviation Electronic Instrument Co., Ltd. is to issue shares for the purpose of purchasing assets and conducting related transactions. The details are as follows:\n"
              f"Share Issue: {self.share_issue}\n"
              f"Assets Purchase: {self.assets_purchase}\n"
              f"Related Transactions: {self.related_transactions}")

plan = AviationElectronicInstrument("X million shares to be issued", "Y assets to be purchased", "Z related transactions to be conducted")
plan.announce_plan()


~~~
`