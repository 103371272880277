import React from "react";
import {Title} from "./Overview"
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Rating from '@mui/material/Rating';

export const Syntax = () => (
    <div className="philosophy-text">
           <Title> Accounting syntax <Rating name="read-only" value={5} readOnly /></Title>
           <Alert  icon={false} variant="filled" severity="info">
           <b>会计句法是金融句法和经济句法的基础</b>。让会计句法在会计、经济和金融之间共享。会计句法是会计、经济和金融之间共享的基础。它是一种专门用于表示会计信息的语言系统，可以用来描述会计事务、会计报告和会计概念。会计句法的基本元素包括：会计概念、会计原则、会计科目、会计科目余额、会计科目账户和会计科目类型。通过使用会计句法，会计人员可以更加精确地表达会计信息，并且金融和经济专业人士可以更好地理解和分析会计信息。
           </Alert>
           <b> 会计问题实际上是句法问题</b>，意思是会计问题的关键在于如何清楚、准确地表达会计信息。这意味着使用正确的语法、格式和术语来描述会计事实和数据。如果表达不清、不准确，可能会导致误解和错误的决策。因此，会计人员需要掌握良好的语言和沟通技巧，以便能够清楚、准确地表达会计信息。
           <Alert  icon={false}  variant="outlined" severity="info"className="mark">
           Accounting syntax refers to the way in which accountants express themselves using language when conducting accounting work. It typically includes accounting terms and concepts that are designed to clearly and accurately convey accounting information. The characteristics of accounting syntax are precision, rigor, and attention to detail. When using accounting syntax, accountants need to follow basic principles and standards such as using the correct accounting terms and concepts, avoiding ambiguity and linguistic errors, etc. In addition, accounting syntax also needs to comply with accounting rules and guidelines such as International Financial Reporting Standards (IFRS) and Chinese Accounting Standards (CAC).
</Alert>
      <span className="文中强调">会计句法</span>是指会计专业人员在进行会计工作时使用的语言表达方式。会计句法通常包含会计术语和会计概念，旨在清晰、准确地表达会计信息。

会计句法的特点是精确、严谨，注重细节。会计人员在使用会计句法时，需要遵循一些基本原则和规范，如使用正确的会计术语和概念，避免歧义和语病等。此外，会计句法还要注意遵守会计规则和准则，如国际财务报告准则(IFRS)、中国会计准则(CAC)等。
<Alert  icon={false}  severity="info">
      
举个例子，在记录应收账款时，会计人员通常会使用以下会计句法："根据合同约定，在交付货物并收到客户确认时，公司增加了应收账款5,000元，记入应收账款科目。"这句话中，会计人员使用了会计术语"应收账款"和"应收账款科目"，并使用了会计概念"合同约定"、"交付货物"和"客户确认"，清晰地表达了应收账款的增加情况。同时，这句话还注重了细节，如增加的金额。

      </Alert>
      <Alert  icon={false} variant="outlined" severity="info"className="mark">
      Accounting syntax refers to the vocabulary, sentence structure, and grammar rules used in accounting language. It can help you better understand accounting principles and make your writing in accounting texts more precise and accurate. Accounting syntax includes accounting terms, concepts, calculation methods and accounting report formats, etc. For example, accounting terms include assets, liabilities, equity, income, expenses; accounting concepts include the principle of symmetry, substance over form, monetary unit assumption; accounting calculation methods include calculating earnings per share, calculating net assets; accounting report formats include balance sheet, income statement, cash flow statement.
      </Alert>

会计的句法是指会计语言中使用的词汇、句子结构和语法规则。会计的句法可以帮助你更好地理解会计原理，并且可以让你在书写会计文本时更加精准和严谨。会计的句法包括会计术语、会计概念、会计计算方法和会计报告格式等。例如，会计术语包括资产、负债、权益、收益、费用等；会计概念包括对称原则、实质性原则、货币单位假设等；会计计算方法包括计算每股收益、计算资产净值等；会计报告格式包括资产负债表、损益表、现金流量表等。
      <Alert icon={false}  variant="outlined" severity="info"className="mark">
      In summary, accounting syntax refers to the vocabulary, sentence structure, and grammar rules used in accounting language, and is an important tool for understanding and using accounting principles. Accounting terminology is a specialized set of terms used to record and report financial information. These terms are commonly used in accounting documents, financial statements, and other business documents, and are often used to express specific accounting concepts or actions. Linguistics is the study of human language, including phonetics, grammar, vocabulary, syntax, semantics, and language usage. While there is no direct relationship between accounting terminology and linguistics, they both serve as tools for expressing concepts and have rules and structure. Therefore, having a basic understanding of linguistic principles can be helpful in understanding and using accounting terminology. There are no unique grammar rules in accounting, but it has its own set of specialized terms and concepts, and rules and guidelines for recording and reporting financial information.
      </Alert>
总之，会计的句法是指会计语言中使用的词汇、句子结构和语法规则，是理解和使用会计原理的重要工具。
会计术语是一类专门用于记录和报告财务信息的专业术语。这些术语通常用于会计凭证、财务报表以及其他商业文件中，并且常常被用于表示特定的会计概念或操作。语言学是研究人类语言的学科，包括语音、语法、词汇、句法、语义以及语言使用等方面。尽管会计术语和语言学之间没有直接的关系，但它们都是用于表达概念的工具，并且都具有规则和结构。因此，对于理解和使用会计术语，掌握一定的语言学知识是有帮助的。
会计中并没有独特的语法规则，但它有自己的一套专业术语和概念，以及记录和报告财务信息的规则和准则。
<Alert  icon={false} variant="outlined" severity="info"className="mark">
Under the framework of International Financial Reporting Standards (IFRS) or Generally Accepted Accounting Principles (GAAP), accountants are required to follow certain rules to properly record and report financial information. These rules include recording transactions, calculating income, classifying assets, and accruing depreciation, etc. For example, when recording a transaction, an accountant must follow the principle of double-entry accounting, recording the transaction in both the debit and credit side, to ensure that the accounts balance. When calculating income, an accountant must use different methods of calculation based on the source and usage of the income, such as the direct income method, the deferred income method, etc.
There are also other rules and principles in accounting that are used to help accountants record and report financial information correctly and to better reflect a company's financial condition. When recording transactions, you must use the four elements of accounting: account, debit amount, credit amount, and date.
</Alert>

在国际会计准则 (International Financial Reporting Standards, IFRS) 或美国通用会计准则 (Generally Accepted Accounting Principles, GAAP) 的框架下，会计师需要遵守一些规则来正确记录和报告财务信息，这些规则包括记录交易、计算收益、分类资产、计提折旧等。
例如，在记录一笔交易时，会计师必须按照“双列记账”的原则，在贷方和借方各记录一笔交易，以保证账目平衡。在计算收益时，会计师必须根据收益的来源和使用情况，使用不同的计算方法，如直接收益法、摊薄收益法等。
会计中还有一些其他的规则和准则，这些规则和准则通常被用于帮助会计师正确记录和报告财务信息，并使这些信息能够更好地反映一个企业的财务状况。
在记录交易时，必须使用四大会计要素：账户、借方金额、贷方金额和日期。
    </div>
  );

  export const Syntax1 = () => (
    
    <div className="philosophy-text">
                 <Title> 生成句法 <Rating name="read-only" value={3} readOnly /></Title>
      <span className="文中强调">会计借贷句法</span>是指在记录账目时使用的一种语言。这种语言用于记录借方和贷方之间的交易，以及资产、负债和权益之间的关系。在会计借贷语言中，借方表示账户的收入或增加，贷方表示账户的支出或减少。
      <Alert  icon={false}  severity="info">
      如果一家公司从银行贷款100万元，则在贷方记录100万元的贷款，在借方记录100万元的银行存款。会计借贷句法是用来维护账目平衡的。这意味着，对于每一笔交易，借方总额应等于贷方总额。例如，如果一家公司在某个月内获得100万元的销售收入，同时在贷方记录50万元的成本，则在借方记录50万元的利润。这样，借方总额（100万元）就等于贷方总额（50万元 + 50万元）。

      </Alert>
会计借贷语言是一种重要的工具，用于记录、分析和报告公司的财务信息。它为决策者提供了有关公司财务状况、财务目标和财务风险的重要信息。自然语言是人类用于交流的语言，包括汉语、英语、法语等。这些语言的语法和句法结构相对复杂，常常有多种意思和表达方式。

编程语言是用于编写计算机程序的语言，包括C、Java、Python等。这些语言的语法和句法结构相对简单，主要用于描述计算机执行的操作和逻辑关系。

会计语言是用于记录和描述财务信息的语言，包括会计术语、会计概念、会计原则等。会计语言是一种专业语言，主要用于描述会计事务和财务状况。

总之，自然语言、编程语言和会计语言是三种不同的语言，用途和结构也不尽相同。自然语言是用于人与人之间的交流，语法和句法结构复杂，常常有多种意思和表达方式。编程语言是用于编写计算机程序的语言，语法和句法结构相对简单，主要用于描述计算机执行的操作和逻辑关系。会计语言是用于记录和描述财务信息的语言，是一种专业语言，主要用于描述会计事务和财务状况。

最后，还需要注意，不同的语言可能具有不同的文化和历史背景，因此在使用时需要注意文化差异。例如，英语中的一些表达方式在汉语中可能并不通用，因此在使用英语时需要注意文化差异。同样，不同的编程语言也可能具有不同的语法规则和约定，因此在学习和使用时需要注意这些差异。会计语言是用于记录和描述财务信息的语言，具有以下特色：

专业性：会计语言是一种专业语言，主要用于描述会计事务和财务状况，具有较高的专业性。

精确性：会计语言需要精确描述财务信息，因此要求较高的精确性。

简洁性：会计语言要求简洁明了，避免冗余和模糊不清。

一致性：会计语言需要遵循一定的规范和标准，保证使用的一致性。

通用性：会计通用性：会计语言需要具有较高的通用性，能够被广泛使用。
此外，会计语言还可能有以下特征：

包含大量的术语和概念，例如会计术语、会计概念、会计原则等。

具有较强的法律性，可能会受到相关法律法规的约束。

具有较强的系统性，可能包含复杂的会计框架和标准。

可能使用特殊的记号和符号，例如会计科目编号、会计凭证编号等。
    </div>
    );
export const Syntax2 = () => (
      <div>
        句法2
      </div>
      );
export const Syntax3 = () => (
        <div>
          句法3
        </div>
        );
export const Declarative = () => (
<>
<Title> Declarative Accounting<Rating 
name="read-only" value={5} readOnly /></Title>
<p className="philosophy-text">
声明式编程语言和命令式编程语言是两种不同的编程风格。

声明式编程语言 (Declarative Programming) 是一种编程风格，它强调程序员在编写代码时应该表达要完成什么，而不是如何完成。这种编程风格通常使用高阶函数和抽象数据类型来表达程序逻辑。 例如： SQL 和 XSLT。

命令式编程语言 (Imperative Programming) 是另一种编程风格，它强调程序员在编写代码时应该描述程序如何完成。这种编程风格通常使用循环和条件语句来控制程序的执行流程。例如： C，Java, Python。
</p><p className="philosophy-text">
声明式编程语言和命令式编程语言之间有许多差异，但它们之间也有联系。虽然声明式编程语言更加简洁，但它们也可能会被命令式编程语言所实现。反之亦然。

声明式编程语言和命令式编程语言都有各自的优势和劣势，在不同场景下使用不同的编程风格可以获得最佳效果。</p><p className="philosophy-text">
声明式编程语言和命令式编程语言不能简单地评判哪个更高级，因为它们是不同的编程风格，每种风格都有各自的优缺点。

声明式编程语言具有简洁易懂的优点，它们更加抽象和高层次，通常更加符合人类思维，更容易阅读和维护。而命令式编程语言更加底层，更加细致，具有更好的性能，更容易控制程序的执行流程。

在机器学习领域，声明式编程语言和命令式编程语言都可以被用于机器学习。虽然声明式编程语言更加简洁易懂，但它们也可能需要更多的代码量来实现。而命令式编程语言更加底层，更加细致，对于一些高性能的机器学习任务可能更加适用。

总的来说，机器学习领域通常使用 Python，这是一种命令式编程语言，因为它具有丰富的库和工具支持。但这并不意味着声明式编程语言不能用于机器学习，而是要根据具体场景和需求来选择合适的。
</p>
<Alert icon={false}  variant="filled" severity="info">

会计是属于声明式编程语言。

会计是一种抽象概念，它涉及到记录、分类、汇总、报告和分析财务信息。 会计师在处理会计信息时，需要符合一系列的会计准则和规则，这些准则和规则规定了会计信息如何被记录、分类、汇总和报告。

会计师需要按照这些准则和规则来处理会计信息，而不是手动执行复杂的计算和运算。这符合了声明式编程语言的特点，即强调程序员在编写代码时应该表达要完成什么，而不是如何完成。

总的来说，会计是一种声明式编程语言，因为它更多地侧重于描述和规范，而不是手动执行复杂的计算和运算。
</Alert>

</>
);
export const Pyaccounting = () => (
          <>
                     <Title> PyAccounting syntax<Rating name="read-only" value={5} readOnly /></Title>

                  <Alert icon={false}  variant="filled" severity="info">
                  React 的 useEffect 是 React Hooks 的一部分，它允许在函数组件中使用类似于类组件的生命周期函数的功能。
在 Python 中没有类似于 useEffect 的机制，因为 Python 不是一种声明式编程语言，而是一种命令式编程语言，它不支持在函数组件中使用类似于生命周期函数的功能。
然而，如果你需要在 Python 中实现类似于 React useEffect 的功能，你可以使用 Python 的装饰器或者上下文管理器来实现。


</Alert>
            新会计句法,新会计句法的要求lightweight, intuitive, blazing-fast, and extensible，五个主要的会计要素：资产、负债、所有者权益、收入和费用，会计要素并未发挥他要素的作用，一个模块总是对应于一个 Python 文件.py。它包含类、函数和常量等逻辑。包基本上是一个可以包含许多模块或子包的模块。好的，我可以尝试为你创立一套新的会计语法规则。这是我能想到的一些规则：

使用双列记账法，在贷方和借方分别记录交易。

在记录交易时，必须使用四大会计要素：账户、借方金额、贷方金额和日期。

账户分为资产类、负债类、所有者权益类和收益类四大类。

收益的计算应根据收益的来源和使用情况使用不同的方法，如直接收益法、摊薄收益法等。

资产的折旧应根据资产的使用寿命和折旧方法使用不同的方法计算，如平均年限法、双倍余额递减法等。

在记录和报告财务信息时，应遵守适用的会计准则，如国际会计准则 (IFRS) 或美国通用会计准则 (GAAP)。

这些规则只是提供一个简单的示例，在实际应用中，还有很多其他的规则和准则需要遵守。希望这些规则能对你有所帮助。
可读性与拓展型的矛盾
在 Python 中, 不强制指定变量类型是 Python 的一大特点。在类的 init 方法中, 不指定变量类型可以让代码更灵活，因为它允许传入任何类型的值。这样可以使程序更易于扩展和维护。
但是, 如果你有一个明确的类型限制,可以使用 python的类型注释,提高代码的可读性和可维护性，同时也可以使用类型检查工具来检查代码中是否存在类型错误。
          </>
          );