import { useAuth0 } from "@auth0/auth0-react";
import React from 'react'
import Button from '@mui/material/Button';

export const LoginButton = () => {
  const {loginWithRedirect,isAuthenticated}= useAuth0();
  return (
 !isAuthenticated && (
    <Button  size="small" variant="outlined" color="primary" onClick={() => loginWithRedirect()}>Log in</Button>
  )
  )
}
export const LogoutButton = () => {
  const {logout,isAuthenticated}= useAuth0();
  return (
 isAuthenticated && (
    <Button size="small" variant="outlined" color="primary" onClick={logout}>Log out</Button>
  ) 
  )
}
export const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Button
    variant="outlined" size="small" color="primary"
      onClick={() =>
        loginWithRedirect({
          screen_hint: 'signup',
        })
      }
    >
      Sign Up
    </Button>
  );
};