import React from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import {Title} from "../Overview";
import Rating from '@mui/material/Rating';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {NavReinforcementlearning} from "../Nav";
import {Asset} from "../Code"
import {NavEnvs} from "../Nav";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  export const Env = () => (
    <div>
             <Title> Accounting Env<Rating name="read-only" value={5} readOnly /></Title>
             <NavEnvs /> 

             创建一个关于研发决策的会计强化学习环境需要考虑以下几点：

编写环境的代码可以基于gym或者其他强化学习框架，用于模拟不确定性因素对研发决策的影响，并设置相应的奖励机制。
<ul><li>状态：研发决策环境中的状态可能包括公司的财务状况，市场需求，竞争手的情况等。
</li><li>动作：智能体需要进行的动作可能包括投资于研发项目，削减研发预算，购买其他公司的技术等。</li><li>奖励：奖励可能取决于研发决策的结果，如果研发项目成功，可能会得到高的奖励，反之亦然。</li><li>信息不对称：研发决策中的信息不对称可能来源于市场需求，竞争对手等不确定性因素，这些信息不对称可能影响到研发项目的成功
率。
</li></ul>
The optimal liquidation problem is a minimization problem, i.e. we need to find the trading list that minimizes the implementation shortfall. In order to solve this problem through reinforcement learning, <b>we need to restate the optimal liquidation problem in terms of States, Actions, and Rewards.</b> 
<br /><br />
比如道德风险在公司金融中的强化学习的分解如下：
<ul><li>States: 公司金融中的道德风险问题可以视为状态，其中包括公司的道德水平，道德风险因素，公司文化，法律法规等。
</li><li>Actions: 公司金融中的动作可以视为采取道德行为，例如遵守法律法规，遵守公司道德政策，披露金融信息等。
</li><li>Rewards: 公司金融中的奖励可以视为道德行为带来的奖励，例如获得道德证书，提高公司形象，获得客户信任等。
</li></ul>
这些状态、动作和奖励可以用强化学习模型进行建模，通过训练来学习如何减少道德风险。
    </div>
  );