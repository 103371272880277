import React from "react";
import { Link} from "react-router-dom";
import {Title} from "../pages/Overview"
import Alert from '@mui/material/Alert';
const ZhaiYao = () => (
  <div>
  <Title>Abstract</Title>
  <Alert icon={false}  severity="info" variant="outlined"  className="mark">
  <h3>
  In accounting, the main discipline is accounting and the supporting disciplines are linguistics and computer science. Traditionally, syntax trees are used to analyze accounting subjects, but it is difficult to solve many accounting phenomena by recursively and binary analyzing accounting subjects as if they were trees. Therefore, it is necessary to study the underlying accounting subjects in order to truly improve readability. The solution is to re-create accounting subjects, for example, from assets to my assets with a drop-down list, lacking a distinction between prior and subsequent events. This approach, called the linguistic turn, is bottom-up, with underlying elements being interconnected rather than fragmented. Linguistic philosophy of accounting is required to support this approach. Based on a linguistic view of accounting, mainstream text analysis is seen as a methodology (data, methods, and tools), and text analysis of accounting should be seen as a theory. The readability of financial statements and accounting standards is poor, and accounting research has not been closely tied to linguistics. The accounting subject components are similar to components and are arranged in a tree-like structure, with data being passed between parent and child components. The life cycle of accounting subjects includes initial recognition and subsequent recognition.
  </h3>
  </Alert>

         <p >
主体学科：会计学,支撑学科：
<span className='文中强调'>语言学</span>和计算机。传统的是<span className='文中强调'>句法树</span>来分析会计科目，递归和二分下去 会计科目像树一样（资产：递延所得税资产）但是对很多会计现象解决不好，需要对更多的底层会计科目进行研究，真正提高可读性从根源是重新创作会计科目，从资产到我的资产下拉列，缺乏事前与事后的区分。
<br />
<Alert icon={false} severity="info" variant="filled"  >
 会计学的语言学转向指的是将会计逻辑应用于会计形式和会计内容的整合，并使用代码来表示这些关系。这种方法可以使会计信息更具可读性和可靠性。
</Alert >
和以往的自上而下的会计科目研究的区分：<Link to='/overview/2' className="文内链接">语言学转向（linguistic turn） </Link>是自下而上，底层不是零散的，而是相互关联，需要到雪花下的原子层级。会计学的语言学转向底层需要<Link to='/overview/5' className="文内链接">会计的语言哲学观</Link>来支持。基于语言的会计观，主流的是把文本分析作为一种方法论（数据、方法和工具），
需要把会计的文本分析作为理论。会计报表缺乏可读性，会计报表的上位会计准则缺乏可读性（仿照git）。会计学的发展一直是离不开语言学的，但是会计研究却不是。借贷必相等 不是同一反复。米塞斯：一个人的价值判断只能通过他的选择（掏钱）（而不是嘴上说）体现出来，一个人价值判断是由他的行为来体现的,会计科目组件化，类似组件,会计科目呈现树形排列,子父组件之间相互传递数据,会计科目的生命周期：初始确认，后续确认。
<br />
<Alert icon={false} severity="info" variant="outlined" className="mark">
<h3>
In the era of big data, accounting research has also undergone important changes. One of them is the linguistic turn. Traditional accounting research is mostly based on quantitative analysis, focusing on the description and generalization of data. However, in the context of big data-driven, accounting research can use natural language processing techniques to analyze text data. This approach is called the linguistic turn. The advantage of linguistic turn is that it can provide more fine and complex information, which can help us better understand the decisions and behaviors of companies. For example, by analyzing the financial report text in the company's annual report, we can understand the company's management's view and confidence level of the future. By analyzing the theme of the company's announcements, we can understand the company's strategic priorities. Of course, the linguistic turn is not a substitute for traditional quantitative analysis, but is used in conjunction with it, so that we can analyze and understand the company from multiple perspectives.
</h3>
</Alert>
在大数据时代，会计研究也发生了重要的变化。其中之一就是语言学转向。传统的会计研究多以定量分析为主，侧重于对数据的描述和概括。而在大数据驱动的情况下，会计研究可以借助自然语言处理技术来对文本数据进行分析。这种做法被称为语言学转向。语言学转向的优势在于，它可以提供更为精细和复杂的信息，进而帮助我们更好地理解企业的决策和行为。例如，通过对公司年报中的财务报告文本进行情感分析，我们可以了解公司高管对未来的看法和信心水平；通过对公司公告文本的主题分析，我们可以了解公司的战略重点等。当然，语言学转向并不是替代传统定量分析的方法，而是与之配合使用，从而使我们能够从多个角度对企业进行分析和理解。
</p>
</div>
);

export default ZhaiYao;
